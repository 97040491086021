export const ES_DICTIONARY = {
  cameraMinSpecificationsHeadingText:
    'La cámara no cumple con las especificaciones mínimas',
  cameraMinSpecificationsMessageText:
    'La cámara debe soportar al menos una resolución de 320*240 y 15 fotogramas por segundo.',
  cameraNotFoundHeadingText: 'La cámara no es accesible.',
  cameraNotFoundMessageText:
    'Verifica que la cámara esté conectada y que no haya otra aplicación utilizando la cámara. Es posible que debas ir a la configuración para otorgar permisos de cámara y cerrar todas las instancias de tu navegador e intentarlo de nuevo.',
  a11yVideoLabelText: 'Cámara web para la verificación de prueba de vida',
  cancelLivenessCheckText: 'Cancelar la verificación de prueba de vida',
  goodFitCaptionText: 'Ajuste correcto',
  goodFitAltText:
    'Ilustración del rostro de una persona, encajando perfectamente dentro de un óvalo.',
  hintCenterFaceText: 'Centra tu rostro',
  hintCenterFaceInstructionText:
    'Instrucción: Antes de comenzar la verificación, asegúrate de que tu cámara esté en la parte superior central de la pantalla y centra tu rostro frente a la cámara. Cuando comience la verificación, aparecerá un óvalo en el centro. Se te pedirá que te acerques al óvalo y luego que te quedes quieto. Después de mantenerte quieto durante unos segundos, deberías escuchar "verificación completada".',
  hintFaceOffCenterText:
    'El rostro no está en el óvalo, centra tu rostro frente a la cámara.',
  hintMoveFaceFrontOfCameraText: 'Coloca tu rostro frente a la cámara',
  hintTooManyFacesText:
    'Asegúrate de que solo haya un rostro frente a la cámara',
  hintFaceDetectedText: 'Rostro detectado',
  hintCanNotIdentifyText: 'Coloca tu rostro frente a la cámara',
  hintTooCloseText: 'Intenta alejarte',
  hintTooFarText: 'Acércate',
  hintConnectingText: 'Conectando...',
  hintVerifyingText: 'Verificando...',
  hintCheckCompleteText: 'Verificación completada',
  hintIlluminationTooBrightText: 'Muévete a un área con menos luz',
  hintIlluminationTooDarkText: 'Muévete a un área más iluminada',
  hintIlluminationNormalText: 'Condiciones de iluminación normales',
  hintHoldFaceForFreshnessText: 'Intenta no moverte',
  hintMatchIndicatorText: '50% completado. Sigue acercándote.',
  photosensitivityWarningBodyText:
    'Esta verificación muestra diferentes colores intermitentes. Ten precaución si eres fotosensible.',
  photosensitivityWarningHeadingText: 'Advertencia de fotosensibilidad',
  photosensitivityWarningInfoText:
    'Algunas personas pueden experimentar convulsiones epilépticas cuando están expuestas a luces de colores. Ten precaución si tú o alguien en tu familia tiene una condición epiléptica.',
  photosensitivityWarningLabelText: 'Más información sobre la fotosensibilidad',
  photosensitivyWarningBodyText:
    'Esta verificación muestra diferentes colores intermitentes. Ten precaución si eres fotosensible.',
  photosensitivyWarningHeadingText: 'Advertencia de fotosensibilidad',
  photosensitivyWarningInfoText:
    'Algunas personas pueden experimentar convulsiones epilépticas cuando están expuestas a luces de colores. Ten precaución si tú o alguien en tu familia tiene una condición epiléptica.',
  photosensitivyWarningLabelText: 'Más información sobre la fotosensibilidad',
  retryCameraPermissionsText: 'Reintentar',
  recordingIndicatorText: 'Grabando',
  startScreenBeginCheckText: 'Comenzar la verificación de video',
  tooFarCaptionText: 'Demasiado lejos',
  tooFarAltText:
    'Ilustración del rostro de una persona dentro de un óvalo; hay un espacio entre el perímetro del rostro y los límites del óvalo.',
  waitingCameraPermissionText:
    'Esperando a que permitas el acceso a la cámara.',
  errorLabelText: 'Error',
  connectionTimeoutHeaderText: 'Tiempo de conexión agotado',
  connectionTimeoutMessageText: 'La conexión ha expirado.',
  timeoutHeaderText: 'Tiempo agotado',
  timeoutMessageText:
    'El rostro no encajó dentro del óvalo en el tiempo límite. Inténtalo de nuevo y asegúrate de llenar completamente el óvalo con tu rostro.',
  faceDistanceHeaderText: 'Movimiento hacia adelante detectado',
  faceDistanceMessageText: 'Evita acercarte mientras te conectas.',
  multipleFacesHeaderText: 'Múltiples rostros detectados',
  multipleFacesMessageText:
    'Asegúrate de que solo haya un rostro presente frente a la cámara.',
  clientHeaderText: 'Error del cliente',
  clientMessageText: 'La verificación falló debido a un problema del cliente',
  serverHeaderText: 'Problema del servidor',
  serverMessageText:
    'No se puede completar la verificación debido a un problema del servidor',
  landscapeHeaderText: 'Orientación horizontal no compatible',
  landscapeMessageText:
    'Gira tu dispositivo a la orientación vertical (retrato).',
  portraitMessageText:
    'Asegúrate de que tu dispositivo permanezca en orientación vertical (retrato) durante la duración de la verificación.',
  tryAgainText: 'Intentar de nuevo',
};

export const LIVENESS_ERROR_MESSAGES = {
  RUNTIME_ERROR: 'Ocurrió un error, contacte a Sora',
  SERVER_ERROR: 'Ocurrió un error con el API de reconocimiento',
  CONNECTION_TIMEOUT: 'Tiempo de conexión agotado',
  TIMEOUT: 'La sesión para la prueba de vida ha expirado',
  FACE_DISTANCE_ERROR:
    'Rostro demasiado cerca antes de comenzar la verificación',
  CAMERA_ACCESS_ERROR:
    'No se pudo acceder a la cámara, por favor proporcione acceso a la cámara',
  CAMERA_FRAMERATE_ERROR:
    'La cámara no cumple con las especificaciones mínimas',
  FRESHNESS_TIMEOUT: 'Tiempo de conexión agotado',
  MOBILE_LANDSCAPE_ERROR: 'Orientación horizontal no compatible',
  NOT_LIVE:
    'No se pudo realizar el reconocimiento facial, asegúrate de centrar bien tu rostro en el óvalo y que tengas buena iluminación.',
};
