import React, { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UseFormReturn } from 'react-hook-form';
import { CHSFormType } from './CreateHolidaySchemeDialog.types';
import { Employee } from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import { EmployeesFinder } from '../../../../../../../businessComponents/EmployeesFinder';
import {
  DeleteActions,
  RowBaseItem,
  TableComponent,
} from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { COLUMNS_TABLE_SIGNERS } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableColumns';
import { SignersTabs } from '../../../../../../RHPod/Documents/PdfDocuments/Signers/SignersTabs';
import { useCHSEmployeesActions } from './useCHSEmployeesActions';

type CHSEmployeesProps = {
  form: UseFormReturn<CHSFormType>;
};

export const CHSEmployees: React.FC<CHSEmployeesProps> = ({ form }) => {
  const theme = useTheme();
  const palette = theme.newPalette;

  const {
    states: {
      rows,
      setSelectedRows,
      setErrorRows,
      errorRows,
      selectedRows,
      setTableTab,
      tableTab,
      isCheckedAll,
    },
    actions: { addRow, deleteRows, handleCheckAllRows, setRows },
  } = useCHSEmployeesActions();

  const deleteItemRef = useRef<null | RowBaseItem[]>(null);

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
  }));

  const deleteRowsAction = (action: DeleteActions, row: RowBaseItem) => {
    if (tableTab === 'success') {
      if (action === 'massive') {
        deleteItemRef.current = selectedRows;
      } else {
        deleteItemRef.current = [row];
      }
      deleteRows(deleteItemRef.current);
    } else {
      if (action === 'massive') {
        const selectedRowsIds = selectedRows.map((r) => r.user);
        setErrorRows(
          errorRows.filter((r) => !selectedRowsIds.includes(r.user)),
        );
        setSelectedRows([]);
      } else {
        setErrorRows(errorRows.filter((r) => r.user !== row.user));
      }
    }
  };

  const handleOnSelectedRowsOnPaste = (foundRows) => {
    const uniqueRows = foundRows
      .filter((employee) => {
        return !rows.some((prevEmployee) => prevEmployee._id === employee._id);
      })
      .map((row) => ({ ...row, type: 'Employee' })); // this because the row is coming from the paste
    setRows((prev) => prev.concat(uniqueRows));
  };

  const handlePasterErrors = (errors) => {
    const uniqueErrors = errors.filter((employee) => {
      return !errorRows.some(
        (prevEmployee) => prevEmployee.user === employee.user,
      );
    });
    setErrorRows(uniqueErrors);
  };

  useEffect(() => {
    form.setValue('assignedTo', rows);
  }, [form, rows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [tableTab, setSelectedRows]);

  useEffect(() => {
    if (errorRows.length === 0) {
      setTableTab('success');
    }
    form.setValue('assignedToErrors', errorRows);
  }, [errorRows, form, setTableTab]);

  return (
    <Stack mt={2} gap={2}>
      <EmployeesFinder
        findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES', 'GROUPS']}
        onSelectRow={(row) => {
          addRow(row);
        }}
        selectedRows={rows}
        isEmployeeSelected={(employee: Employee) =>
          rows.some((row) => row._id === employee._id)
        }
        onSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
        handlePasterErrors={handlePasterErrors}
      />
      <SignersTabs
        signersList={rows}
        signersError={errorRows}
        tab={tableTab}
        handleChange={(e, v) => setTableTab(v)}
      />
      <TableComponent
        width="100%"
        height={300}
        headerBgColor={palette.grey.transparent24}
        headerTextColor={palette.text.secondary}
        rows={tableTab === 'success' ? rows : errorRows}
        selectedRows={selectedRows}
        columns={modifiedColumns}
        handleSelectAllCheckbox={handleCheckAllRows}
        handleCheckboxChange={(checked, row) => {
          if (checked) {
            setSelectedRows([...selectedRows, row]);
          } else {
            setSelectedRows(
              selectedRows.filter((selectedRow) => selectedRow !== row),
            );
          }
        }}
        areAllChecked={isCheckedAll}
        handleLoadMore={() => {}}
        loadedRowCount={rows.length}
        deleteRows={(item, s) => deleteRowsAction(item, s)}
        totalRowsCount={tableTab === 'success' ? rows.length : errorRows.length}
      />
    </Stack>
  );
};
