import {
  currentCompanyVar,
  moduleBlockedDialogVar,
} from '../../cache.reactiveVars';
import { useReactiveVar } from '@apollo/client';
import { SORA_MODULES } from './ModuleBlockable.constants';

export type ModulesAvailable = Exclude<keyof typeof SORA_MODULES, 'ALL'>;

const keysAvailable: Record<ModulesAvailable, string> = {
  DOCUMENTS: 'documents',
  REST: 'rest',
  AI: 'ai',
  HOLIDAYS: 'holidays',
};

type BlockableActionsReturnType = {
  onClick: () => void;
  isModuleAvailable: boolean;
};

//overload used for legacy js files, please avoid and use the enum instead
export function useBlockableActions(
  requiredModule: string,
): BlockableActionsReturnType;
export function useBlockableActions(
  requiredModule: ModulesAvailable,
): BlockableActionsReturnType {
  const currentCompany = useReactiveVar(currentCompanyVar);
  const { modules: companyModules } = currentCompany || {};

  const keyToSearch: string = keysAvailable[requiredModule];

  const isModuleAvailable = companyModules?.[keyToSearch]?.active;

  const onClick = () => {
    if (!isModuleAvailable) {
      moduleBlockedDialogVar({
        open: true,
      });
    }
  };

  return {
    onClick,
    isModuleAvailable,
  };
}
