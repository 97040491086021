// @ts-check
import { useController } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';
import { applySanitizers } from '../../../utils/sanitizers';
import type { ControlledEmployeeRecordTagSubcategoryNameInputProps } from './ControlledEmployeeRecordTagSubcategoryNameInput.types';

const defaultRules: RegisterOptions = {
  required: 'Campo requerido',
  maxLength: {
    value: 80,
    message: 'Máximo 80 caracteres',
  },
};

export const ControlledEmployeeRecordTagSubcategoryNameInput = (
  props: ControlledEmployeeRecordTagSubcategoryNameInputProps,
) => {
  const { control, rules, textFieldProps } = props;

  const name = 'subcategory';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps?.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['cleanString'],
        });
        onChange(inputValue);
      }}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
    />
  );
};
