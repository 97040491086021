import { sifeInstance } from './instance/createInstance';

export const urlBuilder = (url, urlVar) => {
  if (!urlVar || !Object.keys(urlVar).length) return url;

  if (url.includes(':') && Object.keys(urlVar).length) {
    const entries = Object.entries(urlVar);
    let newUrl;
    for (const [key, value] of entries) {
      newUrl = url.replace(`:${key}`, value);
    }
    return newUrl;
  }
  return url;
};

const routes = {
  login: '/users/login',
  userData: '/users/current',
  renewToken: '/users/renewtoken',
  startVerification: '/verify/start',
  verifyFrontId: '/verify/front-id',
  verifyBackId: '/verify/back-id',
  verifySelfie: '/verify/selfie',
  endVerification: '/verify/user',
  createLivenessSession: '/verify/liveness-session',
  getLivenessSession: '/verify/liveness-session/:sessionId',
  electronicSignature: '/signatures/electronic-signature',
  revokeElectronicSignature: '/signatures/crypto-signature',
  pendingDocuments: '/documents/pending',
  signDocsWithSifeCryptographic: '/documents/sign-sife-cryptographic',
  resetPassword: '/users/current/reset-password',
  getCryptoSignature: '/signatures/crypto-signature',
  getSatCryptoSignature: '/signatures/sat-crypto-signature',
  verificationData: '/verify/data',
  revokeSignature: '/signatures/crypto-signature',
  sendEmailVerification: '/users/current/send-email-verification',
  sendPhoneVeritication: '/users/current/send-phone-verification',
  signDocsWithSatCryptographic: '/documents/sign-sat-cryptographic',
  deleteProfilePicture: '/users/current/profile-picture',
  emailVerification: '/users/:user/email-verification',
  phoneVerification: '/users/:user/phone-verification',
};

// creates endpoints attached to conditionally pointed methods
export const createEndpoint = (url = '', instance) => {
  return {
    request: (reqConfig) => {
      return instance.request({ ...reqConfig, url });
    },
    get: (reqConfig = {}, urlVar) => {
      return instance.get(urlBuilder(url, urlVar), { ...reqConfig });
    },
    delete: (reqConfig = {}, urlVar) => {
      return instance.delete(urlBuilder(url, urlVar), { ...reqConfig });
    },
    post: (data, reqConfig = {}, urlVar) => {
      return instance.post(urlBuilder(url, urlVar), data, {
        ...reqConfig,
      });
    },
    put: (data, reqConfig = {}, urlVar) => {
      return instance.put(urlBuilder(url, urlVar), data, {
        ...reqConfig,
      });
    },
    patch: (reqConfig = {}, urlVar) => {
      return instance.patch(urlBuilder(url, urlVar), reqConfig.data, {
        ...reqConfig,
      });
    },
  };
};

export const sifeEndpoints = {
  login: createEndpoint(routes.login, sifeInstance),
  userData: createEndpoint(routes.userData, sifeInstance),
  renewToken: createEndpoint(routes.renewToken, sifeInstance),
  startVerification: createEndpoint(routes.startVerification, sifeInstance),
  verifyFrontId: createEndpoint(routes.verifyFrontId, sifeInstance),
  verifyBackId: createEndpoint(routes.verifyBackId, sifeInstance),
  verifySelfie: createEndpoint(routes.verifySelfie, sifeInstance),
  endVerification: createEndpoint(routes.endVerification, sifeInstance),
  createLivenessSession: createEndpoint(
    routes.createLivenessSession,
    sifeInstance,
  ),
  getLivenessSession: createEndpoint(routes.getLivenessSession, sifeInstance),
  verifyCompletionToken: createEndpoint(routes.completionToken, sifeInstance),
  createElectronicSignature: createEndpoint(
    routes.electronicSignature,
    sifeInstance,
  ),
  revokeElectronicSignature: createEndpoint(
    routes.revokeElectronicSignature,
    sifeInstance,
  ),
  pendingDocuments: createEndpoint(routes.pendingDocuments, sifeInstance),
  signDocsWithSifeCryptographic: createEndpoint(
    routes.signDocsWithSifeCryptographic,
    sifeInstance,
  ),
  resetPassword: createEndpoint(routes.resetPassword, sifeInstance),
  getCryptoSignature: createEndpoint(routes.getCryptoSignature, sifeInstance),
  getSatCryptoSignature: createEndpoint(
    routes.getSatCryptoSignature,
    sifeInstance,
  ),
  verificationData: createEndpoint(routes.verificationData, sifeInstance),
  revokeSignature: createEndpoint(routes.revokeSignature, sifeInstance),
  sendEmailVerification: createEndpoint(
    routes.sendEmailVerification,
    sifeInstance,
  ),
  sendPhoneVeritication: createEndpoint(
    routes.sendPhoneVeritication,
    sifeInstance,
  ),
  signDocsWithSatCryptographic: createEndpoint(
    routes.signDocsWithSatCryptographic,
    sifeInstance,
  ),
  deleteProfilePicture: createEndpoint(
    routes.deleteProfilePicture,
    sifeInstance,
  ),
  emailVerification: createEndpoint(routes.emailVerification, sifeInstance),
  phoneVerification: createEndpoint(routes.phoneVerification, sifeInstance),
};
