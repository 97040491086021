import React, { useRef, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { HolidaysSchemeListItem } from './HolidaysSchemeListItem';
import { GET_COMPANY_SCHEMES } from '../holidaySchemes.gql';
import { useQuery } from '@apollo/client';
import { HolidayScheme } from '../holidaySchemes.types';
import { EditHolidaySchemeDialog } from './editHolidaySchemeDialog/EditHolidaySchemeDialog';
import { HolidaySchemeAdapter } from '../utils/HolidaySchemeAdapter';

export const DisplayHolidaysScheme: React.FC = () => {
  useQuery(GET_COMPANY_SCHEMES, {
    onCompleted: (data) => {
      const schemes = data?.getCompanySchemes?.schemes ?? [];
      setCompanySchemes(
        schemes.map((s) => ({
          ...s,
          scheme: HolidaySchemeAdapter.graphQlSchemeToScheme(s.scheme),
        })),
      );
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const selectedScheme = useRef<null | HolidayScheme>(null);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [companySchemes, setCompanySchemes] = useState<HolidayScheme[]>([]);

  const openEditDialog = (scheme: HolidayScheme) => {
    selectedScheme.current = scheme;
    setIsEditDialogOpen(true);
  };
  return (
    <>
      <Stack p={1}>
        <Grid container spacing={2}>
          {companySchemes.map((scheme: HolidayScheme) => (
            <Grid key={scheme._id} item xs={12} md={3}>
              <HolidaysSchemeListItem
                openEditDialog={openEditDialog}
                scheme={scheme}
              />
            </Grid>
          ))}
        </Grid>
        {selectedScheme.current && (
          <EditHolidaySchemeDialog
            open={isEditDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(false);
              selectedScheme.current = null;
            }}
            scheme={selectedScheme.current}
          />
        )}
      </Stack>
    </>
  );
};
