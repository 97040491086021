import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CHSFormType } from '../createHolidaySchemeDialog/CreateHolidaySchemeDialog.types';
import { Stack } from '@mui/material';
import { ControlledInput } from '../../../../../../../components/ControlledInputs';
import { createHolidaySchemeFormErrorMessages } from '../createHolidaySchemeDialog/CHSForm';
import {
  Alert,
  Avatar,
  ColorSelector,
} from '../../../../../../../newComponents';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { EditHolidaySchemeDialogFormType } from '../editHolidaySchemeDialog/EditHolidaySchemeDialog.types';

type EditTitleProps = {
  form:
    | UseFormReturn<CHSFormType>
    | UseFormReturn<EditHolidaySchemeDialogFormType>;
};
export const EditTitle: React.FC<EditTitleProps> = ({ form }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form as UseFormReturn<EditHolidaySchemeDialogFormType>;

  const schemeType = watch('selectedScheme.type');
  const isOfficial = schemeType ? schemeType === 'LAW_HOLIDAYS' : false;

  return (
    <>
      <Stack gap={2} mt={2}>
        {isOfficial && (
          <Alert variant="standard" severity="warning">
            No puedes editar los campos de este esquema
          </Alert>
        )}
        <ControlledInput
          control={control}
          name={'title'}
          rules={{
            required: 'Este campo es requerido',
            minLength: {
              value: 3,
              message: 'El nombre debe tener al menos 3 caracteres',
            },
            maxLength: {
              value: 255,
              message: 'La descripción no puede tener más de 255 caracteres',
            },
          }}
          muiProps={{
            label: 'Nombre del esquema de vacaciones',
            inputProps: {
              maxLength: 100,
            },
            disabled: isOfficial,
          }}
          errors={errors}
          errorMessages={createHolidaySchemeFormErrorMessages.name}
        />
        <ControlledInput
          control={control}
          name={'description'}
          rules={{
            maxLength: {
              value: 255,
              message: 'La descripción no puede tener más de 255 caracteres',
            },
          }}
          muiProps={{
            label: 'Descripción',
            fullWidth: true,
            multiline: true,
            rows: 3,
            disabled: isOfficial,
          }}
          errors={errors}
        />
        <Avatar cssColor={watch('color')}>
          <Icon icon={'organization_chart'} fill={'white'} />
        </Avatar>
        <ColorSelector
          color={watch('color')}
          onChange={(c) => setValue('color', c)}
          ommitGradients
          disabled={isOfficial}
        />
      </Stack>
    </>
  );
};
