import { DataGrid, DataGridProps, esES } from '@mui/x-data-grid';
import { Box, SxProps } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { styles } from './Datagrid.styles';
import React from 'react';

export const ColumnSettingsIcon: React.FC<{
  openColumnSettings?: (open: boolean) => void;
  bgcolor?: string;
  iconColor?: string;
  headerHeight?: number;
}> = ({
  openColumnSettings = () => {},
  bgcolor = 'hsl(228deg 83% 98%)',
  iconColor,
  headerHeight,
}) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      width={headerHeight}
      height={headerHeight}
      bgcolor={bgcolor}
    >
      <Icon
        fill={iconColor ?? theme.customPalette.primary.blue}
        height="32px"
        icon="settings_4_line"
        onClick={() => openColumnSettings(true)}
        pointer
      />
    </Box>
  );
};

type CustomSoraGridProps = DataGridProps &
  React.RefAttributes<HTMLDivElement> & {
    borderRadius?: string;
    headerRadius?: string;
    headerColor?: string;
    headerTextColor?: string;
    handleCellClick?: () => void;
    hideConfig?: boolean;
    disabled?: boolean;
    optionNameInColumnSetting?: string;
    minHeight?: string;
    datagridSx?: SxProps;
    openColumnSettings?: () => void;
    headerAlign?: string;
    cellPadding?: string;
    height?: string;
    textTransform?: string;
    textAlign?: string;
  };

export const SoraGrid: React.FC<CustomSoraGridProps> = ({
  borderRadius,
  headerRadius,
  headerColor,
  headerTextColor,
  handleCellClick,
  hideConfig,
  headerHeight = 52,
  rowHeight = 48,
  ...props
}) => {
  const theme = useTheme();
  const styleProps = {
    borderRadius,
    headerRadius,
    headerColor,
    headerTextColor,
    rowHeight,
    headerHeight,
    ...props,
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        onCellClick={handleCellClick}
        sx={styles({ props: styleProps, theme })}
        {...props}
      />
      {!hideConfig && (
        <ColumnSettingsIcon
          bgcolor={headerColor}
          iconColor={headerTextColor}
          openColumnSettings={props.openColumnSettings}
          headerHeight={headerHeight}
        />
      )}
    </Box>
  );
};
