import { Stack } from '@mui/material';
import React from 'react';

interface ScreenHeaderDesktopButtonsWrapperProps {
  children: React.ReactNode;
}
export const ScreenHeaderDesktopButtonsWrapper = (
  props: ScreenHeaderDesktopButtonsWrapperProps,
) => {
  const { children } = props;
  return (
    <Stack
      gap={2}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
    >
      {children}
    </Stack>
  );
};
