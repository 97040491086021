import React from 'react';
import { EditHolidaySchemeDialogFormType } from './EditHolidaySchemeDialog.types';
import { Stack } from '@mui/material';
import { useScreenSize } from '../../../../../../../Hooks';
import { UseFormReturn } from 'react-hook-form';
import { EditHolidaySchemeProperties } from './EditHolidaySchemeProperties';
import { HolidaySchemeDaysGranted } from '../HolidaySchemeDaysGranted/HolidaySchemeDaysGranted';
import {
  DaysEarnedScheme,
  HolidaySchemeTypes,
} from '../../holidaySchemes.types';

type EHSEditSchemeProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
};
export const EHSEditScheme: React.FC<EHSEditSchemeProps> = ({ form }) => {
  const { isMobile } = useScreenSize();
  const scheme: DaysEarnedScheme[] = form.getValues('scheme');
  const type = form.getValues('selectedScheme.type');

  return (
    <>
      <Stack
        className={'EHSEditScheme'}
        direction={isMobile ? 'column' : 'row'}
        gap={1}
      >
        <HolidaySchemeDaysGranted
          scheme={scheme}
          edit={type !== HolidaySchemeTypes.LAW_HOLIDAYS}
          onUpdateScheme={(s) => {
            form.setValue('scheme', s);
          }}
        />
        <EditHolidaySchemeProperties form={form} />
      </Stack>
    </>
  );
};
