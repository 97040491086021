import { useEffect, useRef, useState } from 'react';
import {
  ButtonGroup,
  Button,
  MenuItem,
  Typography,
  Tooltip,
} from '../../../../../newComponents';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Stack,
  useTheme,
} from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { todayAt0Minutes } from '../../../../../utils/utils';
import type { SendDocumentButtonProps } from '../CreateDocumentDialog.types';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { HiddenDatePicker } from '../../../../../newComponents/Datepicker/HiddenDatePicker';
import {
  PermissionAction,
  PermissionCode,
} from '../../../../../Hooks/usePermissionChecker';

type Option = {
  label: string;
  icon: 'time_line' | 'send_plane_line';
};

const options: Option[] = [
  {
    label: 'Enviar ahora',
    icon: 'send_plane_line',
  },
  {
    label: 'Programar envío',
    icon: 'time_line',
  },
];

export const SendDocumentButton = (props: SendDocumentButtonProps) => {
  const { scheduledFor, handleContent, onSubmit, onSchedule } = props;
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  const userHasPermissionToUpdate = usePermissionChecker({
    permission: PermissionCode.docs,
    action: PermissionAction.UPDATE,
  });

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (index) => {
    onSubmit();
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 1) {
      setOpenDatePicker(true);
    } else {
      onSchedule(null);
    }
  };

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!scheduledFor) {
      setSelectedIndex(0);
    }
  }, [scheduledFor]);

  return (
    <>
      <Button
        variant="outlined"
        size={isMobile ? 'small' : 'large'}
        color="default"
        onClick={() => handleContent('SIGNERS')}
      >
        Volver
      </Button>
      <Box position="relative">
        <Tooltip
          title={
            userHasPermissionToUpdate
              ? ''
              : 'No tienes permisos para realizar esta acción'
          }
          placement="left"
          arrow
        >
          <span>
            <ButtonGroup disabled={!userHasPermissionToUpdate}>
              <Button
                color="primary"
                size={isMobile ? 'medium' : 'large'}
                variant="contained"
                onClick={() => handleClick(selectedIndex)}
                startIcon={<Icon icon={options[selectedIndex].icon} />}
                ref={anchorRef}
              >
                {options[selectedIndex].label}
              </Button>
              <Button
                color="primary"
                size={isMobile ? 'medium' : 'large'}
                variant="contained"
                aria-haspopup="menu"
                onClick={handleToggle}
                ref={anchorRef}
              >
                <Icon icon={open ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
              </Button>
            </ButtonGroup>
          </span>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-start"
          style={{
            zIndex: 1,
            width: '100%',
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left-start',
              }}
            >
              <Paper
                sx={{ padding: 1, boxShadow: theme.newPalette.shadow.dropdown }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <Stack direction={'row'} gap={1}>
                          <Icon icon={option.icon} />
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <HiddenDatePicker
          onChange={onSchedule}
          minDate={todayAt0Minutes()}
          value={scheduledFor}
          onClose={() => setOpenDatePicker(false)}
          includeTime={true}
          open={openDatePicker}
        />
      </Box>
    </>
  );
};
