// @ts-check
import React from 'react';
import { Stack as MuiStack, Box as MuiBox } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Backdrop } from './Backdrop';
import { Typography } from '../Typography';
import { CircularProgress } from '../Progress/CircularProgress';
import { LinearProgress } from '../Progress/LinearProgress';
import { globalBackdropVar } from '../../cache.reactiveVars';
import { useScreenSize } from '../../Hooks';

export const GlobalBackdrop = () => {
  const backdrop = useReactiveVar(globalBackdropVar);
  const { isMobile } = useScreenSize();
  const {
    // total = 100,
    current = 0,
    feedbackType = 'circular',
    open = false,
    clickable = false,
    variant = 'indeterminate',
    text = '',
  } = backdrop;

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 'tooltip' }}
      open={open}
      onClick={() => (clickable ? globalBackdropVar({ open: false }) : null)}
    >
      <MuiStack
        alignItems="center"
        justifyContent="center"
        gap="24px"
        width="100%"
      >
        {feedbackType === 'circular' ? (
          <CircularProgress variant={variant} color="primary" value={current} />
        ) : (
          <MuiBox sx={{ minWidth: '350px' }}>
            <LinearProgress variant={variant} color="primary" value={current} />
          </MuiBox>
        )}
        {text && (
          <Typography
            variant="subtitle1"
            textAlign="center"
            maxWidth={isMobile ? 'calc(100% - 20px)' : 500}
          >
            {text}
          </Typography>
        )}
      </MuiStack>
    </Backdrop>
  );
};
