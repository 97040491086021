import { Box, DialogTitle, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../newComponents/Button';
import { Alert } from '../../../newComponents/Alert';
import { Tooltip } from '../../../newComponents/Tooltip';
import { IconButton } from '../../../newComponents/IconButton';
import { useScreenSize } from '../../../Hooks';
import type { FileViewerHeaderProps } from './FileViewerDialog.types';
import { fileViewerDialogVar } from './FileViewerDialog.vars';
import { EmployeeAvatar } from '../../EmployeeAvatar';
import { Tag } from '../../../containers/RHPod/Documents/Tags/Tag';
import {
  Typography,
  TypographyWithRef,
} from '../../../newComponents/Typography/Typography';

export const FileViewerHeader = (props: FileViewerHeaderProps) => {
  const { isMobile } = useScreenSize();
  const fileViewerDialog = useReactiveVar(fileViewerDialogVar);
  const { file, mode, showAlert, alertProps, user } = fileViewerDialog;
  const {
    handleSignFile,
    handleMarkAsViewedFile,
    handleRecordVideoAgreement,
    handleClose,
  } = props;

  const fileTitle = file?.title || '';
  const category = file?.category || '';
  const subcategory = file?.subcategory || '';

  return (
    <DialogTitle component="div" sx={{ padding: '0px' }}>
      {showAlert && (
        <Alert
          icon={<></>}
          variant="standard"
          severity="info"
          {...alertProps}
          sx={{
            '& .MuiAlert-icon': { padding: '3px 0px' },
            '& .MuiAlert-message': { padding: '4px 0px' },
            minHeight: '42px',
            justifyContent: 'center',
            borderBottomLeftRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          }}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: '8px 16px' }}
      >
        <Stack gap={0.5}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            gap={isMobile ? 0.5 : 2}
          >
            <Tooltip title={fileTitle} placement="top" arrow>
              <TypographyWithRef
                variant="h6"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '300px',
                }}
              >
                {fileTitle}
              </TypographyWithRef>
            </Tooltip>
            {category && subcategory && (
              <Tag category={category} subcategory={subcategory} />
            )}
          </Stack>
          {user && (
            <Stack direction="row" alignItems="center" gap={0.5}>
              <EmployeeAvatar
                name={user.names}
                profilePicture={user.profilePicture}
                size="tiny"
              />
              <Typography variant="body2" color="grey">
                {`${user.names} ${user.lastNameP} ${user.lastNameM}`.trim()}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          {!isMobile &&
            mode === 'VISIBLE_ONLY' &&
            fileViewerDialog.statusChip && (
              <Box>{fileViewerDialog.statusChip}</Box>
            )}
          {!isMobile && mode === 'SIGNABLE' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignFile}
            >
              Firmar
            </Button>
          )}
          {!isMobile && mode === 'MARK_AS_VIEWED' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleMarkAsViewedFile}
            >
              Marcar como visto
            </Button>
          )}
          {!isMobile && mode === 'VIDEO_AGREEMENT' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleRecordVideoAgreement}
            >
              Grabar video acuerdo
            </Button>
          )}
          <IconButton icon="close_line" onClick={handleClose} />
        </Stack>
      </Stack>
      {isMobile && mode === 'VISIBLE_ONLY' && fileViewerDialog.statusChip && (
        <Box sx={{ px: 2, pb: 1 }}>{fileViewerDialog.statusChip}</Box>
      )}
    </DialogTitle>
  );
};
