import React from 'react';
import { Assignee } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/CreateHolidayAuthorizationTeamDialog.types';
import { RowBaseItem } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { DaysEarnedScheme } from '../../holidaySchemes.types';

export type CreateHolidaySchemeDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export type CHSFormType = {
  title: string;
  description: string;
  color: React.CSSProperties['color'];
  // step: DaysGranted
  approveTemplateId: string | null;
  holidayTakenTemplateId: string | null;
  scheme: DaysEarnedScheme[];
  // step: Employees
  assignedTo: Assignee[];
  assignedToErrors: RowBaseItem[];
};

export enum CreateHolidaySchemeSteps {
  // eslint-disable-next-line no-unused-vars
  Title = 'Title',
  // eslint-disable-next-line no-unused-vars
  DaysGranted = 'DaysGranted',
  // eslint-disable-next-line no-unused-vars
  Employees = 'Employees',
}
