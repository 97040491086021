import { DaysEarnedScheme } from '../../holidaySchemes.types';

export const defaultHolidayScheme: DaysEarnedScheme[] = [
  { year: 1, days: 6 },
  { year: 2, days: 6 },
  { year: 3, days: 8 },
  {
    year: 4,
    days: [
      { month: 0, days: 1 },
      { month: 1, days: 2 },
      { month: 2, days: 3 },
    ],
  },
  {
    year: 5,
    days: [
      { month: 0, days: 1 },
      { month: 1, days: 2 },
      { month: 2, days: 3 },
    ],
  },
];
