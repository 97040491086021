import { useState } from 'react';
import { DaysEarnedScheme } from '../../holidaySchemes.types';
import { globalSnackbarVar } from '../../../../../../../cache.reactiveVars';

type UseHolidaySchemeGrantedProps = {
  scheme: DaysEarnedScheme[];
  edit?: boolean;
};

type UseHolidaySchemeGrantedReturn = {
  states: {
    editableScheme: DaysEarnedScheme[];
    editMode: boolean;
  };
  actions: {
    setEditableScheme: (scheme: DaysEarnedScheme[]) => void;
    updateDaysGrantedPerYear: (
      year: number,
      days: DaysEarnedScheme['days'],
    ) => void;
    setEditMode: (edit: boolean) => void;
    setDaysGrantedPerYearAsNumber: (year: number, days: number) => void;
    updateYearOfScheme: (oldYear: number, newYear: number) => void;
    addYearDefinitionToScheme: () => void;
    removeLastYearDefinitionFromScheme: () => void;
  };
};

export const useHolidaySchemeGranted: (
  props: UseHolidaySchemeGrantedProps,
) => UseHolidaySchemeGrantedReturn = ({ scheme, edit = false }) => {
  const [editableScheme, setEditableScheme] =
    useState<DaysEarnedScheme[]>(scheme);
  const [editMode, setEditMode] = useState(edit);

  const updateDaysGrantedPerYear = (
    year: number,
    days: DaysEarnedScheme['days'],
  ) => {
    setEditableScheme((prev) => {
      return prev.map((scheme) => {
        if (scheme.year === year) {
          return { ...scheme, days };
        }
        return scheme;
      });
    });
  };

  //this should be called when the user closes the detail (per month) view
  const setDaysGrantedPerYearAsNumber = (year: number, days: number) => {
    setEditableScheme((prev) => {
      return prev.map((scheme) => {
        if (scheme.year === year) {
          return { ...scheme, days };
        }
        return scheme;
      });
    });
  };

  // this should be called when the user changes the year of the scheme,
  // example: from 2022 to 2023 or from 2022 to 2022-2023
  const updateYearOfScheme = (oldYear: number, newYear: number) => {
    setEditableScheme((prev) => {
      return prev.map((scheme) => {
        if (scheme.year === oldYear) {
          return { ...scheme, year: newYear };
        }
        return scheme;
      });
    });
  };

  const addYearDefinitionToScheme = () => {
    const lastYearScheme = editableScheme[editableScheme.length - 1];
    const greaterYearString = lastYearScheme.year + 1;
    if (greaterYearString > 35) {
      return globalSnackbarVar({
        show: true,
        message: 'No se pueden añadir más años al esquema',
        severity: 'error',
      });
    }

    setEditableScheme([
      ...editableScheme,
      {
        year: greaterYearString,
        days: 0,
      },
    ]);
  };

  const removeLastYearDefinitionFromScheme = () => {
    if (editableScheme.length === 1) {
      return;
    }
    setEditableScheme((prev) => {
      return prev.slice(0, prev.length - 1);
    });
  };

  return {
    states: {
      editableScheme,
      editMode,
    },
    actions: {
      setEditableScheme,
      updateDaysGrantedPerYear,
      setEditMode,
      setDaysGrantedPerYearAsNumber,
      updateYearOfScheme,
      addYearDefinitionToScheme,
      removeLastYearDefinitionFromScheme,
    },
  };
};
