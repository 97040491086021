// @ts-check
import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { hexToRgbHelper } from '../../../../utils/utils';
import { currentCompanyVar } from '../../../../cache.reactiveVars';
import { TypographyWithRef } from '../../../../newComponents/Typography/Typography';
import {
  documentTypeContainer,
  categoryBoxContainer,
  categoryContainer,
  subcategoryBoxContainer,
  subcategoryContainter,
} from './style';
import { Tooltip } from '../../../../newComponents';

/**
 * @param {object} props
 * @param {object} [props.color]
 * @param {object} props.category
 * @param {object} props.subcategory
 */
export const Tag = ({ color, category, subcategory }) => {
  const theme = useTheme();
  const currentCompany = currentCompanyVar();
  const recordTag = currentCompany?.configs?.employeeRecordsTags?.find(
    (item) => item.category === category,
  );

  let categoryColor = color;
  if (!categoryColor && category === 'Sora') {
    categoryColor = theme.newPalette.success.main;
  } else if (!categoryColor) {
    categoryColor = recordTag?.color;
  }

  const colorWithOpacity = hexToRgbHelper(categoryColor, 0.6);

  return (
    <Stack>
      <Box sx={{ ...documentTypeContainer }}>
        <Box
          sx={{
            ...categoryBoxContainer,
            backgroundColor: colorWithOpacity,
          }}
        >
          <TypographyWithRef
            sx={categoryContainer}
            variant="caption"
            bold
            ellipsis
          >
            {category}
          </TypographyWithRef>
        </Box>
        <Box
          sx={{
            ...subcategoryBoxContainer,
            border: `2px solid ${colorWithOpacity}`,
          }}
        >
          <Tooltip title={subcategory} placement="top" arrow>
            <TypographyWithRef
              sx={subcategoryContainter}
              variant="caption"
              bold
              ellipsis
            >
              {subcategory}
            </TypographyWithRef>
          </Tooltip>
        </Box>
      </Box>
    </Stack>
  );
};
