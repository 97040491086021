import React, { useRef, useState } from 'react';
import { Grid, Popover, Stack } from '@mui/material';
import Calendar from 'react-calendar';
import { add, isSameDay, sub } from 'date-fns';
import './SoraCalendar.css';
import { CalendarEventsSideBar } from './CalendarEventsSideBar';
import { CalendarEvent, CalendarView } from './SoraCalendar.types';
import { useScreenSize } from '../../Hooks';
import { SoraCalendarYearNavigation } from './SoraCalendarYearNavigation';
import { Typography } from '../Typography';
import { getMonthNameFromMonthIndex } from '../../utils/date.utils';
import { SoraCalendarMonthNavigation } from './SoraCalendarMonthNavigation';

type SoraCalendarProps = {
  events?: CalendarEvent[];
  handleEmptyDayClick?: (date: Date) => void;
  handleDeleteEventClick?: (event: CalendarEvent) => void;
  handleEventClick?: (event: CalendarEvent) => void;
  useMobileVersion?: boolean;
  hideSideBar?: boolean;
  isSupportPanel?: boolean;
  isEmployeeView?: boolean;
  isMyCalendarView?: boolean;
};

function getMonthsDates(year: number): Date[] {
  const dates = [];
  for (let i = 0; i < 12; i++) {
    dates.push(new Date(year, i, 1));
  }
  return dates;
}
export const formatShortWeekday = (locale: string, date: Date) =>
  new Intl.DateTimeFormat(locale, {
    weekday: 'narrow',
  }).format(date);

export const SoraCalendar: React.FC<SoraCalendarProps> = ({
  events = [],
  handleEmptyDayClick,
  handleDeleteEventClick,
  handleEventClick,
  isSupportPanel = false,
  useMobileVersion = false,
  hideSideBar = false,
  isEmployeeView = false,
  isMyCalendarView = false,
}) => {
  const { isMobile } = useScreenSize();
  const [calendarView, setCalendarView] = useState<CalendarView>(
    isMobile || useMobileVersion || isMyCalendarView
      ? CalendarView.MONTH
      : CalendarView.YEAR,
  );
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState<number>(
    calendarDate.getFullYear(),
  );

  const updateCurrentYear = (year: number) => {
    setCurrentYear(year);
    const dateToSet = new Date(calendarDate);
    dateToSet.setFullYear(year);
    setCalendarDate(dateToSet);
  };
  const updateMonth = (isNext = false) => {
    const dateToSet = isNext
      ? add(calendarDate, { months: 1 })
      : sub(calendarDate, { months: 1 });
    setCalendarDate(dateToSet);
    setCurrentYear(dateToSet.getFullYear());
  };

  const updateCalendarDateToToday = () => {
    setCalendarDate(new Date());
    setCurrentYear(new Date().getFullYear());
  };

  const onChangeCalendarView = (view: CalendarView) => {
    setCalendarView(view);
  };

  const monthsDates = getMonthsDates(currentYear);

  const selectedEvent = useRef<CalendarEvent | null>(null);

  //popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, date: Date) => {
    const event = events.find((event) => isSameDay(event.date, date));
    if (!event) {
      handleEmptyDayClick?.(date);
      return;
    }
    selectedEvent.current = event;
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'event-popover' : undefined;

  const tileClassName = ({ date }) => {
    const event = events.find(
      (e) => e.date.toDateString() === date.toDateString(),
    );
    return event ? `event event-${event.color.replace('#', '')}` : '';
  };

  const onClickDay = (day: Date, event: React.MouseEvent<HTMLButtonElement>) =>
    handleClick(event, day);

  const isYearView = calendarView === CalendarView.YEAR;

  return (
    <div>
      <Stack
        direction={isMobile || useMobileVersion ? 'column' : 'row'}
        gap={2}
      >
        <Stack className={'calendar'} sx={{ width: '100%' }} direction={'row'}>
          <Stack flex={1}>
            {isYearView && (
              <SoraCalendarYearNavigation
                currentYear={currentYear}
                setCurrentYear={updateCurrentYear}
                onChangeView={onChangeCalendarView}
                onTodayClick={updateCalendarDateToToday}
                currentView={calendarView}
              />
            )}
            {isMyCalendarView && !isYearView && (
              <SoraCalendarMonthNavigation
                currentDate={calendarDate}
                onPrevClick={() => updateMonth(false)}
                onNextClick={() => updateMonth(true)}
                onTodayClick={() => updateCalendarDateToToday()}
                onChangeView={onChangeCalendarView}
                currentView={calendarView}
              />
            )}
            <Grid container justifyContent={'center'}>
              <Grid
                className={'calendar_months'}
                p={'0 !important'}
                item
                xs={12}
                md={12}
              >
                <Grid container spacing={3} sx={{ p: '0 !important' }}>
                  {!isYearView ? (
                    <Grid
                      item
                      xs={12}
                      className={'calendar_month'}
                      sx={{ p: '0 !important' }}
                    >
                      <Calendar
                        locale="es"
                        defaultValue={calendarDate}
                        onChange={(val) => setCalendarDate(val as Date)}
                        onActiveStartDateChange={({ activeStartDate }) => {
                          setCalendarDate(activeStartDate);
                        }}
                        activeStartDate={calendarDate}
                        prev2Label={null}
                        next2Label={null}
                        maxDetail="month"
                        minDetail={'month'}
                        formatShortWeekday={formatShortWeekday}
                        tileClassName={tileClassName}
                        showNeighboringMonth={true}
                        onClickDay={onClickDay}
                        className={
                          isMyCalendarView
                            ? 'myCalendar-soraCalendar'
                            : undefined
                        }
                        showNavigation={false}
                      />
                    </Grid>
                  ) : (
                    monthsDates.map((date) => (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        key={date.toString()}
                      >
                        <Calendar
                          locale="es"
                          navigationLabel={({ date }) =>
                            getMonthNameFromMonthIndex(date.getMonth())
                          }
                          value={date}
                          prevLabel={null}
                          prev2Label={null}
                          next2Label={null}
                          nextLabel={null}
                          maxDetail="month"
                          minDetail="month"
                          formatShortWeekday={formatShortWeekday}
                          tileClassName={tileClassName}
                          showNeighboringMonth={false}
                          onClickDay={onClickDay}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          {!hideSideBar && (
            <CalendarEventsSideBar
              handleDeleteEventClick={handleDeleteEventClick}
              handleEventClick={handleEventClick}
              events={events}
              isSupportPanel={isSupportPanel}
              currentDate={calendarDate}
              view={calendarView}
              isEmployeeView={isEmployeeView}
            />
          )}
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack p={1}>
            <Typography variant="body2">
              {selectedEvent?.current?.title}
            </Typography>
          </Stack>
        </Popover>
      </Stack>
    </div>
  );
};
