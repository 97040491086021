// @ts-check
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { loggerUtil } from '../../../../../utils/loggerUtil.js';
import { ConfirmDialog } from '../../../../../newComponents/Dialog/index.js';
import { TextInput } from '../../../../../newComponents/TextInput/index.js';
import { validationUtil } from '../../../../../utils/fieldUtils.js';
import { SEND_ONBOARDING_NOTIFICATION } from '../EmployeeInfo.gql.js';

import { handleSendOnboardingNotifResponse } from './SendOnboardingNotifDialog.helpers.js';
import {
  resetSendOnboardingNotifDialogVar,
  sendOnboardingNotifDialogVar,
} from '../../Employees.vars.js';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
/**
 *
 * @typedef {import('../../Employees.vars.js').SendOnboardingNotifDialogVar} SendOnboardingNotifDialogVar
 */

export function SendOnboardingNotifDialog() {
  /**
   * Instance of SendOnboardingNotifDialog for managing the state of the add user email dialog.
   * @type {SendOnboardingNotifDialogVar}
   */
  const { open, employeeId, refetchFunc } = useReactiveVar(
    sendOnboardingNotifDialogVar,
  );

  const [sendOnboardingNotif] = useMutation(SEND_ONBOARDING_NOTIFICATION);

  const sendOnboardingNotifDialogForm = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!open) {
      sendOnboardingNotifDialogForm.reset();
      resetSendOnboardingNotifDialogVar();
    }
  }, [open, sendOnboardingNotifDialogForm]);

  const onClose = () => {
    resetSendOnboardingNotifDialogVar();
  };
  const onConfirm = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Enviando notifiación de onboarding...',
      });
      const email = sendOnboardingNotifDialogForm.getValues('email');
      const sendRes = await sendOnboardingNotif({
        variables: {
          input: {
            employeeId,
            email,
          },
        },
      });
      handleSendOnboardingNotifResponse({
        response: sendRes.data,
        form: sendOnboardingNotifDialogForm,
        refetchFunc,
      });
    } catch (e) {
      loggerUtil.error(e);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'Ocurrio un problema con tu petición, intenta de nuevo o contacta a soporte.',
      });
      resetSendOnboardingNotifDialogVar();
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const titleText = 'Atención';
  const bodyText =
    'Para enviar una notificación, deberás agregar su correo electrónico.';

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      confirmLabel="Enviar"
      cancelLabel="Cancelar"
      confirmButtonToRight
      onCancel={onClose}
      onConfirm={onConfirm}
      title={titleText}
    >
      <Stack spacing={2}>
        <Typography variant="body1">{bodyText}</Typography>
        <Controller
          name="email"
          control={sendOnboardingNotifDialogForm.control}
          rules={{
            validate: (value) => {
              return validationUtil.email(value) || errorMessages.validate;
            },
            required: true,
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <TextInput
                label="Correo electrónico"
                name="email"
                value={value}
                onChange={onChange}
                helperText={
                  sendOnboardingNotifDialogForm.formState.errors.email?.message
                }
                error={!!sendOnboardingNotifDialogForm.formState.errors.email}
              />
            );
          }}
        />
      </Stack>
    </ConfirmDialog>
  );
}

const errorMessages = {
  required: 'Campo requerido',
  validate: 'Correo electrónico inválido',
};
