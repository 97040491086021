import {
  DaysEarnedByYear,
  GraphQlDaysEarnedScheme,
} from '../holidaySchemes.types';

export class HolidaySchemeAdapter {
  static graphQlSchemeToScheme(
    scheme: GraphQlDaysEarnedScheme[],
  ): DaysEarnedByYear[] {
    return scheme.map((s) => {
      return {
        year: s.year,
        days: s.days || s.daysPerMonth,
      };
    });
  }

  static schemeToGraphQlScheme(
    scheme: DaysEarnedByYear[],
  ): GraphQlDaysEarnedScheme[] {
    return scheme.map((sc) => {
      return {
        year: sc.year,
        days: typeof sc.days === 'number' ? sc.days : undefined,
        daysPerMonth:
          typeof sc.days === 'number'
            ? undefined
            : sc.days.map((d) => ({
                month: d.month,
                days: d.days,
              })),
      };
    });
  }
}
