const variableToReplaceBE = {
  '[Nombre_de_la_Empresa]': '{{company.name}}',
  '[Nombre_del_Empleado]': '{{user.name}}',
  '[Telefono_del_Empleado]': '{{user.phone}}',
  '[RFC_del_Empleado]': '{{user.rfc}}',
  '[CURP_del_Empleado]': '{{user.curp}}',
  '[Fecha_de_nacimiento_del_empleado]': '{{user.birthdate}}',
  '[Edad_del_Empleado]': '{{user.age}}',
  '[Sexo]': '{{user.gender}}',
  '[Estado_Civil_del_empleado]': '{{user.maritalStatus}}',
  '[Numero_de_Seguridad_Social]': '{{user.socialSecurityNumber}}',
  '[Pais_de_Nacimiento]': '{{user.address.country}}',
  '[Codigo_Postal]': '{{user.address.zipCode}}',
  '[Estado]': '{{user.address.state}}',
  '[Ciudad]': '{{user.address.city}}',
  '[Colonia]': '{{user.address.colony}}',
  '[Calle]': '{{user.address.street}}',
  '[Numero_exterior]': '{{user.address.externalNumber}}',
  '[Numero_interior]': '{{user.address.internalNumber}}',
  '[Salario]': '{{workSpace.salary}}',
  '[Tipo_de_Contrato]': '{{workSpace.contractType}}',
  '[Tipo_de_Jornada]': '{{workSpace.workDayType}}',
  '[Fecha_de_Ingreso]': '{{workSpace.employmentStartDate}}',
  '[Antiguedad]': '{{workSpace.jobSeniority}}',
  '[No_nomina_del_Empleado]': '{{workSpace.payrollId}}',
  '[Puesto_del_Empleado]': '{{workSpace.workTitle}}',
  '[Centro_de_Trabajo_del_Empleado]': '{{workCenter.name}}',
  '[Fecha_de_hoy]': '{{todayDate}}',
  '[Dias_solicitados]': '{{daysRequested}}',
};

export const replaceVariablesFe = ({ employee }) => {
  const variableToReplaceFE = {
    '[Nombre_de_la_Empresa]': employee.company.name,
    '[Nombre_del_Empleado]': employee.user.name,
    '[Telefono_del_Empleado]': employee.user.phone,
    '[RFC_del_Empleado]': employee.user.rfc,
    '[No_nomina_del_Empleado]': employee.workSpace.payrollId,
    '[Puesto_del_Empleado]': employee.workSpace.workTitle,
    '[Centro_de_Trabajo_del_Empleado]': employee.workCenter.name,
    '[CURP_del_Empleado]': employee.user.curp,
    '[Fecha_de_nacimiento_del_empleado]': employee.user.birthdate,
    '[Edad_del_Empleado]': employee.user.age,
    '[Sexo]': employee.user.gender,
    '[Estado_Civil_del_empleado]': employee.user.maritalStatus,
    '[Pais_de_Nacimiento]': employee.user.address.country,
    '[Codigo_Postal]': employee.user.address.zipCode,
    '[Estado]': employee.user.address.state,
    '[Ciudad]': employee.user.address.city,
    '[Colonia]': employee.user.address.colony,
    '[Calle]': employee.user.address.street,
    '[Numero_exterior]': employee.user.address.externalNumber,
    '[Numero_interior]': employee.user.address.internalNumber,
    '[Salario]': employee.workSpace.salary,
    '[Numero_de_Seguridad_Social]': employee.user.socialSecurityNumber,
    '[Tipo_de_Contrato]': employee.workSpace.contractType,
    '[Tipo_de_Jornada]': employee.workSpace.workDayType,
    '[Fecha_de_Ingreso]': employee.workSpace.employmentStartDate,
    '[Antiguedad]': employee.workSpace.jobSeniority,
    '[Fecha_de_hoy]': employee?.todayDate,
    '[Dias_solicitados]': '13 de enero de 2025, 14 de enero de 2025',
  };

  return variableToReplaceFE;
};

export const htmlDomPurify = (data) => {
  if (data.content && !data.preview) {
    if (data?.useFeVariables && data?.employeeToReplace) {
      const varsToUseFe = replaceVariablesFe({
        employee: data.employeeToReplace,
      });
      Object.keys(varsToUseFe).forEach((key) => {
        data.content = data.content.replaceAll(
          key,
          varsToUseFe[key] || `<span style="color: red;">${key}</span>`,
        );
      });
    } else {
      Object.keys(variableToReplaceBE).forEach((key) => {
        data.content = data.content.replaceAll(key, variableToReplaceBE[key]);
      });
    }
  }

  return `
  <!DOCTYPE html>
  <html>
    <head>
      <title id="Head"></title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8; width=device-width"
      />
      <style>
        // * {
        //     font-family: Arial, Helvetica, sans-serif;
        // }

        body { font-family:Arial, Helvetica,sans-serif; font-size:14px }
       
        .title {
          color: blue; 
          font-weight: 700;
          font-size: 34px;
          line-height: 128%; 
          letter-spacing: 0px; 
          text-align: center;
        }
        .date {
          margin-bottom: 16px; 
          margin-left: 16px;
        }
        .variables {
          line-height: 24px; 
          margin-left: 16px;
        }

        .main {
          line-height: 24px !important;
          margin: 16px;
        }

        .container_sign {
          padding: 10px 10px 110px 10px; 
          display: flex; 
          justify-content: space-around;
        }

        .sign {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column; 
          padding: 0 80px;
          border-top: 1px solid;
          display: flex;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
        }

        .hash {
          border-top: 1px solid;
          display: flex;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
        }

      </style>
    </head>
    <body>
      ${
        data.logo
          ? `<img class='logo' height='100px' alt='logo' src=${
              data.preview ? data.logo : '{{firstImgUrl}}'
            }>`
          : ''
      }
      ${
        data.title
          ? `<h2 class="title">
          ${data.title}
        </h2>`
          : ''
      }
${
  ''
  // TODO: add date as variable <p class="date">Fecha: ${format(new Date(), 'd MMM hh:mm a y OOOO', {
  //   locale: es,
  // })}
}
      </p>
      <div class="main">
        ${data.content}
      </div>
      
      ${
        data.image
          ? `<img class='extraImage' height='100px' alt='logo' src=${
              data.preview ? data.image : '{{secondImgUrl}}'
            }>`
          : ''
      }
    </body>
  </html>
  `;
};

export const getHmtlFromCreator = ({
  data,
  preview,
  content,
  useFeVariables = false,
  employeeToReplace = null,
}) => {
  const { signable } = data;
  const htmlPopulate = htmlDomPurify({
    logo: null,
    preview,
    image: null,
    signable,
    content,
    useFeVariables,
    employeeToReplace,
  });
  return htmlPopulate;
};

export const createHtmlToReplaceVars = ({
  addUserAsVariable = false,
  employee = null,
  preview = true,
  htmlTemplate,
}) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlTemplate, 'text/html');
  const mainElement = doc.querySelector('.main');
  const content = mainElement?.innerHTML || htmlTemplate;
  const htmlPopulate = getHmtlFromCreator({
    data: {},
    preview,
    content: content || '',
    useFeVariables: Boolean(addUserAsVariable),
    employeeToReplace: employee,
  });
  return htmlPopulate;
};

export const dynamicFields = (theme) => {
  return {
    employees: {
      text: 'Empleados',
      color: theme.customPalette.primary.blue,
      icon: 'team_line',
      label: 'Buscar empleados o centros de trabajo',
      warning:
        'Puedes seleccionar solo a los empleados y centros de trabajo que tengas asignados.',
    },
    companySigners: {
      text: 'Firmantes de empresa',
      color: theme.customPalette.status.success_green_70,
      icon: 'user_2_line',
      label: 'Buscar usuarios con permisos para firmar documentos',
      warning:
        'Sólo los usarios con permiso para firmar documentos apareceran en esta lista. ',
    },
  };
};
