import React, { useState } from 'react';
import { Stack, ListItemButton, Divider } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Popover, List, Tooltip, Typography } from '../../newComponents';
import {
  getEmployeeLegalStatusButtonProps,
  getEmployeeStatusButtonProps,
} from './EmployeeActionsButton.helper';
import { usePermissionChecker } from '../../Hooks';
import {
  PermissionAction,
  PermissionCode,
} from '../../Hooks/usePermissionChecker';

export const EmployeeActionsButton = ({
  alta,
  currentStatus,
  currentLegalStatus,
  currentAbsenceStatus,
  userId,
  employeeRfc,
  workSpaceId,
  workCenterId,
  employeeBlocked,
  employeeData,
  sendOnboardingNotifData = null,
  handlePrintLetter,
  handleSendRecovery,
  handleChangeStatus,
  handleChangeLegalStatus,
  handleDownloadEmployeeRecords,
  handleSendNotificationToSingleUser,
  handleChangeBlockUserAccess,
  handleOpenAbsentDialog,
  handleSendOnboardingNotif = null,
  handleGetOnboardingLink,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const [
    employeesStatusUpdatePermissionGranted,
    employeesEditReadPermissionGranted,
    receiptsReadPermissionGranted,
    documentsReadPermissionGranted,
  ] = usePermissionChecker([
    {
      permission: PermissionCode.employeesStatus,
      action: PermissionAction.UPDATE,
    },
    {
      permission: PermissionCode.mply,
      action: PermissionAction.READ,
    },
    {
      permission: PermissionCode.recs,
      action: PermissionAction.READ,
    },
    {
      permission: PermissionCode.docs,
      action: PermissionAction.READ,
    },
  ]);

  const employeeStatusName = currentStatus.name;
  const employeeLegalStatusName = currentLegalStatus?.name;
  const isEmployeeBaja = employeeStatusName === 'BAJA';
  const isPendingAbsence = currentAbsenceStatus?.name === 'PENDING';
  const canEmployeeBeDeleted = isEmployeeBaja;
  const showGenerateOnboardingLinkButton =
    currentStatus?.name === 'PREREGISTERED' &&
    employeeData?.alta === 'SIFE' &&
    currentLegalStatus?.name !== 'SUING';

  const employeeStatusButtonProps = getEmployeeStatusButtonProps({
    employeeStatusName,
  });

  const employeeLegalStatusButtonProps = getEmployeeLegalStatusButtonProps({
    employeeLegalStatusName,
  });

  const permissionsTooltipTitle = employeesStatusUpdatePermissionGranted
    ? ''
    : 'No tienes permisos para esta acción';

  const suingButtonTooltipTitle = employeesStatusUpdatePermissionGranted
    ? employeeLegalStatusName === 'SUING'
      ? 'Activa este botón cuando la demanda del colaborador haya concluido'
      : 'Activa este botón cuando recibas una demanda por parte del coolaborador'
    : 'No tienes permisos para esta acción';

  const userCanDownloadEmployeeRecords =
    employeesEditReadPermissionGranted &&
    (receiptsReadPermissionGranted || documentsReadPermissionGranted);

  const userCanDownloadLetter =
    employeesEditReadPermissionGranted && documentsReadPermissionGranted;

  const showPrintLetterButton = alta === 'CARTA';

  const showSendOnboardingAlert =
    employeeStatusName === 'PREREGISTERED' &&
    alta === 'SIFE' &&
    employeeLegalStatusName !== 'SUING';

  return (
    <Stack alignItems="center">
      <Icon icon={'more_2_line'} onClick={handleClick} pointer />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: '15px',
            },
          },
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'Background' }}
          aria-label="contacts"
        >
          <ListItemButton
            onClick={() =>
              handleSendRecovery({
                employeeId: userId,
                workSpaceId: workSpaceId,
              })
            }
          >
            <Tooltip title={permissionsTooltipTitle}>
              <span
                style={{
                  display: 'flex',
                  gap: '12px',
                }}
              >
                <Icon icon="mail_lock_line" pointer />
                <Typography> Enviar recuperación de contraseña</Typography>
              </span>
            </Tooltip>
          </ListItemButton>
          <ListItemButton onClick={handleSendNotificationToSingleUser}>
            <Tooltip title={permissionsTooltipTitle}>
              <span
                style={{
                  display: 'flex',
                  gap: '12px',
                }}
              >
                <Icon icon="mail_send_line" pointer />
                <Typography>
                  {' '}
                  Enviar recordatorio de firmar pendientes
                </Typography>
              </span>
            </Tooltip>
          </ListItemButton>
          {showGenerateOnboardingLinkButton && (
            <ListItemButton onClick={() => handleGetOnboardingLink(userId)}>
              <Tooltip title={permissionsTooltipTitle}>
                <span
                  style={{
                    display: 'flex',
                    gap: '12px',
                  }}
                >
                  <Icon icon="link_m" pointer />
                  <Typography>Copiar link de onboarding</Typography>
                </span>
              </Tooltip>
            </ListItemButton>
          )}
          {showSendOnboardingAlert && handleSendOnboardingNotif && (
            <Tooltip
              title={
                !sendOnboardingNotifData?.canSendOnboardingNotif
                  ? sendOnboardingNotifData?.message ||
                    'Se ha enviado una notificación recientemente. Debes esperar 24 horas'
                  : ''
              }
              placement="top"
            >
              <span>
                <ListItemButton
                  onClick={() => handleSendOnboardingNotif(employeeData)}
                  disabled={!sendOnboardingNotifData?.canSendOnboardingNotif}
                  sx={{ display: 'flex', gap: '12px' }}
                >
                  <Icon icon="mail_line" pointer />
                  <Typography> Enviar recordatorio de onboarding</Typography>
                </ListItemButton>
              </span>
            </Tooltip>
          )}
          <Divider />
          <Tooltip title={permissionsTooltipTitle} placement="top">
            <span>
              {isPendingAbsence ? (
                <ListItemButton
                  onClick={() => handleOpenAbsentDialog(employeeData)}
                  disabled={!employeesStatusUpdatePermissionGranted}
                >
                  <Icon icon="rest_time_line" pointer />
                  <Typography style={{ marginLeft: '12px' }}>
                    Finalizar ausencia
                  </Typography>
                </ListItemButton>
              ) : (
                <ListItemButton
                  onClick={() => handleOpenAbsentDialog(employeeData)}
                  disabled={!employeesStatusUpdatePermissionGranted}
                >
                  <Icon icon="rest_time_line" pointer />
                  <Typography style={{ marginLeft: '12px' }}>
                    Registrar ausencia
                  </Typography>
                </ListItemButton>
              )}
            </span>
          </Tooltip>

          <Tooltip title={permissionsTooltipTitle} placement="top">
            <span>
              <ListItemButton
                onClick={() => {
                  handleChangeStatus({
                    employeeId: userId,
                    actionType: employeeStatusButtonProps.actionType,
                    workCenterId: workCenterId,
                  });
                }}
                disabled={!employeesStatusUpdatePermissionGranted}
              >
                <Icon icon={employeeStatusButtonProps.icon} pointer />
                <Typography style={{ marginLeft: '12px' }}>
                  {employeeStatusButtonProps.title}
                </Typography>
              </ListItemButton>
            </span>
          </Tooltip>
          {isEmployeeBaja && (
            <Tooltip title={permissionsTooltipTitle} placement="top">
              <span>
                <ListItemButton
                  onClick={() =>
                    handleChangeBlockUserAccess({
                      employeeId: userId,
                      blockEmployee: !employeeBlocked,
                    })
                  }
                  disabled={!employeesStatusUpdatePermissionGranted}
                >
                  <Icon
                    icon={employeeBlocked ? 'login_circle_line' : 'forbid_line'}
                    pointer
                  />
                  <Typography style={{ marginLeft: '12px' }}>
                    {employeeBlocked
                      ? 'Desbloquear acceso a sora'
                      : 'Bloquear acceso a sora'}
                  </Typography>
                </ListItemButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title={suingButtonTooltipTitle} placement={'top'}>
            <span>
              <ListItemButton
                onClick={() => {
                  handleChangeLegalStatus({
                    employeeId: userId,
                    legalStatus: currentLegalStatus,
                  });
                }}
                disabled={!employeesStatusUpdatePermissionGranted}
              >
                <Icon icon={employeeLegalStatusButtonProps.icon} pointer />
                <Typography style={{ marginLeft: '12px' }}>
                  {employeeLegalStatusButtonProps.title}
                </Typography>
              </ListItemButton>
            </span>
          </Tooltip>
          {canEmployeeBeDeleted && (
            <Tooltip title={permissionsTooltipTitle} placement="top">
              <span>
                <ListItemButton
                  onClick={() =>
                    handleChangeStatus({
                      employeeId: userId,
                      actionType: 'DELETE',
                      workCenterId: workCenterId,
                    })
                  }
                  disabled={!employeesStatusUpdatePermissionGranted}
                >
                  <Icon icon="delete_bin_5_line" pointer />
                  <Typography style={{ marginLeft: '12px' }}>
                    Eliminar empleado
                  </Typography>
                </ListItemButton>
              </span>
            </Tooltip>
          )}
          <Divider />
          {showPrintLetterButton && (
            <Tooltip
              title={
                !userCanDownloadLetter && 'No tienes permisos para esta acción'
              }
            >
              <span
                style={{
                  display: 'flex',
                  gap: '12px',
                }}
              >
                <ListItemButton
                  onClick={() => {
                    handlePrintLetter({
                      employeeId: userId,
                    });
                  }}
                  disabled={!userCanDownloadLetter}
                  sx={{ display: 'flex', gap: '12px' }}
                >
                  <Icon icon="printer_line" pointer />
                  <Typography>Imprimir carta</Typography>
                </ListItemButton>
              </span>
            </Tooltip>
          )}
          <Tooltip
            title={
              !userCanDownloadEmployeeRecords &&
              'No tienes permisos para esta acción'
            }
            placement="top"
          >
            <span
              style={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <ListItemButton
                onClick={() => {
                  handleDownloadEmployeeRecords({
                    employeeId: userId,
                    employeeRfc: employeeRfc,
                  });
                }}
                sx={{ display: 'flex', gap: '12px' }}
                disabled={!userCanDownloadEmployeeRecords}
              >
                <Icon icon="download_2_line" pointer />
                <Typography>Descargar expediente</Typography>
              </ListItemButton>
            </span>
          </Tooltip>
        </List>
      </Popover>
    </Stack>
  );
};
