import React from 'react';
import { DaysEarnedScheme } from '../../holidaySchemes.types';
import { UseFormReturn } from 'react-hook-form';
import { CHSFormType } from './CreateHolidaySchemeDialog.types';
import { useScreenSize } from '../../../../../../../Hooks';
import { Stack } from '@mui/material';
import { EditHolidaySchemeProperties } from '../editHolidaySchemeDialog/EditHolidaySchemeProperties';
import { HolidaySchemeDaysGranted } from '../HolidaySchemeDaysGranted/HolidaySchemeDaysGranted';

export const CHSEditScheme: React.FC<{ form: UseFormReturn<CHSFormType> }> = ({
  form,
}) => {
  const { isMobile } = useScreenSize();

  const scheme: DaysEarnedScheme[] = form.getValues('scheme');
  return (
    <>
      <Stack
        className={'EHSEditScheme'}
        direction={isMobile ? 'column' : 'row'}
        gap={1}
        sx={{ height: '100%' }}
      >
        <Stack flex={1} sx={{ overflowY: 'auto', height: '100%' }}>
          <HolidaySchemeDaysGranted
            scheme={scheme}
            edit={true}
            onUpdateScheme={(s) => {
              form.setValue('scheme', s);
            }}
          />
        </Stack>
        <Stack flex={1}>
          <EditHolidaySchemeProperties form={form} />
        </Stack>
      </Stack>
    </>
  );
};
