import { SVGProps } from 'react';

export function AvatarCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="270"
      height="359"
      viewBox="0 0 270 359"
      {...props}
    >
      <defs>
        <style>
          {
            '\n      .st0 {\n        fill: none;\n      }\n\n      .st1 {\n        fill: #5c5e5e;\n      }\n\n      .st2 {\n        fill: #cecece;\n      }\n\n      .st3 {\n        fill: #fff;\n      }\n\n      .st4 {\n        fill: #f9f9f9;\n      }\n\n      .st5 {\n        fill: url(#Degradado_sin_nombre);\n      }\n\n      .st6 {\n        fill: silver;\n      }\n\n      .st7 {\n        fill: #0aac9d;\n      }\n\n      .st8 {\n        fill: url(#Degradado_sin_nombre_6);\n      }\n\n      .st9 {\n        fill: url(#Degradado_sin_nombre_4);\n      }\n\n      .st10 {\n        fill: url(#Degradado_sin_nombre_3);\n      }\n\n      .st11 {\n        fill: url(#Degradado_sin_nombre_2);\n      }\n\n      .st12 {\n        fill: #989898;\n      }\n    '
          }
        </style>
        <linearGradient
          id="Degradado_sin_nombre"
          data-name="Degradado sin nombre"
          x1={0.5}
          y1={328.7}
          x2={60.5}
          y2={328.7}
          gradientTransform="translate(0 360) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5a46f9" />
          <stop offset={1} stopColor="#2093fd" />
        </linearGradient>
        <linearGradient
          id="Degradado_sin_nombre_2"
          data-name="Degradado sin nombre 2"
          x1={209.6}
          y1={32.3}
          x2={269.6}
          y2={32.3}
          gradientTransform="translate(0 360) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5a46f9" />
          <stop offset={1} stopColor="#2093fd" />
        </linearGradient>
        <linearGradient
          id="Degradado_sin_nombre_3"
          data-name="Degradado sin nombre 3"
          x1={209.6}
          y1={328.7}
          x2={269.6}
          y2={328.7}
          gradientTransform="translate(0 360) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5a46f9" />
          <stop offset={1} stopColor="#2093fd" />
        </linearGradient>
        <linearGradient
          id="Degradado_sin_nombre_4"
          data-name="Degradado sin nombre 4"
          x1={0.6}
          y1={32.4}
          x2={60.6}
          y2={32.4}
          gradientTransform="translate(0 360) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5a46f9" />
          <stop offset={1} stopColor="#2093fd" />
        </linearGradient>
        <linearGradient
          id="Degradado_sin_nombre_6"
          data-name="Degradado sin nombre 6"
          x1={58.7}
          y1={180.6}
          x2={211.3}
          y2={180.6}
          gradientTransform="translate(0 360) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5a46f9" />
          <stop offset={1} stopColor="#2093fd" />
        </linearGradient>
      </defs>
      <path
        className="st4"
        d="M253.3,10.9H14.5c-2.2,0-4.1,1.8-4.1,4.1v328.2c0,2.7,2.2,4.8,4.8,4.8h238c2.9,0,5.3-2.4,5.3-5.3V16.3c0-3-2.4-5.4-5.4-5.4h.2ZM134.6,283.3c-40.8,0-73.8-46.5-73.8-103.9s33.1-103.9,73.8-103.9,73.8,46.5,73.8,103.9-33.1,103.9-73.8,103.9Z"
      />
      <path className="st2" d="M77.9,210.3" />
      <path
        className="st5"
        d="M6.1,62.5c-3.1,0-5.5-2.5-5.5-5.6V15.6C.5,7,7.5,0,16.2,0h38.8c3.1,0,5.5,2.5,5.5,5.6s-2.5,5.6-5.5,5.6H16.2c-2.5,0-4.6,2.1-4.6,4.6v41.3c0,3.1-2.5,5.6-5.5,5.6h0v-.2Z"
      />
      <path
        className="st11"
        d="M253.9,358.9h-38.8c-3.1,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5h38.8c2.5,0,4.6-2.1,4.6-4.6v-41.3c0-3.1,2.5-5.6,5.5-5.6s5.5,2.5,5.5,5.6v41.3c0,8.7-7,15.7-15.7,15.7h0Z"
      />
      <path
        className="st10"
        d="M264.1,62.5c-3.1,0-5.5-2.5-5.5-5.6V15.6c0-2.5-2.1-4.6-4.6-4.6h-38.8c-3.1,0-5.5-2.5-5.5-5.6s2.4-5.4,5.5-5.4h38.8c8.7,0,15.7,7,15.7,15.7v41.3c0,3.1-2.5,5.6-5.5,5.6h-.1Z"
      />
      <path
        className="st9"
        d="M55,358.9H16.3c-8.7,0-15.7-7-15.7-15.7v-41.3c0-3.1,2.5-5.6,5.5-5.6s5.5,2.5,5.5,5.6v41.3c0,2.5,2.1,4.6,4.6,4.6h38.8c3.1,0,5.5,2.5,5.5,5.5s-2.5,5.5-5.5,5.5h0Z"
      />
      <path
        className="st0"
        d="M84.2,234.9l19.8-6.5,9.9-3.3.5-.2.6-.2,2.1-.7,1.6-.5,5.3-1.7v-3c-10.5.3-25.3.5-28.4-.9-4.9-2.2-6.9-8.8-5.2-35.5,1.7-26.7,18.4-52.3,36.3-52.1,18.7.1,20.5,4,21.4,5.6.2.4.5.9.6,1.4h2.8c16.9.8,25.8,18.4,27.5,45.1,1.7,26.7-.3,33.3-5.2,35.5-3.1,1.4-18,1.2-28.4.9v2.9l5.3,1.8,2,.7,1.7.6.6.2.5.2,9.9,3.3,19.8,6.6c2.4.8,4.3,2.3,5.6,4.3v2.9c9.6-17.3,15.3-39,15.3-62.7,0-55.9-32-101.4-71.3-101.4s-71.3,45.5-71.3,101.4,5.6,45.1,15.1,62.3c0-1.5,0-2.4,0-2.5,1.3-2,3.2-3.5,5.6-4.3v-.2Z"
      />
      <path
        className="st6"
        d="M155.7,225.1l-.5-.2c.1.6.4,2.5.7,5.2l-.2-5.1h0Z"
      />
      <path className="st6" d="M114.4,225l-.5.2-.2,5.1c.3-2.7.6-4.6.7-5.3Z" />
      <path
        className="st1"
        d="M154.4,277.1c0,2.2-.2,4.2-.2,5.4,14.5-5.3,27.4-16.3,37.2-31.3-.1-3.3-.3-6.7-.3-9-9.2,16.6-21.9,29-36.6,34.9h-.1Z"
      />
      <path
        className="st1"
        d="M115.4,282.3c0-1.3-.2-3.2-.2-5.4-14.6-6-27.3-18.5-36.4-35.1v9.4c9.7,14.8,22.4,25.8,36.7,31.1h0Z"
      />
      <path
        className="st1"
        d="M114.7,261.8c0-1.9,0-3.2-.2-3.5-2.2-9.3-1.5-21.2-.7-28h0l.2-5.1-9.9,3.3-19.8,6.5c-1.1.3-2,.8-2.9,1.5-1.7,1.2-2.6,3.2-2.6,5.3h0c9.1,16.7,21.8,29.2,36.4,35.2-.2-4.9-.3-11.3-.5-15.1h0Z"
      />
      <path
        className="st1"
        d="M191,242.1c0-2.7-1.5-5.2-3.9-6.5s-1.1-.5-1.7-.7l-19.8-6.6-9.9-3.3.2,5.1c.8,6.9,1.5,18.9-.7,28.2,0,.3-.1,1.6-.2,3.5-.2,3.9-.3,10.3-.5,15.3,14.7-5.9,27.4-18.3,36.6-34.9h-.1Z"
      />
      <path
        className="st2"
        d="M148.4,281.3c-4.4.8-9,1.3-13.6,1.3s-9.2-.4-13.6-1.3c-1.3-.2-.9,1.6-.6,2.7,4.7,1.2,9.5,1.9,14.4,1.9s11.5-.9,16.9-2.6c-1.2-.9-2.3-2.2-3.5-2h0Z"
      />
      <path className="st2" d="M154.2,282.5c-.8.3-1.5.5-2.3.8" />
      <path
        className="st2"
        d="M121.2,281.3c-.4,0-.9-1.1-1.3-2.7-1.6-.5-3.2-1.1-4.7-1.7,0,2.2.2,4.1.2,5.4,1.7.6,3.4,1.2,5.2,1.7-.4-1.2-.7-2.9.6-2.7h0Z"
      />
      <path
        className="st2"
        d="M149.7,278.7c-.4,1.5-.8,2.5-1.3,2.5,1.2-.2,2.3,1.1,3.5,2,.8-.2,1.5-.5,2.3-.8,0-1.3.2-3.2.2-5.4-1.6.6-3.1,1.2-4.7,1.7h0Z"
      />
      <path
        className="st2"
        d="M155.8,230.1c-.3-2.7-.6-4.6-.7-5.2l-.6-.2-1.7-.6-.7-.2-1.1,1.4-3.1,3.9-7.6,9.4h0c-2.8,3.5-8.1,3.5-10.9,0h0l-7.6-9.4-3.1-3.9-1.1-1.4h-.3l-2.1.8-.6.2c0,.7-.4,2.6-.7,5.3h0c-.8,6.9-1.5,18.8.7,28.1,0,.3,0,1.6.2,3.5,0,3.9.3,10.2.5,15.1,1.5.6,3.1,1.2,4.7,1.7,4.9,1.5,9.9,2.3,15.1,2.3s9.9-.7,14.7-2.2c1.6-.5,3.2-1,4.7-1.7.2-5,.3-11.4.5-15.3,0-1.9.1-3.2.2-3.5,2.2-9.3,1.5-21.3.7-28.2h0Z"
      />
      <path
        className="st2"
        d="M119.9,278.6c.4,1.6.9,2.6,1.3,2.7,4.4.8,9,1.3,13.6,1.3s9.2-.4,13.6-1.3c.4,0,.8-1.1,1.3-2.5-4.7,1.4-9.7,2.2-14.7,2.2s-10.3-.8-15.1-2.3h0Z"
      />
      <path
        className="st6"
        d="M161.9,173.3c-1.4-2.3-3.2-4.8-4.7-5.6-1.9-1.1-1.7-7.8-5.4-12.2-1,1.8-2.5,4.2-4.9,6.6-5.1,5.1-14.9,5.5-14.9,5.5,0,0,7-3.3,10.2-8.8.9-1.6,1.6-3.5,2-5.3-1.1,1.8-2.4,3.7-4.3,5.7-7.8,8.3-24,7.2-27.1,8.9-1.4.8-3.1,3.1-4.6,5.3-1.7,2.6-2.9,5.1-2.9,5.1v8.3c0,2.8.4,5.5,1.1,8,2.5,8.9,9.1,16.1,17.7,19.4h0v4.5h0v3l-5.3,1.7-1.6.5h.3l1.1,1.3,3.1,3.9,7.6,9.4h0c2.8,3.5,8.1,3.5,10.9,0h0l7.6-9.4,3.1-3.9,1.1-1.4.7.2-2-.7-5.3-1.8v-7.5c8.5-3.3,15.1-10.5,17.7-19.4.7-2.6,1.1-5.3,1.1-8v-9.2c-.1-.2-1.1-2.1-2.4-4.2h0Z"
      />
      <path className="st12" d="M164.3,177.5h0s.2-1.7.3-4.2h-.4v4.2h0Z" />
      <path className="st12" d="M105.3,173.3h-.4c.2,3,.4,5.1.4,5.1v-5.1Z" />
      <path
        className="st12"
        d="M164.2,186.8c0,2.8-.4,5.3-1.1,7.8-.3.9-.5,1.6-.9,2.5s-1.3,2.9-2,4.1c-3.4,5.8-8.5,10.3-14.8,12.7v4.7c10.5.3,25.6.5,28.6-.9,4.9-2.2,6.9-8.8,5.2-35.5h0c-1.7-26.6-10.6-44.2-27.5-45h-2.8c-.2-.5-.4-1-.6-1.4-.9-1.6-2.7-5.5-21.4-5.6-18-.1-34.6,25.4-36.3,52.1-1.7,26.7.3,33.3,5.2,35.5,3.1,1.4,17.9,1.2,28.4.9h0v-4.5h0c-8.5-3.3-15.1-10.5-17.7-19.4-.7-2.6-1.1-5.3-1.1-8v-8.3s-.2-2-.4-5.1h.4v5.1s1.3-2.5,2.9-5.1c1.4-2.2,3.1-4.5,4.6-5.3,3.1-1.7,19.3-.6,27.1-8.9,1.9-2,3.3-3.9,4.3-5.7-.4,1.8-1.1,3.7-2,5.3-3.2,5.5-10.2,8.8-10.2,8.8,0,0,9.8-.4,14.9-5.5,2.4-2.4,3.9-4.8,4.9-6.6,3.7,4.4,3.5,11.2,5.4,12.2,1.5.8,3.3,3.4,4.7,5.6,1.3,2.1,2.2,3.8,2.3,4"
      />
      <path
        className="st8"
        d="M135,285.8c-42.1,0-76.3-47.7-76.3-106.4s34.2-106.4,76.3-106.4,76.3,47.7,76.3,106.4-34.2,106.4-76.3,106.4ZM135,78c-39.3,0-71.3,45.5-71.3,101.4s32,101.4,71.3,101.4,71.3-45.5,71.3-101.4-32-101.4-71.3-101.4Z"
      />
      <g>
        <path
          className="st3"
          d="M135,296.6c-7.6,0-13.7-6.1-13.7-13.7s6.1-13.7,13.7-13.7,13.7,6.1,13.7,13.7-6.1,13.7-13.7,13.7Z"
        />
        <path
          className="st7"
          d="M135,296.6c-7.6,0-13.7-6.1-13.7-13.7s6.1-13.7,13.7-13.7,13.7,6.1,13.7,13.7-6.1,13.7-13.7,13.7ZM133.6,288.4l9.7-9.7-1.9-1.9-7.8,7.8-3.9-3.9-1.9,1.9,5.8,5.8h0Z"
        />
      </g>
    </svg>
  );
}
