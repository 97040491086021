import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, Stack } from '@mui/material';
import { Dialog } from '../../../newComponents/Dialog';
import { IconButton } from '../../../newComponents';
import { DialogProps } from '../../../newComponents/Dialog/Dialog.types';
import { VideoAgreementRecorderInfo } from './VideoAgreementRecorderInfo';
import { VideoAgreementRecorderInstructions } from './VideoAgreementRecorderInstructions';
import { VideoAgreementRecorderCamera } from './VideoAgreementRecorderCamera';
import { VideoAgreementRecorderResume } from './VideoAgreementRecorderResume';
import { ExitConfirmationDialog } from '../ExitConfirmationDialog';
import { EXIT_CONFIRMATION_TYPES } from '../ExitConfirmationDialog/ExitConfirmationDialog.constants';
import {
  videoAgreementRecorderDialogVar,
  resetVideoAgreementRecorderDialogVar,
} from './VideoAgreementRecorderDialog.vars';

export function VideoAgreementRecorderDialog() {
  const { open, step, onCloseConfirmDialog } = useReactiveVar(
    videoAgreementRecorderDialogVar,
  );
  const [showExitConfirmationDialog, setShowExitConfirmationDialog] =
    useState(false);

  const dynamicProps: Partial<DialogProps> =
    step === 2
      ? { PaperProps: { sx: { backgroundColor: 'black' } } }
      : { maxWidth: 'lg' };

  const handleClose = () => {
    setShowExitConfirmationDialog(true);
  };

  return (
    <Dialog fullWidth open={open} {...dynamicProps} onClose={handleClose}>
      <DialogTitle component="div" sx={{ padding: '0px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ padding: '8px 16px' }}
        >
          <IconButton icon="close_line" onClick={handleClose} />
        </Stack>
      </DialogTitle>
      {step === 0 && <VideoAgreementRecorderInfo />}
      {step === 1 && <VideoAgreementRecorderInstructions />}
      {step === 2 && <VideoAgreementRecorderCamera />}
      {step === 3 && <VideoAgreementRecorderResume />}
      <ExitConfirmationDialog
        open={showExitConfirmationDialog}
        type={EXIT_CONFIRMATION_TYPES.VIDEO_AGREEMENT}
        onClose={() => setShowExitConfirmationDialog(false)}
        onCancel={() => {
          setShowExitConfirmationDialog(false);
          resetVideoAgreementRecorderDialogVar();
          onCloseConfirmDialog();
        }}
        onConfirm={() => setShowExitConfirmationDialog(false)}
      />
    </Dialog>
  );
}
