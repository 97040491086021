import { useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { TemplateCreationSettingsForm } from './TemplateCreationSettingsForm';
import { refetchFunctionsVar } from '../../../../../cache.reactiveVars';
import { useTemplateCreation } from '../TemplateHooks';
import type { SimpleTemplateCreationDialogProps } from './TemplateCreationDialog.types';
import { DEFAULT_TEMPLATE_SETTINGS } from './TemplateCreationDialog.constants';
import { usePermissionChecker } from '../../../../../Hooks';

export const SimpleTemplateCreationDialog = (
  props: SimpleTemplateCreationDialogProps,
) => {
  const theme = useTheme();
  const { showDialog, closeDialog, template } = props;
  const userHasPermissionToUpdate = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });
  const { handleCreateTemplate } = useTemplateCreation();
  const { refetchAllTemplates } = useReactiveVar(refetchFunctionsVar);

  const templateSettingsForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_TEMPLATE_SETTINGS,
  });

  const templateSettingsFormIsValid = templateSettingsForm.formState.isValid;

  const handleTemplateCreation = () => {
    const data = {
      ...templateSettingsForm.getValues(),
      myEditor: template.myEditor,
      authorizers: [],
      authorizeInOrder: false,
    };

    handleCreateTemplate({
      data,
      onSuccess: () => {
        closeDialog();
        refetchAllTemplates && refetchAllTemplates();
      },
    });
  };

  useEffect(() => {
    if (template) {
      templateSettingsForm.reset({
        ...DEFAULT_TEMPLATE_SETTINGS,
        ...template,
      });
    }
  }, [template, templateSettingsForm]);

  return (
    <>
      <Dialog
        slideMode
        fullWidth
        open={showDialog}
        maxWidth="xs"
        onClose={() => closeDialog()}
        showCloseButton={false}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 3,
          }}
          component="div"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon
              fill={theme.customPalette.status.inactive_gray}
              icon="close_line"
              height="26px"
              onClick={() => closeDialog()}
              style={{ cursor: 'pointer' }}
            />
            Guardar como plantilla
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <TemplateCreationSettingsForm form={templateSettingsForm} />
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button
            variant="contained"
            size="large"
            disabled={
              !templateSettingsFormIsValid || !userHasPermissionToUpdate
            }
            onClick={templateSettingsForm.handleSubmit(handleTemplateCreation)}
            tooltipText={
              userHasPermissionToUpdate
                ? ''
                : 'No tienes permisos para realizar esta acción'
            }
            tooltipArrow
            tooltipPlacement="left"
          >
            Guardar plantilla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
