import { Stack, Divider, DialogContent, DialogActions } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { Icon } from '../../../../components/Icons/Icons';
import INEIcon from '../IdentityVerificationDialogAssets/INEIcon.png';
import RenapoIcon from '../IdentityVerificationDialogAssets/RenapoIcon.png';
import { ID_TYPES } from '../IdentityVerificationDialog.constants';
import { useScreenSize } from '../../../../Hooks';
import { ImageList } from './ImageList';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';
import { useEndIdentityVerification } from '../IdentityVerificationDialogHooks/useEndIdentityVerification';

export function VerificationSummary() {
  const { handleEndIdentityVerification } = useEndIdentityVerification();
  const { isMobile } = useScreenSize();
  const { selectedIDType } = useReactiveVar(identityVerificationDialogVar);

  const handleBack = () => {
    setIdentityVerificationDialogVar({ showSummary: false });
  };

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          maxWidth="800px"
          minHeight="65vh"
          alignItems="center"
          justifyContent="center"
          margin="0 auto"
          gap={3}
        >
          <Stack textAlign="center" alignItems="center" gap={isMobile ? 2 : 3}>
            <Typography variant="h3">
              Confirma que todo esté en orden.
            </Typography>
            {selectedIDType === ID_TYPES.INE && (
              <>
                <Typography variant="body1" color="grey" maxWidth="400px">
                  Tu CURP y tus fotografías fueron verificadas ante
                </Typography>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <img src={INEIcon} alt="INE" height={35} />
                    <Icon
                      icon="checkbox_circle_fill"
                      color="success"
                      height="20"
                      width="20"
                    />
                  </Stack>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <img src={RenapoIcon} alt="Renapo" height={25} />
                    <Icon
                      icon="checkbox_circle_fill"
                      color="success"
                      height="20"
                      width="20"
                    />
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
          <ImageList />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Stack
          gap={2}
          width="100%"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={isMobile ? 'center' : 'right'}
        >
          <Button
            variant="outlined"
            color="default"
            size={isMobile ? 'medium' : 'large'}
            onClick={handleBack}
            fullWidth={isMobile}
          >
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            size={isMobile ? 'medium' : 'large'}
            onClick={handleEndIdentityVerification}
          >
            Verificar identidad
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}
