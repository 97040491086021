import React, { Fragment } from 'react';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { verifyIfSelectedTemplateHasChanged } from '../CreateDocumentDialog.helpers';
import {
  SORA_MODULES,
  useBlockableActions,
} from '../../../../../components/ModuleBlockableComponent';
import { createTemplateDialogVar } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars';
import { TemplateCreationDialog } from '../../Templates/TemplateCreationDialog';
import { SaveTemplateButtonProps } from '../CreateDocumentDialog.types';
import type { ModulesAvailable } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';
import {
  PermissionAction,
  PermissionCode,
} from '../../../../../Hooks/usePermissionChecker';

type TooltipTextType = {
  editor: string;
  permission: boolean;
  failedInputs: boolean;
  selectedTemplate: boolean;
  templateHasChanged: boolean;
};

export const tooltipText = (props: TooltipTextType) => {
  const {
    editor,
    permission,
    failedInputs,
    selectedTemplate,
    templateHasChanged,
  } = props;
  if (!permission) {
    return 'No tienes permisos para realizar esta acción';
  }

  if (selectedTemplate && templateHasChanged) {
    return 'La plantilla no ha sido modificada';
  }

  if (failedInputs) {
    return 'Podrás guardar la plantilla una vez que todos los campos estén completos';
  }

  if (!editor) {
    return 'No hay contenido en la plantilla';
  }

  return '';
};

export const SaveTemplateButton = (props: SaveTemplateButtonProps) => {
  const {
    currentTemplate,
    selectedTemplate,
    buttonProps = {},
    skipIsMobileCondition,
    afterOnOpen,
  } = props;
  const { isMobile } = useScreenSize();

  const userHasPermissionToUpdate = usePermissionChecker({
    permission: PermissionCode.templates,
    action: PermissionAction.UPDATE,
  });

  const {
    onClick: documentsModuleIsBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS as ModulesAvailable);

  const handleOpenDialog = () => {
    if (afterOnOpen) {
      afterOnOpen();
    }
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }
    createTemplateDialogVar({ open: true });
  };

  const templateHasChanged = verifyIfSelectedTemplateHasChanged({
    currTemplate: {
      title: currentTemplate?.title,
      myEditor: currentTemplate?.myEditor,
      category: currentTemplate?.categories?.category,
      subcategory: currentTemplate?.categories?.subcategory,
    },
    selectedTemplate: selectedTemplate,
  });

  let disabledSaveTemplate = true;
  let failedInputs = false;

  const categories = currentTemplate.categories;
  const failedCategories = !categories?.category || !categories?.subcategory;
  failedInputs =
    !currentTemplate?.myEditor || !currentTemplate?.title || failedCategories;

  if (selectedTemplate) {
    disabledSaveTemplate = !userHasPermissionToUpdate || templateHasChanged;
    if (failedInputs) {
      disabledSaveTemplate = true;
    }
  } else {
    disabledSaveTemplate = failedInputs || !userHasPermissionToUpdate;
  }

  const htmlContent = currentTemplate?.myEditor;

  const tooltipTextValue = tooltipText({
    editor: htmlContent,
    permission: userHasPermissionToUpdate,
    failedInputs,
    selectedTemplate: Boolean(selectedTemplate),
    templateHasChanged,
  });

  return (
    <Fragment>
      {isMobile && !skipIsMobileCondition ? (
        <IconButton
          icon="save_2_line"
          color="text"
          onClick={handleOpenDialog}
          tooltipArrow
          tooltipText={tooltipTextValue || ''}
          tooltipPlacement="left"
          disabled={disabledSaveTemplate}
          {...buttonProps}
        />
      ) : (
        <Button
          variant="outlined"
          color="default"
          size={isMobile ? 'small' : 'medium'}
          startIcon={<Icon icon="save_2_line" />}
          onClick={handleOpenDialog}
          tooltipArrow
          tooltipText={tooltipTextValue}
          tooltipPlacement="left"
          disabled={disabledSaveTemplate}
          {...buttonProps}
        >
          Guardar como plantilla
        </Button>
      )}

      <TemplateCreationDialog
        template={{
          title: currentTemplate?.title,
          myEditor: currentTemplate?.myEditor,
          category: categories?.category,
          subcategory: categories?.subcategory,
        }}
      />
    </Fragment>
  );
};
