//@ts-check
import { useCallback, useMemo } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';
import { Icon } from '../../../../../../components/Icons/Icons';
import {
  ListItem,
  IconButton,
  Typography,
} from '../../../../../../newComponents';
import { RenderEmployeeRecordType } from '../../../../../RHPod/Documents/Tags/ExpandTags';
import { useScreenSize } from '../../../../../../Hooks';
import {
  openPreviewTemplateDialogVar,
  resetOpenTemplatesListDialogVar,
} from '../Templates.vars';
import { useGetTemplate } from '../../../../../RHPod/Documents/Templates/TemplateHooks';
import { globalSnackbarVar } from '../../../../../../cache.reactiveVars';

const CARD_HEIGHT = 183;

const columnCount = ({ xs, sm, md, lg, xl }) => {
  if (xs || sm) return 1;
  if (md) return 2;
  if (lg) return 3;
  if (xl) return 3;
  // device.
  return 3;
};

export const TemplatesListCards = ({
  templates = { total: 0, templates: [] },
  handleLoadMore,
  loading,
  onOpenTemplate,
}) => {
  const screenSizes = useScreenSize();
  const { handleGetTemplate } = useGetTemplate();

  const COLUMN_COUNT = useMemo(() => {
    return columnCount(screenSizes);
  }, [screenSizes]);

  const ROW_COUNT = Math.ceil(templates.total / COLUMN_COUNT);

  const handlePreviewTemplateDialog = useCallback((event, template) => {
    event.stopPropagation();
    if (!template) return null;
    openPreviewTemplateDialogVar({
      open: true,
      template: template,
    });
  }, []);

  const handleSelectTemplate = async (template, isSelected) => {
    if (!template || !template?._id) {
      return globalSnackbarVar({
        show: true,
        message: 'No se ha seleccionado una plantilla',
        severity: 'error',
        duration: 4000,
      });
    }
    await handleGetTemplate({
      templateId: template._id,
      onSuccess: (info) => {
        if (onOpenTemplate) {
          onOpenTemplate(info);
        }
        resetOpenTemplatesListDialogVar();
      },
    });
  };

  return (
    <Grid container spacing={2} sx={{ height: 500, width: '100%' }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <InfiniteLoader
              isItemLoaded={(index) => index < templates.templates.length}
              itemCount={templates.total}
              threshold={1}
              loadMoreItems={handleLoadMore}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeGrid
                  columnCount={COLUMN_COUNT}
                  columnWidth={width / COLUMN_COUNT}
                  height={height}
                  rowCount={ROW_COUNT}
                  rowHeight={CARD_HEIGHT}
                  width={width + 16}
                  itemData={templates.templates}
                  ref={ref}
                  onItemsRendered={(gridProps) => {
                    onItemsRendered({
                      overscanStartIndex:
                        gridProps.overscanRowStartIndex * COLUMN_COUNT,
                      overscanStopIndex:
                        gridProps.overscanRowStopIndex * COLUMN_COUNT,
                      visibleStartIndex:
                        gridProps.visibleRowStartIndex * COLUMN_COUNT,
                      visibleStopIndex:
                        gridProps.visibleRowStopIndex * COLUMN_COUNT,
                    });
                  }}
                >
                  {({ style, data, rowIndex, columnIndex }) => {
                    const index = rowIndex * COLUMN_COUNT + columnIndex;
                    const template = data[index];
                    if (!template) return null;
                    const category = template?.categories?.category;
                    const subcategory = template?.categories?.subcategory;

                    if (loading) {
                      return (
                        <ListItem style={style} sx={{ padding: 1 }}>
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="100%"
                          />
                        </ListItem>
                      );
                    }

                    return (
                      <div
                        style={{
                          ...style,
                          padding: '8px 16px',
                          boxSizing: 'border-box',
                          cursor: 'pointer',
                        }}
                      >
                        <Card
                          variant="outlined"
                          sx={{ borderRadius: '16px', height: '100%' }}
                          style={{ padding: '16px' }}
                          onClick={() => handleSelectTemplate(template)}
                        >
                          <CardHeader
                            sx={{ padding: '0' }}
                            avatar={
                              <Icon
                                icon="ic_document"
                                width="30px"
                                height="40px"
                              />
                            }
                            action={
                              <IconButton
                                icon="eye_fill"
                                size="small"
                                onClick={(event) =>
                                  handlePreviewTemplateDialog(event, template)
                                }
                              />
                            }
                          />
                          <CardContent sx={{ padding: '20px 0px' }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              mb={2}
                            >
                              <Typography variant="subtitle2">
                                {template?.title}
                              </Typography>
                              <Icon
                                icon="checkbox_circle_line"
                                color="success"
                              />
                            </Stack>
                            <RenderEmployeeRecordType
                              params={{
                                row: {
                                  category: category,
                                  subcategory: subcategory,
                                },
                              }}
                              handleOpen={() => {}}
                              columnWidth={350}
                              hideIconAiTag
                            />
                          </CardContent>
                        </Card>
                      </div>
                    );
                  }}
                </FixedSizeGrid>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </Grid>
  );
};
