import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import {
  Dialog,
  TextInput,
  Typography,
  Button,
  IconButton,
} from '../../../../../newComponents';
import { useTheme } from '@mui/material/styles';
import { DocumentNode, useApolloClient, useMutation } from '@apollo/client';
import {
  CREATE_HOLIDAY,
  GET_COMPANY_HOLIDAYS,
  UPDATE_HOLIDAY,
} from '../Calendar.gql';
import { CalendarEvent } from '../../../../../newComponents/SoraCalendar/SoraCalendar.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
  globalSnackbarVarGenericError,
} from '../../../../../cache.reactiveVars';
import {
  CREATE_OFFICIAL_HOLIDAY,
  GET_OFFICIAL_HOLIDAYS,
  UPDATE_OFFICIAL_HOLIDAY,
} from '../../../../Landing/SupportPanel/OfficialHolidays/OfficialHolidays.gql';
import { Calendar } from '../../../../../newComponents/Datepicker';

type AddHolidayDialogProps = {
  open: boolean;
  onClose: () => void;
  preselectedDate?: Date;
  preselectedEvent?: CalendarEvent;
  isSupportPanel?: boolean;
  onDeleteHoliday: () => void;
};

type Mutations = {
  create: DocumentNode;
  update: DocumentNode;
  get: DocumentNode;
};

const adminMutations: Mutations = {
  create: CREATE_HOLIDAY,
  update: UPDATE_HOLIDAY,
  get: GET_COMPANY_HOLIDAYS,
};

const soraAdminMutations: Mutations = {
  create: CREATE_OFFICIAL_HOLIDAY,
  update: UPDATE_OFFICIAL_HOLIDAY,
  get: GET_OFFICIAL_HOLIDAYS,
};

export const AddHolidayDialog: React.FC<AddHolidayDialogProps> = ({
  open,
  onClose,
  preselectedDate,
  preselectedEvent,
  isSupportPanel = false,
  onDeleteHoliday,
}) => {
  const mutations = isSupportPanel ? soraAdminMutations : adminMutations;
  const theme = useTheme();
  const client = useApolloClient();
  const [createHolidayMutation] = useMutation(mutations.create, {
    onCompleted: () => {
      client.refetchQueries({ include: [mutations.get] });
    },
  });
  const [updateHolidayMutation] = useMutation(mutations.update, {
    onCompleted: () => {
      client.refetchQueries({ include: [mutations.get] });
    },
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    preselectedDate ? preselectedDate : new Date(),
  );
  const [holidayName, setHolidayName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (preselectedDate) {
      setSelectedDate(preselectedDate);
    } else {
      setSelectedDate(null);
    }
  }, [preselectedDate]);

  useEffect(() => {
    if (preselectedEvent) {
      setSelectedDate(preselectedEvent.date);
      setHolidayName(preselectedEvent.title);
    } else {
      setSelectedDate(null);
      setHolidayName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedEvent]);

  const handleAddHoliday = async () => {
    if (!selectedDate || !holidayName) {
      setError('Debes seleccionar una fecha y un nombre');
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Agregar festivo',
      });
      await createHolidayMutation({
        variables: {
          input: {
            title: holidayName,
            date: selectedDate,
            color: isSupportPanel
              ? theme.newPalette.error.main
              : theme.newPalette.primary.main,
          },
        },
      });
      onClose();
      globalSnackbarVar({
        show: true,
        message: 'Festivo agregado',
        severity: 'success',
      });
    } catch (e) {
      console.error(e);
      globalSnackbarVarGenericError();
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  const handleUpdateHoliday = async () => {
    if (!preselectedEvent) {
      setError('No se puede actualizar el festivo seleccionado');
      return onClose();
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando festivo...',
      });
      await updateHolidayMutation({
        variables: {
          input: {
            _id: preselectedEvent._id,
            title: holidayName,
            date: selectedDate,
            color: isSupportPanel
              ? theme.newPalette.error.main
              : theme.newPalette.primary.main,
          },
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Festivo actualizado',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      console.error(e);
      globalSnackbarVarGenericError();
    } finally {
      globalBackdropVar({
        open: false,
      });
    }

    onClose();
  };

  const handleClose = () => {
    // reset values
    setSelectedDate(null);
    setHolidayName('');
    setError(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      slideMode
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.newPalette.divider}`,
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <IconButton onClick={handleClose} icon={'close_line'} />
            <Typography variant="h6">
              {preselectedEvent ? 'Editar' : 'Agregar'} festivo
            </Typography>
          </Stack>
          <Stack>
            {preselectedEvent && (
              <IconButton
                color={'error'}
                onClick={onDeleteHoliday}
                icon={'delete_bin_line'}
              />
            )}
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={2}>
          <TextInput
            label={'Nombre del festivo'}
            value={holidayName}
            onChange={(e) => setHolidayName(e.target.value)}
            fullWidth
            error={holidayName.length > 0 ? !holidayName.length : false}
            inputProps={{
              maxLength: 100,
            }}
          />
          <Calendar
            date={selectedDate}
            setDate={(date) => {
              setSelectedDate(date);
            }}
            textFieldProps={{
              fullWidth: true,
            }}
            label={'Fecha'}
          />

          {error && <Typography color={'error'}>{error}</Typography>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} gap={2} m={2}>
          <Button variant={'outlined'} onClick={handleClose} color={'default'}>
            Cancelar
          </Button>
          {preselectedEvent ? (
            <Button
              variant={'contained'}
              onClick={handleUpdateHoliday}
              color={'primary'}
            >
              Actualizar
            </Button>
          ) : (
            <Button
              variant={'contained'}
              onClick={handleAddHoliday}
              color={'primary'}
            >
              Agregar
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
