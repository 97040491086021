import { Stack } from '@mui/material';
import React from 'react';

interface ScreenHeaderWrapperProps {
  children: React.ReactNode;
}
export const ScreenHeaderWrapper = (props: ScreenHeaderWrapperProps) => {
  const { children } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      gap="8px"
      alignItems="center"
      paddingTop="12px"
    >
      {children}
    </Stack>
  );
};
