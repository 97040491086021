import { useReactiveVar } from '@apollo/client';
import { DialogTitle, Stack } from '@mui/material';
import { Dialog } from '../../../newComponents/Dialog';
import { Avatar } from '../../../newComponents/Avatar';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { SelectIDType } from './SelectIDType';
import { CaptureIDBack } from './CaptureIDBack';
import { CaptureIDFront } from './CaptureIDFront';
import { CaptureLiveness } from './CaptureLiveness';
import { VerificationSummary } from './VerificationSummary';
import { ID_TYPES } from './IdentityVerificationDialog.constants';
import {
  identityVerificationDialogVar,
  resetIdentityVerificationDialog,
} from './IdentityVerificationDialog.vars';

const COMPONENT_MAP = {
  [ID_TYPES.INE]: { 0: CaptureIDFront, 1: CaptureIDBack, 2: CaptureLiveness },
  [ID_TYPES.PASSPORT]: { 0: CaptureIDFront, 1: CaptureLiveness },
  [ID_TYPES.OTHER]: { 0: CaptureIDFront, 1: CaptureLiveness },
};

export function IdentityVerificationDialog() {
  const {
    open,
    stepNumberLabel,
    selectedIDType,
    showSummary,
    INE,
    PASSPORT,
    OTHER,
    onClose,
  } = useReactiveVar(identityVerificationDialogVar);

  const currentStep = { INE, PASSPORT, OTHER }[selectedIDType]?.currentStep;
  const StepComponent = COMPONENT_MAP[selectedIDType]?.[currentStep] || null;

  const handleClose = () => {
    resetIdentityVerificationDialog();
    onClose && onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xl" onClose={handleClose} open={open}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2} alignItems="center">
            {stepNumberLabel && (
              <Avatar color="primary" size="tiny">
                {stepNumberLabel}
              </Avatar>
            )}
            <Typography variant="h6">Verifica tu identidad</Typography>
          </Stack>
          <IconButton icon="close_line" onClick={handleClose} />
        </Stack>
      </DialogTitle>
      {!showSummary && selectedIDType === '' && <SelectIDType />}
      {!showSummary && StepComponent && <StepComponent />}
      {showSummary && <VerificationSummary />}
    </Dialog>
  );
}
