import { styled, Stack, DialogContent } from '@mui/material';
import { IconButton } from '../../../../newComponents/IconButton';

export const OptionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.grey.grey700,
  ':hover': { backgroundColor: theme.newPalette.grey.grey700 },
}));

export const CaptureButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.error.main,
  ':hover': { backgroundColor: theme.newPalette.error.main },
  ':disabled': { backgroundColor: theme.newPalette.error.darker },
}));

const borderWith = '8px';
const doubleBorderWith = '16px';

type CustomDialogContentProps = {
  isMobile: boolean;
  isLoading: boolean;
  isFaceDetected: boolean;
  isVideoRecording: boolean;
  isRecordingRestarted: boolean;
};

export const CustomDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) =>
    prop !== 'isMobile' &&
    prop !== 'isFaceDetected' &&
    prop !== 'isLoading' &&
    prop !== 'isVideoRecording' &&
    prop !== 'isRecordingRestarted',
})<CustomDialogContentProps>(({
  isMobile,
  isFaceDetected,
  isLoading,
  isVideoRecording,
  isRecordingRestarted,
  theme,
}) => {
  let background = 'none';
  if (!isLoading) {
    if (isFaceDetected && isVideoRecording) {
      background = 'rgba(255, 255, 255, 0.5)';
    } else if (isFaceDetected && !isRecordingRestarted) {
      background = theme.newPalette.success.gradient;
    } else {
      background = theme.newPalette.error.gradient;
    }
  }
  return {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: isMobile ? '0px' : '16px',
    position: 'relative',
    '::after': {
      content: '""',
      position: 'absolute',
      padding: borderWith,
      borderRadius: isMobile ? '0px' : '16px',
      width: '100%',
      height: '100%',
      background,
      mask: 'linear-gradient(white 0 0) content-box, linear-gradient(white 0 0)',
      maskComposite: 'exclude',
      '-webkit-mask':
        'linear-gradient(white 0 0) content-box, linear-gradient(white 0 0)',
      '-webkit-mask-composite': 'xor',
    },
  };
});

type CameraVideoWrapperProps = {
  isLoading: boolean;
};

export const CameraVideoWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<CameraVideoWrapperProps>(({ isLoading }) => ({
  display: isLoading ? 'none' : 'flex',
  backgroundColor: '#000000',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  maxHeight: '100%',
}));

type CameraHeaderWrapperProps = {
  showHeaderControls: boolean;
  isMobile: boolean;
};

export const CameraHeaderWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'showHeaderControls' && prop !== 'isMobile',
})<CameraHeaderWrapperProps>(({ showHeaderControls, isMobile }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: showHeaderControls ? 'space-between' : 'center',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  alignItems: 'center',
  right: borderWith,
  top: borderWith,
  left: borderWith,
  flexDirection: 'row',
  width: `calc(100% - ${doubleBorderWith})`,
  height: '4rem',
  borderTopLeftRadius: isMobile ? '0px' : '5px',
  borderTopRightRadius: isMobile ? '0px' : '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(6px)',
  zIndex: 2,
}));

type CameraFooterWrapperProps = {
  isMobile: boolean;
};

export const CameraFooterWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<CameraFooterWrapperProps>(({ isMobile }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-around',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  alignItems: 'center',
  bottom: borderWith,
  right: borderWith,
  left: borderWith,
  boxSizing: 'border-box',
  flexDirection: 'row',
  width: `calc(100% - ${doubleBorderWith})`,
  height: '4rem',
  borderBottomLeftRadius: isMobile ? '0px' : '5px',
  borderBottomRightRadius: isMobile ? '0px' : '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(6px)',
  zIndex: 2,
}));

type VideoProps = {
  flipImage: boolean;
};

export const Video = styled('video', {
  shouldForwardProp: (prop) => prop !== 'flipImage',
})<VideoProps>(({ flipImage }) => ({
  transform: flipImage ? 'scale(-1,1)' : 'scale(1,1)',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

type VideoAgreementTextWrapperProps = {
  isMobile: boolean;
};

export const VideoAgreementTextWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<VideoAgreementTextWrapperProps>(({ isMobile }) => ({
  position: 'absolute',
  backdropFilter: 'blur(6px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '16px',
  padding: '10px',
  bottom: '6rem',
  width: '90%',
  height: isMobile ? 'calc(100% / 4)' : '170px',
  overflowY: 'auto',
  zIndex: 2,
}));

export const ScrollText = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'normal',
  height: '2rem',
  color: 'white',
}));
