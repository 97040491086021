import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '../../../../../../newComponents';
import { Stack } from '@mui/material';
import { CustomTheme } from '../../../../../../theme';
import { CreateHolidaySchemeDialog } from './createHolidaySchemeDialog/CreateHolidaySchemeDialog';

export const DisplayHolidaysSchemeHeader: React.FC = () => {
  const theme: CustomTheme = useTheme();
  const [openAddHolidaySchemeDialog, setOpenAddHolidaySchemeDialog] =
    useState(false);
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack className={'DisplayHolidaysSchemeHeaderTitles'}>
          <Typography variant={'h4'}>Esquemas de vacaciones</Typography>
          <Typography variant={'body1'} color={theme.newPalette.text.secondary}>
            Los esquemas de vacaciones te ayudan a configurar el tipo de
            vacaciones que se asignan a tus empleados.
          </Typography>
        </Stack>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => setOpenAddHolidaySchemeDialog(true)}
        >
          Crear esquema personalizado
        </Button>
      </Stack>
      <CreateHolidaySchemeDialog
        open={openAddHolidaySchemeDialog}
        handleClose={() => {
          setOpenAddHolidaySchemeDialog(false);
        }}
      />
    </>
  );
};
