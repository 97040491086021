import { useReactiveVar } from '@apollo/client';
import { userPermissionsVar } from '../cache.reactiveVars';

// export type PermissionCode = keyof typeof userPermissionsDefault;
export enum PermissionCode {
  // eslint-disable-next-line no-unused-vars
  hra = 'hra',
  // eslint-disable-next-line no-unused-vars
  dash = 'dash',
  // eslint-disable-next-line no-unused-vars
  recs = 'recs',
  // eslint-disable-next-line no-unused-vars
  docs = 'docs',
  // eslint-disable-next-line no-unused-vars
  mply = 'mply',
  // eslint-disable-next-line no-unused-vars
  employeesStatus = 'employeesStatus',
  // eslint-disable-next-line no-unused-vars
  wc = 'wc',
  // eslint-disable-next-line no-unused-vars
  cgr = 'cgr',
  // eslint-disable-next-line no-unused-vars
  lr = 'lr',
  // eslint-disable-next-line no-unused-vars
  templates = 'templates',
  // eslint-disable-next-line no-unused-vars
  workTitles = 'workTitles',
  // eslint-disable-next-line no-unused-vars
  groups = 'groups',
  // eslint-disable-next-line no-unused-vars
  compData = 'compData',
  // eslint-disable-next-line no-unused-vars
  supAdmins = 'supAdmins',
  // eslint-disable-next-line no-unused-vars
  mod = 'mod',
  // eslint-disable-next-line no-unused-vars
  consump = 'consump',
  // eslint-disable-next-line no-unused-vars
  bill = 'bill',
  // eslint-disable-next-line no-unused-vars
  roles = 'roles',
  // eslint-disable-next-line no-unused-vars
  signDocs = 'signDocs',
  // eslint-disable-next-line no-unused-vars
  recordChecklist = 'recordChecklist',
  // eslint-disable-next-line no-unused-vars
  requestDocsToEmployees = 'requestDocsToEmployees',
  // eslint-disable-next-line no-unused-vars
  approveDocsFromEmployees = 'approveDocsFromEmployees',
  // eslint-disable-next-line no-unused-vars
  authorizeTemplates = 'authorizeTemplates',
  // eslint-disable-next-line no-unused-vars
  publishTemplates = 'publishTemplates',
  // eslint-disable-next-line no-unused-vars
  holidays = 'holidays',
  // eslint-disable-next-line no-unused-vars
  videoAgreementAuthorizer = 'videoAgreementAuthorizer',
  // eslint-disable-next-line no-unused-vars
  videoAgreementTemplates = 'videoAgreementTemplates',
}
// eslint-disable-next-line no-unused-vars
export enum PermissionAction {
  // eslint-disable-next-line no-unused-vars
  CREATE = 'create',
  // eslint-disable-next-line no-unused-vars
  UPDATE = 'update',
  // eslint-disable-next-line no-unused-vars
  READ = 'read',
  // eslint-disable-next-line no-unused-vars
  DELETE = 'delete',
}

type Permission = {
  permission: PermissionCode;
  action: PermissionAction;
};
// this type is used for legacy js files, please avoid using it
type PermissionString = {
  permission: string;
  action: string;
};

export function usePermissionChecker(props: Permission[]): boolean[];
export function usePermissionChecker(props: Permission): boolean;
export function usePermissionChecker(props: PermissionString[]): boolean[];
export function usePermissionChecker(props: PermissionString): boolean;
export function usePermissionChecker(
  props: Permission | Permission[] | PermissionString | PermissionString[],
): boolean | boolean[] {
  const userPermissions = useReactiveVar(userPermissionsVar);

  if (Array.isArray(props)) {
    return props.map(({ permission, action }) => {
      const allowed =
        userPermissions &&
        userPermissions[permission] &&
        userPermissions[permission][action];
      return Boolean(allowed);
    });
  }

  const { permission, action } = props;
  const allowed =
    userPermissions &&
    userPermissions[permission] &&
    userPermissions[permission][action];
  return Boolean(allowed);
}
