import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../../newComponents';

export const EditableBlock: React.FC<{
  edit?: boolean;
  value: string;
  onChange: (value: string) => void;
}> = ({ edit = false, onChange, value }) => {
  return (
    <>
      <Stack flex={1} justifyContent={'center'} alignItems={'center'}>
        <Typography textAlign={'center'}>{value}</Typography>
      </Stack>
    </>
  );
};
