import { useState } from 'react';
import { NativeCameraProps } from './NativeCamera.types';
import { convertMiniature } from '../../utils/image';
import { globalBackdropVar } from '../../cache.reactiveVars';
import { setWebcamDialogVar } from '../../businessComponents/Dialogs/WebcamDialog/WebcamDialog.vars';

export function CameraNative(props: NativeCameraProps) {
  const {
    onCapture,
    onPosibleError = () => {},
    useWebcamIfNotSupported = true,
    showWebcamOverlayGuide = true,
    facingMode = 'environment',
    children,
  } = props;

  const [clickCount, setClickCount] = useState(0);

  const captureSupported = () => {
    const elementToCheck = document.createElement('input');
    elementToCheck.setAttribute('capture', facingMode);
    return elementToCheck.capture !== undefined;
  };

  return (
    <>
      <label htmlFor="file-upload-input">
        <div>{children}</div>
      </label>
      <input
        id="file-upload-input"
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        capture={facingMode}
        onClick={(e) => {
          if (!captureSupported()) {
            e.preventDefault();
            if (!useWebcamIfNotSupported) return;
            setWebcamDialogVar({
              open: true,
              facingMode,
              showOverlayGuide: showWebcamOverlayGuide,
              onCapture: (data) => {
                onCapture(data);
              },
            });
          }

          const newCount = clickCount + 1;
          setClickCount(newCount);
          if (newCount >= 3) onPosibleError();
        }}
        onChange={async (e) => {
          const file = e.target.files[0];
          if (!file) return;
          globalBackdropVar({
            open: true,
            clickable: false,
            text: 'Validando imagen...',
          });
          const reader = new FileReader();
          reader.onload = async () => {
            const imageResized = await convertMiniature(
              { dataUrl: reader.result },
              800,
            );
            setClickCount(0);
            onCapture({
              dataUrl: imageResized.dataUrl,
              width: 0,
              height: 0,
            });
          };
          reader.readAsDataURL(file);
        }}
      />
    </>
  );
}
