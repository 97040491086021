import React from 'react';
import { Box } from '@mui/material';

const dotBaseStyles = {
  width: '12px',
  height: '12px',
  borderRadius: '50%',
};

export const Dot: React.FC<{
  color: string;
  size?: string;
}> = ({ color, size }) => {
  return (
    <>
      <Box
        sx={{
          ...dotBaseStyles,
          ...(size && { width: size, height: size }),
          ...(color.startsWith('linear-gradient')
            ? { backgroundImage: color }
            : { backgroundColor: color }),
        }}
      />
    </>
  );
};
