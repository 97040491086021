import { useState } from 'react';
import Webcam from 'react-webcam';
import { Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { CameraPermissionDeniedDialog } from '../../CameraPermissionDeniedDialog';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import { useFaceDetection } from '../VideoAgreementRecorderDialogHooks/useFaceDetection';
import { VideoAgreementRecorderButton } from './VideoAgreementRecorderButton';
import { VideoAgreementRecorderAlerts } from './VideoAgreementRecorderAlerts';
import { useVideoRecorder } from '../VideoAgreementRecorderDialogHooks/useVideoRecorder';
import {
  setVideoAgreementRecorderDialogVar,
  videoAgreementRecorderDialogVar,
} from '../VideoAgreementRecorderDialog.vars';
import {
  Typography,
  CircularProgress,
  TypographyWithRef,
} from '../../../../newComponents';
import {
  CameraHeaderWrapper,
  CameraVideoWrapper,
  CustomDialogContent,
  OptionButton,
  ScrollText,
  VideoAgreementTextWrapper,
} from './VideoAgreementRecorderCamera.styles';
import { convertSecondsToTime } from './VideoAgreementRecorderCamera.helpers';

export function VideoAgreementRecorderCamera() {
  const { isMobile } = useScreenSize();
  const [showPermissionDenied, setShowPermissionDenied] = useState(false);
  const { textToRead, videoDuration } = useReactiveVar(
    videoAgreementRecorderDialogVar,
  );
  const {
    webcamRef,
    videoWidth,
    videoHeight,
    isFaceDetected,
    isLoading,
    setIsVideoReady,
  } = useFaceDetection();
  const {
    isVideoRecording,
    recordingCountDownRef,
    isRecordingRestarted,
    setIsRecordingRestarted,
    handleStartVideoRecorder,
    handleStopVideoRecorder,
  } = useVideoRecorder({
    webcamRef,
    isFaceDetected,
  });

  const handleClose = () => {
    setVideoAgreementRecorderDialogVar({
      step: 1,
      videoRecorded: null,
    });
  };

  return (
    <CustomDialogContent
      isLoading={isLoading}
      isMobile={isMobile}
      isFaceDetected={isFaceDetected}
      isVideoRecording={isVideoRecording}
      isRecordingRestarted={isRecordingRestarted}
    >
      <CameraPermissionDeniedDialog
        open={showPermissionDenied}
        onClose={() => {
          setShowPermissionDenied(false);
        }}
      />
      {!isLoading && (
        <>
          <VideoAgreementRecorderAlerts
            isFaceDetected={isFaceDetected}
            isVideoRecording={isVideoRecording}
            isRecordingRestarted={isRecordingRestarted}
            setIsRecordingRestarted={setIsRecordingRestarted}
          />
          <CameraHeaderWrapper
            showHeaderControls={!isVideoRecording}
            isMobile={isMobile}
          >
            {!isVideoRecording && (
              <OptionButton
                icon="close_line"
                color="white"
                size="small"
                onClick={handleClose}
              />
            )}
            <Stack direction="row" gap={1} alignItems="center">
              {isVideoRecording && (
                <Icon icon="record_circle_line" color="error" />
              )}
              <TypographyWithRef
                variant="h3"
                customColor
                color="white"
                ref={recordingCountDownRef}
              >
                {convertSecondsToTime(videoDuration)}
              </TypographyWithRef>
            </Stack>
            {!isVideoRecording && <Stack width="40px"></Stack>}
          </CameraHeaderWrapper>
        </>
      )}
      {isLoading && (
        <Stack
          gap={2}
          justifyContent="center"
          alignItems="center"
          height="70vh"
        >
          <CircularProgress size={80} />
          <Typography variant="body1" customColor color="white">
            Iniciando cámara...
          </Typography>
        </Stack>
      )}
      <CameraVideoWrapper isLoading={isLoading}>
        <Webcam
          ref={webcamRef}
          audio
          mirrored
          autoPlay
          playsInline
          videoConstraints={{
            width: videoWidth,
            height: videoHeight,
            aspectRatio: 1.777777778,
            facingMode: 'user',
          }}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          onUserMedia={() => setIsVideoReady(true)}
          onUserMediaError={(err: any) => {
            if (err.message === 'Permission denied' || err.code === 0) {
              setShowPermissionDenied(true);
            }
          }}
        />
      </CameraVideoWrapper>

      {!isLoading && (
        <>
          <VideoAgreementTextWrapper isMobile={isMobile}>
            <ScrollText>
              <Icon
                icon="arrow_down_s_line"
                color="white"
                width="20px"
                height="20px"
              />
            </ScrollText>
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              textAlign="center"
              customColor
              color="white"
            >
              {textToRead}
            </Typography>
          </VideoAgreementTextWrapper>
          <VideoAgreementRecorderButton
            isFaceDetected={isFaceDetected}
            isVideoRecording={isVideoRecording}
            isRecordingRestarted={isRecordingRestarted}
            onStartRecording={handleStartVideoRecorder}
            onStopRecording={handleStopVideoRecorder}
          />
        </>
      )}
    </CustomDialogContent>
  );
}
