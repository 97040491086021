import { Stack } from '@mui/material';
import React from 'react';

interface ScreenHeaderMobileButtonsWrapperProps {
  children: React.ReactNode;
}

export const ScreenHeaderMobileButtonsWrapper = (
  props: ScreenHeaderMobileButtonsWrapperProps,
) => {
  const { children } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      flexWrap="wrap"
      justifyContent="flex-end"
      flexGrow={1}
    >
      {children}
    </Stack>
  );
};
