// @ts-check
import { createTheme, Theme } from '@mui/material';
import { buttonStyles } from './buttonStyles';
import { buttonGroupStyles } from './buttonGroupStyles';
import { fabButtonStyles } from './fabButtonStyles';
import { iconButtonStyles } from './iconButtonStyles';
import { toggleButtonStyles } from './toggleButtonStyles';
import { toggleButtonGroupStyles } from './toggleButtonGroupStyles';
import { backdropStyles } from './backdropStyles';
import { circularProgressStyles } from './circularProgressStyles';
import { typographyStyles } from './typographyStyles';
import { ColorPalette } from '../components/Colors/Colors';
import { onLightcolors, onDarkcolors } from './colorPalette';
import { tabStyles } from './tabStyles';
import { tabsStyles } from './tabsStyles';
import { breadcrumbsStyles } from './breadcrumbsStyles';
import { listItemIconStyles } from './listItemIconStyles';
import { listItemTextStyles } from './listItemTextStyles';
import { menuItemStyles } from './menuItemStyles';
import { menuStyles } from './menuStyles';
import { chipStyles } from './chipStyles';
import { alertStyles } from './alertStyles';
import { alertTitleStyles } from './alertTitleStyles';
import { linearProgressStyles } from './linearProgressStyles';
import { dialogStyles } from './dialogStyles';
import { paginationStyles } from './paginationStyles';
import { stepConnectorStyles } from './stepConnectorStyles';
import { stepperStyles } from './stepperStyles';
import { snackbarStyles } from './snackbarStyles';
import { tooltipStyles } from './tooltipStyles';
import { badgeStyles } from './badgeStyles';
import { avatarStyles } from './avatarStyles';
import { avatarGroupStyles } from './avatarGroupStyles';
import { popoverTitleStyles } from './popoverStyles';
import { timelineDotStyles } from './timelineDotStyles';
import { timelineConnectorStyles } from './timelineConnector';
import { listItemButtonStyles } from './listItemButtonStyles';
import { listStyles } from './listStyles';
import { datagridStyles } from './datagridStyles';
import { textFieldStyles } from './textFieldStyles';
import { switchStyles } from './switchStyles';
import { radioButtonStyles } from './radioButtonStyles';
import { checkboxStyles } from './checkboxStyles';
import { sliderStyles } from './sliderStyles';
import { accordionStyles, accordionSummaryStyles } from './accordionStyles';
import { formControlLabelStyles } from './formControlLabelStyles';
import { selectStyles } from './selectStyles';
import { inputLabelStyles } from './inputLabelStyles';
import { esES } from '@mui/x-date-pickers/locales';

export type newPaletteType = typeof onLightcolors | typeof onDarkcolors;

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: typeof ColorPalette;
    newPalette: newPaletteType;
  }
}

export type CustomTheme = Theme & {
  customPalette: typeof ColorPalette;
  newPalette: typeof onLightcolors | typeof onDarkcolors;
};

export const getDesignTokens = (mode: 'light' | 'dark') => {
  const palette = mode === 'light' ? onLightcolors : onDarkcolors;

  // map custom shadows to the default shadows
  const shadows = createTheme({}).shadows;
  shadows[1] = palette.shadow.z1;
  shadows[8] = palette.shadow.z8;
  shadows[12] = palette.shadow.z12;
  shadows[16] = palette.shadow.z16;
  shadows[20] = palette.shadow.z20;
  shadows[24] = palette.shadow.z24;

  return {
    customPalette: { ...ColorPalette },
    newPalette: palette,
    typography: {
      fontFamily: 'Public Sans',
    },
    palette: {
      mode,
      // @ts-ignore
      default: { main: '#DFE3E8' },
      text: {
        primary: palette.text.primary,
        secondary: palette.text.secondary,
        disabled: palette.text.disabled,
      },
      primary: {
        main: palette.primary.main,
        dark: palette.primary.dark,
        light: palette.primary.light,
      },
      secondary: {
        main: palette.secondary.main,
        dark: palette.secondary.dark,
        light: palette.secondary.light,
      },
      error: {
        main: palette.error.main,
        dark: palette.error.dark,
        light: palette.error.light,
      },
      success: {
        main: palette.success.main,
        dark: palette.success.dark,
        light: palette.success.light,
        contrastText: palette.common.white,
      },
      grey: {
        50: palette.grey.grey50,
        100: palette.grey.grey100,
        200: palette.grey.grey200,
        300: palette.grey.grey300,
        400: palette.grey.grey400,
        500: palette.grey.grey500,
        600: palette.grey.grey600,
        700: palette.grey.grey700,
        800: palette.grey.grey800,
        900: palette.grey.grey900,
      },
      background: {
        default: palette.background.default,
        paper: palette.background.paper,
      },
      action: {
        active: palette.actionStates.active,
        hover: palette.actionStates.hover,
        selected: palette.actionStates.selected,
        disabled: palette.actionStates.disabled,
        disabledBackground: palette.actionStates.disabledBackground,
        focus: palette.actionStates.focus,
      },
      divider: palette.divider,
    },
    shadows,
    components: {
      MuiDialog: dialogStyles,
      MuiFab: fabButtonStyles,
      MuiButton: buttonStyles,
      MuiButtonGroup: buttonGroupStyles,
      MuiIconButton: iconButtonStyles,
      MuiToggleButton: toggleButtonStyles,
      MuiToggleButtonGroup: toggleButtonGroupStyles,
      MuiBackdrop: backdropStyles,
      MuiCircularProgress: circularProgressStyles,
      MuiTab: tabStyles,
      MuiTabs: tabsStyles,
      MuiBreadcrumbs: breadcrumbsStyles,
      MuiMenu: menuStyles,
      MuiMenuItem: menuItemStyles,
      MuiListItemText: listItemTextStyles,
      MuiListItemIcon: listItemIconStyles,
      MuiAlert: alertStyles,
      MuiAlertTitle: alertTitleStyles,
      MuiLinearProgress: linearProgressStyles,
      MuiTypography: typographyStyles,
      MuiChip: chipStyles,
      MuiPagination: paginationStyles,
      MuiStepConnector: stepConnectorStyles,
      MuiStepper: stepperStyles,
      MuiSnackbar: snackbarStyles,
      MuiTooltip: tooltipStyles,
      MuiBadge: badgeStyles,
      MuiAvatar: avatarStyles,
      MuiAvatarGroup: avatarGroupStyles,
      MuiPopover: popoverTitleStyles,
      MuiTimelineDot: timelineDotStyles,
      MuiTimelineConnector: timelineConnectorStyles,
      MuiListItemButton: listItemButtonStyles,
      MuiList: listStyles,
      MuiDataGrid: datagridStyles,
      MuiTextField: textFieldStyles,
      MuiSwitch: switchStyles,
      MuiRadio: radioButtonStyles,
      MuiCheckbox: checkboxStyles,
      MuiSlider: sliderStyles,
      MuiAccordion: accordionStyles,
      MuiAccordionSummary: accordionSummaryStyles,
      MuiFormControlLabel: formControlLabelStyles,
      MuiSelect: selectStyles,
      MuiInputLabel: inputLabelStyles,
    },
    esES,
  };
};
