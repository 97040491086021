import { makeVar } from '@apollo/client';

type LivenessDialogVar = {
  open: boolean;
  sessionId: string;
  verificationId: string;
  onSuccess: (imageUrl: string) => void;
  onFail: (errorMessage: string) => void;
};

const livenessDialogDefault: LivenessDialogVar = {
  open: false,
  sessionId: '',
  verificationId: '',
  onSuccess: () => {},
  onFail: () => {},
};

export const livenessDialogVar = makeVar(livenessDialogDefault);

export const resetLivenessDialogVar = () => {
  livenessDialogVar(livenessDialogDefault);
};

export const setLivenessDialogVar = (input: Partial<LivenessDialogVar>) => {
  livenessDialogVar({
    ...livenessDialogVar(),
    ...input,
  });
};
