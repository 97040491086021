import { Stack } from '@mui/material';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

/**
 * @typedef {import('react-hook-form').UseFormReturn<any>} Form
 * @typedef {import('react').MutableRefObject} MutableRefObject
 */
/**
 * @param {object} props
 * @param {Form} props.form
 * @param {(...event: any[]) => void} props.onChange
 * @param {any} props.value
 * @param {MutableRefObject} props.editorRef
 * @param {boolean} [props.disabled]
 */
export const Editor = ({ form, onChange, value, editorRef, disabled }) => {
  const values = form.getValues();

  const handleEditorChange = (editor) => {
    onChange(editor);
  };

  const uploadImage = (cb, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const id = 'blobid' + new Date().getTime();
        const blobCache = editorRef.current.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  return (
    <Stack minHeight="180px">
      <TinyEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        def
        onEditorChange={handleEditorChange}
        initialValue=""
        apiKey="cd2vy8em07evm4qhvgq9v3gm241cv8kll6jn2q7ut712wd87"
        disabled={disabled}
        init={{
          auto_focus: true,
          language: 'es',
          selector: 'textarea#file-picker',
          min_height: 620,
          elementpath: false,
          plugins: [
            'lists',
            'image',
            'powerpaste',
            'formatpainter',
            'table advtable',
            'table',
            'casechange',
          ],
          menubar: 'file edit view insert format tools table tc',
          toolbar:
            'bold italic underline strikethrough forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify image | bullist numlist outdent indent | ' +
            'insertfile undo redo a11ycheck styleselect | formatselect fontsizeselect | ' +
            'removeformat | link | formatpainter | casechange',
          fullscreen_native: true,
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: uploadImage,
          content_style: `body { font-family:Arial,Helvetica,sans-serif; font-size:14px } .variable {
              font-weight: bold;
            }`,
          setup: (editor) => {
            editor.on('init', function (e) {
              if (values?.myEditor) {
                editor.setContent(`${values.myEditor}`);
              }
            });
          },
          // Configuración mejorada para el manejo de pegado
          paste_as_text: false,
          paste_auto_cleanup_on_paste: false,
          paste_remove_styles: false,
          paste_remove_styles_if_webkit: false,
          paste_strip_class_attributes: 'none',
          paste_retain_style_properties: 'all',
          paste_merge_formats: true,
        }}
      />
    </Stack>
  );
};
