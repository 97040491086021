import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  HEXADECIMAL_COLOR_PALETTE,
  Typography,
} from '../../../../../../../newComponents';
import { DialogTitle, IconButton, Stack, useTheme } from '@mui/material';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { CHSForm } from './CHSForm';
import {
  CHSFormType,
  CreateHolidaySchemeDialogProps,
  CreateHolidaySchemeSteps,
} from './CreateHolidaySchemeDialog.types';
import { defaultHolidayScheme } from '../HolidaySchemeDaysGranted/DefaulScheme.template';

export const CreateHolidaySchemeDialog: React.FC<
  CreateHolidaySchemeDialogProps
> = ({ open, handleClose }) => {
  const theme = useTheme();
  const form = useForm<CHSFormType>({
    defaultValues: {
      title: '',
      description: '',
      scheme: defaultHolidayScheme,
      color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
      approveTemplateId: null,
      holidayTakenTemplateId: null,
    },
    mode: 'onBlur',
    shouldFocusError: true,
  });

  const [step, setStep] = useState<CreateHolidaySchemeSteps>(
    CreateHolidaySchemeSteps.Title,
  );

  const onClose = () => {
    form.reset();
    setStep(CreateHolidaySchemeSteps.Title);
    handleClose();
  };

  const handlePrev = () => {
    if (step === CreateHolidaySchemeSteps.DaysGranted) {
      setStep(CreateHolidaySchemeSteps.Title);
    } else if (step === CreateHolidaySchemeSteps.Employees) {
      setStep(CreateHolidaySchemeSteps.DaysGranted);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        slideMode
        fullWidth
        maxWidth={{ [CreateHolidaySchemeSteps.Title]: 'sm' }[step] ?? 'lg'}
        sx={{ height: '100%' }}
        PaperProps={{ sx: { height: '100%' } }}
      >
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.newPalette.divider}`,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <IconButton onClick={onClose}>
              <Icon
                fill={theme.customPalette.textColor.text_black}
                icon="close_line"
                height="20px"
              />
            </IconButton>
            <Typography variant="h6">Crear esquema de vacaciones</Typography>
          </Stack>
        </DialogTitle>
        <CHSForm
          form={form}
          step={step}
          setStep={setStep}
          handleClose={onClose}
          handlePrev={handlePrev}
        />
      </Dialog>
    </>
  );
};
