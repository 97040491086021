export const getMonthNameFromMonthIndex = (monthIndex: number): string => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  return months[monthIndex];
};

export const getDayNameFromDayIndex = (
  dayIndex: number,
  short: boolean = false,
): string => {
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  if (short) {
    //return only the first letter
    if (dayIndex === 3) {
      return 'X';
    }
    return days[dayIndex].charAt(0);
  }
  return days[dayIndex];
};
