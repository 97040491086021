import { Stack } from '@mui/material';
import React from 'react';

interface TableHeaderWrapperProps {
  children: React.ReactNode;
}
export const TableHeaderWrapper = (props: TableHeaderWrapperProps) => {
  const { children } = props;
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      padding={2}
      gap={1}
      flexGrow={1}
      flexWrap="wrap"
    >
      {children}
    </Stack>
  );
};
