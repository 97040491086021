import React from 'react';
import { Stack, TextField } from '@mui/material';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { getMonthNameFromMonthIndex } from '../../utils/date.utils';
import { Button } from '../Button';
import { CalendarView } from './SoraCalendar.types';

type SoraCalendarMonthNavigationProps = {
  onPrevClick: () => void;
  onNextClick: () => void;
  onTodayClick: () => void;
  currentDate: Date;
  onChangeView: (view: CalendarView) => void;
  currentView: CalendarView;
};
export const SoraCalendarMonthNavigation: React.FC<
  SoraCalendarMonthNavigationProps
> = ({
  currentDate,
  onNextClick,
  onPrevClick,
  onTodayClick,
  onChangeView,
  currentView,
}) => {
  const month = getMonthNameFromMonthIndex(currentDate.getMonth());
  return (
    <>
      <Stack p={2} mb={2} direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} alignItems={'center'}>
          <Button
            variant="outlined"
            color={'default'}
            sx={{
              borderRadius: '1em',
              height: '2em',
              minHeight: '2em',
              p: '.2em 0',
              fontWeight: 'normal',
            }}
            onClick={onTodayClick}
          >
            Hoy
          </Button>
          <IconButton onClick={onPrevClick} icon={'arrow_left_s_line'} />
          <IconButton onClick={onNextClick} icon={'arrow_right_s_line'} />
          <Typography variant={'h6'}>
            {month} de {currentDate.getFullYear()}
          </Typography>
        </Stack>
        {/*dropdown with two options*/}
        <TextField
          select
          variant="outlined"
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{ width: '100px' }}
          value={currentView}
          onChange={(e) => onChangeView(e.target.value as CalendarView)}
        >
          <option value={CalendarView.MONTH}>Mes</option>
          <option value={CalendarView.YEAR}>Año</option>
        </TextField>
      </Stack>
    </>
  );
};
