// @ts-check
import React, { useEffect, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { Calendar } from '../../../newComponents/Datepicker';

/** @param {import('./ControlledEmploymentStartDateInput.types').ControlledEmploymentStartDateInputProps} props */
export const ControlledEmploymentStartDateInput = ({
  control,
  rules,
  textFieldProps = {},
  onJobSeniorityChange = () => {},
}) => {
  const name = 'employmentStartDate';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  const convertUTCDateToLocalDate = (date) => {
    const utcDate = new Date(date);
    const localDate = new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
    );
    return localDate;
  };

  const calculateJobSeniority = useCallback(
    /**  @param {Date | null} employmentStartDate */
    (employmentStartDate) => {
      if (!employmentStartDate) return onJobSeniorityChange('');

      const today = new Date();
      if (today < employmentStartDate) return onJobSeniorityChange('');

      let years = today.getFullYear() - employmentStartDate.getFullYear();
      let months = today.getMonth() - employmentStartDate.getMonth();
      let days = today.getDate() - employmentStartDate.getDate();

      if (days < 0) {
        months--;
        days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      }
      if (months < 0) {
        years--;
        months += 12;
      }

      let jobSeniority = '';
      if (years > 0) {
        jobSeniority += years + (years > 1 ? ' años ' : ' año ');
      }
      if (months > 0) {
        jobSeniority += months + (months > 1 ? ' meses ' : ' mes ');
      }
      if (days > 0) {
        jobSeniority += days + (days > 1 ? ' días' : ' día');
      }

      onJobSeniorityChange(jobSeniority);
    },
    [onJobSeniorityChange],
  );

  useEffect(() => {
    calculateJobSeniority(value);
  }, [value, calculateJobSeniority]);

  return (
    <Calendar
      date={value ? convertUTCDateToLocalDate(value) : null}
      setDate={(date) => {
        onChange(date);
        calculateJobSeniority(date);
      }}
      formatDate="dd/MM/yyyy"
      error={hasError}
      helperText={helperText}
      showIconToRemove={Boolean(value)}
      textFieldProps={{
        id: textFieldProps.id || name,
        onBlur,
        ...textFieldProps,
      }}
    />
  );
};
