// @ts-check
import React from 'react';
import { ButtonBase, Alert as MuiAlert, Stack } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Tooltip } from '../Tooltip';

const iconMapping = {
  info: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_info" />,
  success: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_check" />,
  warning: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_alert" />,
  error: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_info" />,
};

/**
 * @type {React.FC<import('./Alert.types').AlertProps>}
 */
export const Alert = ({
  onAction,
  onDismiss,
  actionLabel,
  onActionDisabled,
  onActionTooltip,
  dismissLabel,
  onDismissTooltip,
  onDismissDisabled,
  variant = 'standard',
  severity = 'info',
  ...props
}) => {
  return (
    <MuiAlert
      variant={variant}
      severity={severity}
      iconMapping={{ ...iconMapping }}
      action={
        (onAction || onDismiss) && (
          <Stack flexDirection="row" gap={1}>
            {onAction && (
              <Tooltip title={onActionTooltip}>
                <span>
                  <ButtonBase
                    className="Action-button"
                    onClick={onAction}
                    disabled={onActionDisabled}
                  >
                    {actionLabel}
                  </ButtonBase>
                </span>
              </Tooltip>
            )}
            {onDismiss && (
              <Tooltip title={onDismissTooltip}>
                <span>
                  <ButtonBase
                    className="Action-button-dismiss"
                    onClick={onDismiss}
                    disabled={onDismissDisabled}
                  >
                    {dismissLabel}
                  </ButtonBase>
                </span>
              </Tooltip>
            )}
          </Stack>
        )
      }
      {...props}
    />
  );
};
