import React from 'react';

type ObjectId = string;
export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export interface UserReference {
  _id: ObjectId;
  names: string;
  lastNameP: string;
  lastNameM: string;
  rfc: string;
}

export type DaysEarnedByYearScheme = {
  year: number;
  days: number;
};

export type DaysEarnedByMonth = {
  month: Month;
  days: number;
};

export type DaysEarnedByYearPerMonthScheme = {
  year: number;
  days: DaysEarnedByMonth[];
};

export type DaysEarnedByYear = {
  year: number;
  days: number | DaysEarnedByMonth[];
};

export type DaysEarnedScheme =
  | DaysEarnedByYearScheme
  | DaysEarnedByYear
  | DaysEarnedByYearPerMonthScheme;

export type GraphQlDaysEarnedScheme = {
  year: number;
  days?: number;
  daysPerMonth?: DaysEarnedByMonth[];
};

// eslint-disable-next-line no-unused-vars
export enum HolidaySchemeTypes {
  // eslint-disable-next-line no-unused-vars
  LAW_HOLIDAYS = 'LAW_HOLIDAYS',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_HOLIDAYS = 'CUSTOM_HOLIDAYS',
}

export interface HolidayScheme {
  _id: string;
  companyId: ObjectId;
  title: string;
  description?: string;
  color?: React.CSSProperties['color'];
  approveTemplateId?: ObjectId;
  holidayTakenTemplateId?: ObjectId;
  scheme: DaysEarnedScheme[];
  createdBy: UserReference;
  employeesCount: number;
  type: HolidaySchemeTypes;
}
