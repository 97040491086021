import { Box, Popover, Stack, DialogContent } from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Icon } from '../../../../components/Icons/Icons';
import { MobileSlideUpDialog } from '../../../../newComponents/Dialog';
import { useScreenSize } from '../../../../Hooks';
import {
  ActionBarPopMenuProps,
  MenuItemsProps,
} from './ActionBarPopMenu.types';

const MenuItems = (props: MenuItemsProps) => {
  const { menuItems } = props;
  return (
    <Stack direction="column" gap="16px" width="100%" flexGrow={1}>
      {menuItems.map((item, index) => {
        const key = `menu-item-${index}`;
        const {
          label = 'Menu Item',
          icon = 'code_line',
          iconColor = 'primary',
          onClick = () => {},
          noIcon = false,
          ...restProps
        } = item;
        return (
          <Button
            key={key}
            variant="outlined"
            color="default"
            startIcon={
              !noIcon && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr="8px"
                >
                  <Icon icon={icon} color={iconColor} height={'24px'} />
                </Box>
              )
            }
            size="large"
            onClick={onClick}
            sx={{
              height: '84px !important',
              justifyContent: 'flex-start',
            }}
            {...restProps}
          >
            {label}
          </Button>
        );
      })}
    </Stack>
  );
};

export const ActionBarPopMenu = (props: ActionBarPopMenuProps) => {
  const { popoverOpen, anchorEl, handlePopoverClose, menuItems } = props;
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return (
      <MobileSlideUpDialog open={popoverOpen} onClose={handlePopoverClose}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            padding: '24px',
          }}
        >
          <MenuItems menuItems={menuItems} />
        </DialogContent>
      </MobileSlideUpDialog>
    );
  }

  return (
    <Popover
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '.MuiPopover-paper': {
          padding: '24px',
          maxWidth: isMobile ? '250px' : '400px',
          width: '100%',
        },
      }}
    >
      <MenuItems menuItems={menuItems} />
    </Popover>
  );
};
