import React from 'react';
import { Stack, TextField } from '@mui/material';
import { ConfirmDialog, Typography } from '../../newComponents';

const confirmationMessage = (documentType, multipleFiles) => {
  const typeString = documentType === 'receipts' ? 'recibos' : 'documentos';
  const singularTypeString =
    documentType === 'receipts' ? 'recibo' : 'documento';
  if (multipleFiles) {
    return `¿Estás seguro que quieres eliminar los ${typeString}?`;
  }
  return `¿Estás seguro que quieres eliminar el ${singularTypeString}?`;
};

const title = (documentType, multipleFiles) => {
  let typeString;
  if (documentType === 'documents') {
    typeString = multipleFiles ? 'documentos' : 'documento';
  } else {
    typeString = multipleFiles ? 'recibos' : 'recibo';
  }
  return `Eliminar ${typeString}`;
};

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {string} [ props.confirmLabel ]
 * @param {string} [ props.cancelLabel ]
 * @param {'receipts'|'documents'} props.documentType
 * @param {boolean} props.multipleFiles
 * @param {Function} props.handleCommentChange
 * @param {Function} props.onConfirm
 * @param {boolean} [ props.hideFooterButtons ]
 * @param {React.JSX.Element} props.children
 * @returns {React.JSX.Element}
 */

/**
 * @type {React.FC<import('@mui/material').DialogProps & {
 *   confirmLabel?: string,
 *   cancelLabel?: string,
 *   documentType: 'receipts'|'documents',
 *   multipleFiles: boolean,
 *   onClose: () => void,
 *   onConfirm: () => void,
 *   handleCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
 *   hideFooterButtons?: boolean,
 *   children?: React.ReactNode
 * }>}
 */
export const DeleteDocumentsDialog = ({
  open,
  confirmLabel = 'Eliminar',
  cancelLabel = 'Cancelar',
  documentType,
  multipleFiles,
  onClose,
  onConfirm,
  handleCommentChange,
  hideFooterButtons = false,
  children,
}) => {
  return (
    <>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        icon="delete_bin_line"
        confirmLabel={!hideFooterButtons ? confirmLabel : undefined}
        cancelLabel={!hideFooterButtons ? cancelLabel : undefined}
        title={title(documentType, multipleFiles)}
        severity="error"
        onCancel={onClose}
        onConfirm={onConfirm}
      >
        {children ? (
          children
        ) : (
          <Stack spacing={2}>
            <Typography variant="body1">
              {confirmationMessage(documentType, multipleFiles)}
            </Typography>
            <TextField
              id="delete-document-reason-textField"
              placeholder="Escribe un motivo"
              multiline
              rows={2}
              fullWidth
              onChange={handleCommentChange}
              inputProps={{
                maxLength: 500,
              }}
            />
          </Stack>
        )}
      </ConfirmDialog>
    </>
  );
};
