import { gql } from '@apollo/client';

export const GET_COMPANY_DEFAULT_ROLES = gql`
  query getCompanyDefaultRoles {
    getCompanyDefaultRoles {
      superAdmin {
        permissions {
          companyPermissions {
            companyData {
              code
              active
              read
              create
              update
              delete
            }
            superAdmins {
              code
              active
              read
              create
              update
              delete
            }
            modules {
              code
              active
              read
              create
              update
              delete
            }
            consumption {
              code
              active
              read
              create
              update
              delete
            }
            billing {
              code
              active
              read
              create
              update
              delete
            }
            rolesAndPermissions {
              code
              active
              read
              create
              update
              delete
            }
            legalRepresentative {
              code
              active
              read
              create
              update
              delete
            }
            signDocuments {
              code
              read
              active
              create
              update
              delete
            }
          }
          rhPodPermissions {
            hrAdmins {
              code
              active
              read
              create
              update
              delete
            }
            dashboard {
              code
              active
              read
              create
              update
              delete
            }
            receipts {
              code
              active
              read
              create
              update
              delete
            }
            documents {
              code
              active
              read
              create
              update
              delete
            }
            employees {
              code
              active
              read
              create
              update
              delete
            }
            employeesStatus {
              code
              active
              read
              create
              update
              delete
            }
            workCenters {
              code
              active
              read
              create
              update
              delete
            }
            companyGroupReports {
              code
              active
              read
              create
              update
              delete
            }
            legalRepresentative {
              code
              active
              read
              create
              update
              delete
            }
            templates {
              code
              active
              read
              create
              update
              delete
            }
            workTitles {
              code
              active
              read
              create
              update
              delete
            }
            groups {
              code
              active
              read
              create
              update
              delete
            }
            recordChecklist {
              code
              active
              read
              create
              update
              delete
            }
            requestDocsToEmployees {
              code
              active
              read
              create
              update
              delete
            }
            approveDocsFromEmployees {
              code
              active
              read
              create
              update
              delete
            }
            authorizeTemplates {
              code
              active
              read
              create
              update
              delete
            }
            publishTemplates {
              code
              active
              read
              create
              update
              delete
            }
            holidays {
              code
              active
              read
              create
              update
              delete
            }
            videoAgreementAuthorizer {
              code
              active
              read
              create
              update
              delete
            }
            videoAgreementTemplates {
              code
              active
              read
              create
              update
              delete
            }
          }
        }
      }
      adminRh {
        permissions {
          rhPodPermissions {
            hrAdmins {
              code
              active
              read
              create
              update
              delete
            }
            dashboard {
              code
              active
              read
              create
              update
              delete
            }
            receipts {
              code
              active
              read
              create
              update
              delete
            }
            documents {
              code
              active
              read
              create
              update
              delete
            }
            employees {
              code
              active
              read
              create
              update
              delete
            }
            employeesStatus {
              code
              active
              read
              create
              update
              delete
            }
            workCenters {
              code
              active
              read
              create
              update
              delete
            }
            companyGroupReports {
              code
              active
              read
              create
              update
              delete
            }
            legalRepresentative {
              code
              active
              read
              create
              update
              delete
            }
            signDocuments {
              code
              read
              active
              create
              update
              delete
            }
            templates {
              code
              active
              read
              create
              update
              delete
            }
            workTitles {
              code
              active
              read
              create
              update
              delete
            }
            groups {
              code
              active
              read
              create
              update
              delete
            }
            recordChecklist {
              code
              active
              read
              create
              update
              delete
            }
            requestDocsToEmployees {
              code
              active
              read
              create
              update
              delete
            }
            approveDocsFromEmployees {
              code
              active
              read
              create
              update
              delete
            }
            authorizeTemplates {
              code
              active
              read
              create
              update
              delete
            }
            publishTemplates {
              code
              active
              read
              create
              update
              delete
            }
            holidays {
              code
              active
              read
              create
              update
              delete
            }
            videoAgreementAuthorizer {
              code
              active
              read
              create
              update
              delete
            }
            videoAgreementTemplates {
              code
              active
              read
              create
              update
              delete
            }
          }
        }
      }
      adminWC {
        permissions {
          rhPodPermissions {
            ctAdmins {
              code
              active
              read
              create
              update
              delete
            }
            dashboard {
              code
              active
              read
              create
              update
              delete
            }
            receipts {
              code
              active
              read
              create
              update
              delete
            }
            documents {
              code
              active
              read
              create
              update
              delete
            }
            employees {
              code
              active
              read
              create
              update
              delete
            }
            employeesStatus {
              code
              active
              read
              create
              update
              delete
            }
            workCenters {
              code
              active
              read
              create
              update
              delete
            }
            signDocuments {
              code
              read
              active
              create
              update
              delete
            }
            templates {
              code
              active
              read
              create
              update
              delete
            }
            workTitles {
              code
              active
              read
              create
              update
              delete
            }
            groups {
              code
              active
              read
              create
              update
              delete
            }
            recordChecklist {
              code
              active
              read
              create
              update
              delete
            }
            requestDocsToEmployees {
              code
              active
              read
              create
              update
              delete
            }
            approveDocsFromEmployees {
              code
              active
              read
              create
              update
              delete
            }
            authorizeTemplates {
              code
              active
              read
              create
              update
              delete
            }
            publishTemplates {
              code
              active
              read
              create
              update
              delete
            }
            holidays {
              code
              active
              read
              create
              update
              delete
            }
            videoAgreementAuthorizer {
              code
              active
              read
              create
              update
              delete
            }
            videoAgreementTemplates {
              code
              active
              read
              create
              update
              delete
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PERMISSIONS = gql`
  mutation updateCompanyRoles($input: CompanyRolesInput!) {
    updateCompanyRoles(input: $input) {
      __typename
    }
  }
`;

export const CREATE_WC_ADMIN_COMPANY = gql`
  mutation createCompanyWorkCenterAdmin($input: CompanyWorkCenterAdminInput!) {
    createCompanyWorkCenterAdmin(input: $input) {
      __typename
      ... on ValidationError {
        field
        validationMessage
      }
      ... on WorkCenterNotFound {
        message
      }
      ... on UserAlreadyExists {
        message
      }
      ... on AdminIncorrectInfo {
        message
      }
      ... on CompanyNotFound {
        message
      }
      ... on Success {
        message
        warnings
      }
    }
  }
`;

export const GET_USER_PERMISSIONS = gql`
  query getUserPermissions($userId: String!) {
    getUserPermissions(userId: $userId) {
      __typename
      ... on UserPermissions {
        superAdmin {
          companyPermissions {
            companyData {
              code
              read
              create
              update
              delete
            }
            superAdmins {
              code
              read
              create
              update
              delete
            }
            modules {
              code
              read
              create
              update
              delete
            }
            consumption {
              code
              read
              create
              update
              delete
            }
            billing {
              code
              read
              create
              update
              delete
            }
            rolesAndPermissions {
              code
              read
              create
              update
              delete
            }
            legalRepresentative {
              code
              read
              create
              update
              delete
            }
            signDocuments {
              code
              read
              create
              update
              delete
            }
          }
          rhPodPermissions {
            hrAdmins {
              code
              read
              create
              update
              delete
            }
            dashboard {
              code
              read
              create
              update
              delete
            }
            receipts {
              code
              read
              create
              update
              delete
            }
            documents {
              code
              read
              create
              update
              delete
            }
            employees {
              code
              read
              create
              update
              delete
            }
            employeesStatus {
              code
              read
              create
              update
              delete
            }
            workCenters {
              code
              read
              create
              update
              delete
            }
            companyGroupReports {
              code
              read
              create
              update
              delete
            }
            legalRepresentative {
              code
              read
              create
              update
              delete
            }
            templates {
              code
              read
              create
              update
              delete
            }
            workTitles {
              code
              read
              create
              update
              delete
            }
            groups {
              code
              read
              create
              update
              delete
            }
            recordChecklist {
              code
              read
              create
              update
              delete
            }
            requestDocsToEmployees {
              code
              read
              create
              update
              delete
            }
            approveDocsFromEmployees {
              code
              read
              create
              update
              delete
            }
            authorizeTemplates {
              code
              read
              create
              update
              delete
            }
            publishTemplates {
              code
              read
              create
              update
              delete
            }
            holidays {
              code
              read
              create
              update
              delete
            }
            videoAgreementAuthorizer {
              code
              read
              create
              update
              delete
            }
            videoAgreementTemplates {
              code
              read
              create
              update
              delete
            }
          }
        }
        adminRh {
          rhPodPermissions {
            hrAdmins {
              code
              read
              create
              update
              delete
            }
            dashboard {
              code
              read
              create
              update
              delete
            }
            receipts {
              code
              read
              create
              update
              delete
            }
            documents {
              code
              read
              create
              update
              delete
            }
            employees {
              code
              read
              create
              update
              delete
            }
            employeesStatus {
              code
              read
              create
              update
              delete
            }
            workCenters {
              code
              read
              create
              update
              delete
            }
            companyGroupReports {
              code
              read
              create
              update
              delete
            }
            legalRepresentative {
              code
              read
              create
              update
              delete
            }
            signDocuments {
              code
              read
              create
              update
              delete
            }
            templates {
              code
              read
              create
              update
              delete
            }
            workTitles {
              code
              read
              create
              update
              delete
            }
            groups {
              code
              read
              create
              update
              delete
            }
            recordChecklist {
              code
              read
              create
              update
              delete
            }
            requestDocsToEmployees {
              code
              read
              create
              update
              delete
            }
            approveDocsFromEmployees {
              code
              read
              create
              update
              delete
            }
            authorizeTemplates {
              code
              read
              create
              update
              delete
            }
            publishTemplates {
              code
              read
              create
              update
              delete
            }
            holidays {
              code
              read
              create
              update
              delete
            }
            videoAgreementAuthorizer {
              code
              read
              create
              update
              delete
            }
            videoAgreementTemplates {
              code
              read
              create
              update
              delete
            }
          }
        }
        adminWC {
          rhPodPermissions {
            ctAdmins {
              code
              read
              create
              update
              delete
            }
            dashboard {
              code
              read
              create
              update
              delete
            }
            receipts {
              code
              read
              create
              update
              delete
            }
            documents {
              code
              read
              create
              update
              delete
            }
            employees {
              code
              read
              create
              update
              delete
            }
            employeesStatus {
              code
              read
              create
              update
              delete
            }
            workCenters {
              code
              read
              create
              update
              delete
            }
            signDocuments {
              code
              read
              create
              update
              delete
            }
            templates {
              code
              read
              create
              update
              delete
            }
            workTitles {
              code
              read
              create
              update
              delete
            }
            groups {
              code
              read
              create
              update
              delete
            }
            recordChecklist {
              code
              read
              create
              update
              delete
            }
            requestDocsToEmployees {
              code
              read
              create
              update
              delete
            }
            approveDocsFromEmployees {
              code
              read
              create
              update
              delete
            }
            authorizeTemplates {
              code
              read
              create
              update
              delete
            }
            publishTemplates {
              code
              read
              create
              update
              delete
            }
            holidays {
              code
              read
              create
              update
              delete
            }
            videoAgreementAuthorizer {
              code
              read
              create
              update
              delete
            }
            videoAgreementTemplates {
              code
              read
              create
              update
              delete
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_PERMISSIONS = gql`
  mutation updateUserPermissions($input: UpdateUserPermissionsInput!) {
    updateUserPermissions(input: $input) {
      __typename
    }
  }
`;

export const GET_ADMIN_USERS_FROM_COMPANY = gql`
  query getCompanyAdmins($adminTypes: [AdminType]!, $searchPermission: String) {
    getCompanyAdmins(
      adminTypes: $adminTypes
      searchPermission: $searchPermission
    ) {
      _id
      id
      rfc
      names
      fullName
      lastNameP
      lastNameM
      status
      lastLogin
      email
      phone
      userWhoInvite {
        _id
        curp
        lastNameP
        lastNameM
        names
        profilePicture
        rfc
      }
      hasPermissions
      alta
      rol
      profilePicture
      allWorkCenterData {
        workCenterName
        workCenterId
        employee
      }
      isEmployee
      workCenterIdAsEmployee
    }
  }
`;

export const UPDATE_WC_ADMIN_WORKCENTERS = gql`
  mutation updateWCAdminWorkCenters($input: updateWCAdminWorkCentersInput!) {
    updateWCAdminWorkCenters(input: $input) {
      __typename
    }
  }
`;

export const CREATE_ADMIN_RH_COMPANY = gql`
  mutation createCompanyAdminRh($input: CompanyAdminRhAdminInput!) {
    createCompanyAdminRh(input: $input) {
      __typename
      ... on ValidationError {
        field
        validationMessage
      }
      ... on WorkCenterNotFound {
        message
      }
      ... on AdminIncorrectInfo {
        message
      }
      ... on UserAlreadyExists {
        message
      }
      ... on CompanyNotFound {
        message
      }
      ... on Success {
        message
        warnings
      }
    }
  }
`;

export const GET_ALL_COMPANY_ADMINS = gql`
  query getAllCompanyAdmins(
    $page: Int
    $perPage: Int
    $filter: GetCompanyAdminsFilterInput
  ) {
    getAllCompanyAdmins(filter: $filter, page: $page, perPage: $perPage) {
      ... on GetCompanyAdminsResult {
        total
        admins {
          _id
          user {
            _id
            names
            lastNameP
            lastNameM
            fullName
            rfc
            email
            profilePicture
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_MODULES = gql`
  query getCompanyModules($filter: GetCompanyModulesFilterInput) {
    getCompanyModules(filter: $filter) {
      ... on CompanyModulesResult {
        modules {
          documents {
            active
          }
          holidays {
            active
          }
          rest {
            active
          }
          ai {
            active
            getTaxCertificateData
            getImssCertificateData
          }
        }
        employeeRecordTags {
          _id
          category
          description
          color
          isSoraCategory
          subcategories {
            _id
            subcategory
            isSoraSubcategory
            isAiEnabled
            description
          }
        }
      }
    }
  }
`;
