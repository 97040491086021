import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents/Typography';
import { styles } from './ScreenTitle.styles';
import { IconOptions } from '../../components/Icons/IconTypes';

interface ScreenTitleProps {
  title: string;
  icon: IconOptions;
}

export const ScreenTitle = (props: ScreenTitleProps) => {
  const { title = 'Screen title', icon = 'brackets_line' } = props;
  const theme = useTheme();
  const style = styles({ theme });
  return (
    <Box>
      <Box sx={style.root}>
        <Box sx={style.icon}>
          <Icon icon={icon} height={24} color="primary" />
        </Box>
        <Typography variant="h4" color="text">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
