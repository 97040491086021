import React, { useMemo } from 'react';
import { Box, Card, CardContent, Stack } from '@mui/material';
import {
  CalendarEvent,
  CalendarEventTypes,
  CalendarView,
} from './SoraCalendar.types';
import { Icon } from '../../components/Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { useScreenSize } from '../../Hooks';

type DotProps = {
  eventType: CalendarEventTypes;
  color: string;
};

const Dot: React.FC<DotProps> = ({ eventType, color }) => {
  return (
    <Box
      width="8px"
      height="8px"
      borderRadius="50%"
      sx={{ background: color }}
      mr={1}
    />
  );
};

type CalendarEventsSideBarProps = {
  events?: CalendarEvent[];
  handleEventClick?: (event: CalendarEvent) => void;
  handleDeleteEventClick?: (event: CalendarEvent) => void;
  isSupportPanel?: boolean;
  currentDate: Date;
  view: CalendarView;
  isEmployeeView: boolean;
};

type SortedEventsType = CalendarEvent & { isEventActionsEnabled: boolean };

function dateFormatter(date: Date, includeYear = false): string {
  const monthString = date.toLocaleString('es-Mx', { month: 'long' });
  const monthCapitalized =
    monthString.charAt(0).toUpperCase() + monthString.slice(1);
  const day = date.getDate();
  if (includeYear) {
    return `${day} ${monthCapitalized} ${date.getFullYear()}`;
  }
  return `${day} ${monthCapitalized}`;
}

export const CalendarEventsSideBar: React.FC<CalendarEventsSideBarProps> = ({
  events = [],
  handleEventClick,
  handleDeleteEventClick,
  currentDate,
  view,
  isEmployeeView = false,
  isSupportPanel = false,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  const editEvent = (event: CalendarEvent) => {
    if (isSupportPanel) {
      return handleEventClick?.(event);
    } else {
      if (event.type === CalendarEventTypes.COMPANY) {
        return handleEventClick?.(event);
      }
    }
  };

  const sortedEvents: SortedEventsType[] = useMemo(() => {
    const isEventActionsEnabled = (event: CalendarEvent) => {
      if (isEmployeeView) return false;
      if (isSupportPanel) return true;
      // is admin view
      return event.type === CalendarEventTypes.COMPANY;
    };
    return events
      ?.sort((a, b) => a.date.getTime() - b.date.getTime())
      .filter((event) => {
        if (view === 'MONTH') {
          return (
            event.date.getMonth() === currentDate.getMonth() &&
            event.date.getFullYear() === currentDate.getFullYear()
          );
        } else {
          return event.date.getFullYear() === currentDate.getFullYear();
        }
      })
      .map((event) => ({
        ...event,
        isEventActionsEnabled: isEventActionsEnabled(event),
      }));
  }, [events, view, currentDate, isEmployeeView, isSupportPanel]);

  return (
    <Stack
      mx={2}
      p={2}
      sx={{
        borderLeft: isMobile ? 'none' : `1px solid ${theme.newPalette.divider}`,
        height: '100%',
        minWidth: '300px',
      }}
    >
      <Stack direction={'row'} gap={2}>
        <Typography component={'span'} color={'gray_70'}>
          <Icon icon={'calendar_check_line'} color={'grey'} />
        </Typography>
        <Typography variant="h6" mb={2}>
          Festivos
        </Typography>
      </Stack>

      <Stack gap={2}>
        {sortedEvents.length === 0 && (
          <Typography variant={'body1'}>No hay eventos</Typography>
        )}
        {sortedEvents?.map((event, index) => (
          <Card
            className={
              event.isEventActionsEnabled
                ? 'calendar-sidebar-event-card'
                : undefined
            }
            key={index}
            onClick={() =>
              event.isEventActionsEnabled ? editEvent(event) : null
            }
            sx={{
              cursor: event.isEventActionsEnabled ? 'pointer' : 'default',
              borderRadius: '16px',
              border: `1px solid ${theme.customPalette.grayColors.gray_30}`,
              '&:hover': {
                backgroundColor: theme.customPalette.grayColors.gray_10,
              },
            }}
          >
            <CardContent>
              <Stack
                direction={'row'}
                alignItems={'start'}
                justifyContent={'space-between'}
              >
                <Stack>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Dot eventType={event.type} color={event.color} />
                    <Typography variant="body1" fontWeight={'bold'}>
                      <strong>{dateFormatter(event.date, true)}</strong>
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body1"
                    color={theme.newPalette.text.secondary}
                  >
                    {event.title}
                  </Typography>
                </Stack>
                <Stack className={'calendar-sidebar-event-delete-button-stack'}>
                  {event.isEventActionsEnabled && !!handleDeleteEventClick && (
                    <IconButton
                      icon={'delete_bin_line'}
                      color={'error'}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteEventClick?.(event);
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};
