import { useState } from 'react';
import { RowBaseItem } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { AutocompleteOption } from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';

export const useCHSEmployeesActions = () => {
  const [page, setPage] = useState<number>(1);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [tableTab, setTableTab] = useState<'success' | 'error'>('success');
  const [errorRows, setErrorRows] = useState<RowBaseItem[]>([]);

  // this will hold the rows of the employees
  // this should be the sum of the rows selected here and the rows preloaded on preselectedScheme
  const [rows, setRows] = useState<RowBaseItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<RowBaseItem[]>([]);

  // ========================= actions functions

  const addRow = (row: AutocompleteOption) => {
    // The ALL_EMPLOYEES button is not in the figma, but I bet it'll be added :)
    const rowsWithoutAllEmployees = rows.filter(
      (row) => row.type !== 'ALL_EMPLOYEES',
    );
    setRows([...rowsWithoutAllEmployees, row]);
  };

  const deleteRows = (items: RowBaseItem[]) => {
    const itemsIds = items.map((item) => item._id);
    // removing from rows
    setRows(rows.filter((r) => !itemsIds.includes(r._id)));
    setSelectedRows(selectedRows.filter((r) => !itemsIds.includes(r._id)));
  };

  const handleCheckAllRows = (checked: boolean) => {
    setIsCheckedAll(checked);
    if (checked) {
      setSelectedRows(tableTab === 'success' ? rows : errorRows);
    } else {
      setSelectedRows([]);
    }
  };

  return {
    states: {
      page,
      setPage,
      isCheckedAll,
      setIsCheckedAll,
      tableTab,
      setTableTab,
      errorRows,
      setErrorRows,
      rows,
      setRows,
      selectedRows,
      setSelectedRows,
    },
    actions: {
      addRow,
      deleteRows,
      handleCheckAllRows,
      setRows,
    },
  };
};
