import { useReactiveVar } from '@apollo/client';
import { soraClientId } from '../../../../API/instance/createInstance';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { useGetMyProfile } from '../../../../containers/RHPod/EmployeePortal/useGetMyProfile';
import { VERIFICATION_TOKEN } from '../IdentityVerificationDialog.constants';
import {
  identityVerificationDialogVar,
  resetIdentityVerificationDialog,
} from '../IdentityVerificationDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useEndIdentityVerification() {
  const { fetchMyProfile } = useGetMyProfile();
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const { verificationId, onFinish } = identityVerificationDialog;

  const handleEndIdentityVerification = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Verificando tu identidad...',
      });

      const payload: any = {
        verificationId,
        useStoredImages: true,
        clientIds: [soraClientId],
      };

      const response = await sifeEndpoints.endVerification.post(payload);

      if (response.status === 200) {
        // used to fetch the updated profile and keep userVar updated
        await fetchMyProfile();

        const { verificationData } = response.data.data;
        const { completionToken } = verificationData;
        localStorage.setItem(VERIFICATION_TOKEN, completionToken);
        onFinish();
        resetIdentityVerificationDialog();
        globalSnackbarVar({
          show: true,
          message: 'Identidad verificada',
          severity: 'success',
        });
        return;
      }

      if (response.status === 400) {
        globalSnackbarVar({
          show: true,
          message:
            'No se encontró similitud entre la selfie y la foto de su identificación, por favor verifique que la identificación y la selfie sean de la misma persona.',
          severity: 'error',
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al realizar la verificación',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleEndIdentityVerification };
}
