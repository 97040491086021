import { sifeEndpoints } from '../../../API/sifeEndpoints';
import { setLivenessDialogVar } from './LivenessDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';

export const handleStartLivenessSession = async ({
  verificationId,
  onSuccess,
  onFail = () => {},
}: {
  verificationId: string;
  onSuccess: (imageUrl: string) => void;
  onFail?: (errorMessage: string) => void;
}) => {
  try {
    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Creando sesión de reconocimiento facial...',
    });
    const response = await sifeEndpoints.createLivenessSession.post({
      verificationId,
    });

    globalBackdropVar({ open: false });

    if (response.status === 201) {
      setLivenessDialogVar({
        open: true,
        sessionId: response.data.data.sessionId,
        verificationId,
        onSuccess,
        onFail,
      });
      return;
    }
    globalSnackbarVar({
      show: true,
      message: 'Ocurrió un error al crear la sesión de reconocimiento facial',
      severity: 'error',
    });
  } catch {
    globalSnackbarVar({
      show: true,
      message: 'Ocurrió un error, contacte a Sora',
      severity: 'error',
    });
  } finally {
    globalBackdropVar({ open: false });
  }
};
