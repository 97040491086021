import { hexToRgb } from '@mui/material';
import { isEqual } from 'date-fns';
import formatDistance from 'date-fns/formatDistance';
import { es } from 'date-fns/locale';
import { curpRegex } from './validators';

export const copy = (aObject) => {
  // Prevent undefined objects
  if (!aObject) return aObject;

  let bObject = Array.isArray(aObject) ? [] : {};

  let value;
  for (const key in aObject) {
    // Prevent self-references to parent object
    // if (Object.is(aObject[key], aObject)) continue;

    value = aObject[key];

    bObject[key] = typeof value === 'object' ? copy(value) : value;
  }

  return bObject;
};

export const removeTZ = (date) => {
  if (!date) return null;
  return date.slice(0, date.indexOf('T'));
};

const dateFormats = {
  humanShort: { year: '2-digit', month: 'short', day: 'numeric' },
  'dd MM yyyy': { year: 'numeric', month: 'short', day: 'numeric' },
  'dd/MM/yyyy': { year: 'numeric', month: 'numeric', day: 'numeric' },
  nsn: { year: 'numeric', month: 'short', day: 'numeric' },
  'd MMM yyyy h:mm': {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
};
dateFormats['localDate'] = dateFormats.humanShort;
dateFormats['d MMM y'] = dateFormats.humanShort;

export const formatDate = (date, formatStr = 'd MMM y') => {
  if (!date) return '';
  const dateOptions = dateFormats[formatStr] ?? dateFormats.humanShort;
  try {
    return new Date(date).toLocaleDateString('es-Mx', dateOptions);
  } catch (err) {
    return date;
  }
};

/**
 * returns a formatted date and time
 * @param {Date|null} [date]
 * @param {string} [dateFormat]
 * @returns {string} Returns a formatted date and time
 */
export const formatDateTime = (date, dateFormat) => {
  if (!date) return '';
  try {
    const dateString = formatDate(date, dateFormat);
    const timeString = date
      .toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' })
      .toLowerCase();
    return `${dateString} ${timeString}`;
  } catch (err) {
    return '';
  }
};

/**
 * returns a date rounded to the nearest half hour
 * @param  {Date|null} date
 * @return {Date|null}
 */
export const roundDateTimeToNearestHalfHour = (date) => {
  if (!date) return null;
  const newDate = date;
  const minutes = newDate.getMinutes();
  if (minutes < 15) {
    newDate.setMinutes(0);
  } else if (minutes < 45) {
    newDate.setMinutes(30);
  } else {
    newDate.setMinutes(0);
    newDate.setHours(newDate.getHours() + 1);
  }
  return newDate;
};

export const todayAt0Minutes = () => {
  const today = new Date();
  today.setHours(today.getHours() + 1, 0, 0, 0);
  return today;
};

export const formatDateToLocaleString = (date, formatStr = 'd MMM y') => {
  if (!date) return;
  const ISOStringDate = new Date(date).toISOString();
  const sanitizedDate = ISOStringDate.replace(/-/g, '/').replace(/T.+/, '');
  const convertedDate = new Date(sanitizedDate);
  const dateOptions = dateFormats[formatStr] ?? dateFormats.humanShort;

  try {
    return new Date(convertedDate).toLocaleDateString('es-Mx', dateOptions);
  } catch (err) {
    return date;
  }
};

export const numberWithCommas = (num) => {
  if (!num) return '0';
  if (typeof num !== 'string') num = num.toString();

  let decimals = [];
  let numArray = [];
  if (num.includes('.')) {
    decimals = num.split('.')[1].slice(0, 2);
    decimals = [decimals.concat(decimals.length === 1 ? '0' : '')];
    numArray = num.split('.')[0].split('');
  } else {
    numArray = num.split('');
  }

  let numberResult = numArray.reverse().reduce((acc, curr, index) => {
    if (index % 3 === 0 && index !== 0) acc.push(',');
    acc.push(curr);
    return acc;
  }, []);

  if (decimals.length) numberResult = decimals.concat(['.'], numberResult);
  return numberResult.reverse().join('');
};

// Get milliseconds from hours, minutes, or/and seconds.
/**
 * @param {object} input
 * @param {number} [input.h]
 * @param {number} [input.min]
 * @param {number} [input.s]
 * @returns {number}
 */
export function getMs({ h, min, s }) {
  const acceptedValues = ['number', 'undefined'];
  try {
    if (
      acceptedValues.includes(typeof h) &&
      acceptedValues.includes(typeof min) &&
      acceptedValues.includes(typeof s)
    ) {
      const hour = h ? h * 3600000 : 0;
      const minute = min ? min * 60000 : 0;
      const second = s ? s * 1000 : 0;
      return hour + minute + second;
    } else {
      throw new Error('expected integer values in object values');
    }
  } catch (error) {
    console.error(error);
  }
}

export const randomName = () => {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};
export const splitInBatches = ({ array, size }) => {
  const result = [];
  let i = 0;
  while (i < array.length) {
    result.push(array.slice(i, (i += size)));
  }
  return result;
};

const possibleWidgetTitles = [
  'Button to launch messaging window',
  'Botón para iniciar la ventana de mensajería',
  'Button to launch messaging window, conversation in progress',
  'Botón para iniciar la ventana de mensajería, conversación en curso',
];
/*
This approach is not recommended, but it is the only way to hide the widget since it is loaded in an iframe and is not a React component.
The widget provider may change data like title, name, id, etc. sporadically and this approach will not work at all. 
Consider looking for a better way to do this safely.
*/
export const hideOrShowWidget = (display) => {
  const iframeCollection = document.getElementsByTagName('iframe');
  const iframeArray = Array.from(iframeCollection);

  const launchButton = iframeArray.find((iframe) =>
    possibleWidgetTitles.includes(iframe.title),
  );

  if (launchButton) {
    launchButton.style.display = display;
  }

  const iframeMessage = iframeArray.find(
    (iframe) =>
      iframe.title === 'Messaging window' ||
      iframe.title === 'Ventana de mensajería',
  );

  if (iframeMessage) {
    const div = iframeMessage.parentNode;
    // @ts-ignore
    div.style.display = display;
  }

  const iframeText = iframeArray.find(
    (iframe) =>
      iframe.title === 'Mensaje de la compañía' ||
      iframe.title === 'Message from company',
  );

  if (iframeText) {
    iframeText.style.display = display;
  }
};

export const uuidv4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

export const compareDates = (dateFilter, defaultDateFilter) => {
  const { start, end } = dateFilter;
  const { start: defStartDate, end: defEndDate } = defaultDateFilter;

  const startDatesAreEqual =
    (defStartDate === null && start === null) || isEqual(start, defStartDate);

  const endDatesAreEqual =
    (defEndDate === null && end === null) || isEqual(end, defEndDate);

  return startDatesAreEqual && endDatesAreEqual;
};

export const hexToRgbHelper = (hexColor, opacity) => {
  if (!hexColor || typeof hexColor !== 'string') return '';

  const rgbColor = hexToRgb(hexColor);
  if (!opacity) return rgbColor;

  const splittedArray = rgbColor.split('');
  splittedArray.splice(rgbColor.length - 1, 0, `, ${opacity}`);
  return splittedArray.join('');
};

export const replaceCharacters = (str) => {
  if (!str) return '';
  const separatorRegExp = /[,; \n\t]/g;

  // Replace the separator characters with dots
  const result = str.replace(separatorRegExp, ';');

  // Return the modified string
  return result;
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatNumberWithSuffix = (val) => {
  if (!val) return 0;
  const formattedNumber = val.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
  });

  return formattedNumber;
};

/** @param {number} bytes */
export const bytesToMB = (bytes) => {
  if (!bytes) return 0;
  return parseFloat(`${bytes / 1024 / 1024}`).toFixed(2);
};

/**
 * @param {Date} date
 * @param {object} options
 * @returns {string}
 */
export const formatDateDistance = (date, options = {}) => {
  if (!date) return '';
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    includeSeconds: true,
    locale: es,
    ...options,
  });
};

export const getFileExtension = (filename) => {
  if (typeof filename !== 'string') return '';
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  return filename.slice(lastDotIndex + 1);
};

export const calculateAge = (birthdate) => {
  if (!birthdate) return 0;

  const today = new Date();
  // Calculate the difference in years
  let age = today.getUTCFullYear() - birthdate.getUTCFullYear();

  // Check if the birthday hasn't occurred yet this year
  const hasBirthdayOccurred =
    today.getUTCMonth() > birthdate.getUTCMonth() ||
    (today.getUTCMonth() === birthdate.getUTCMonth() &&
      today.getUTCDate() >= birthdate.getUTCDate());

  // Adjust age if the birthday hasn't occurred yet
  if (!hasBirthdayOccurred) age--;

  return age;
};

export const calculateJobSeniority = (employmentStartDate) => {
  if (!employmentStartDate) return '';

  const today = new Date();

  let years = today.getUTCFullYear() - employmentStartDate.getUTCFullYear();
  let months = today.getUTCMonth() - employmentStartDate.getUTCMonth();
  let days = today.getUTCDate() - employmentStartDate.getUTCDate();

  if (days < 0) {
    months--;
    days += new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      0,
    ).getUTCDate();
  }
  if (months < 0) {
    years--;
    months += 12;
  }

  let jobSeniority = '';
  if (years > 0) {
    jobSeniority += years + (years > 1 ? ' años ' : ' año ');
  }
  if (months > 0) {
    jobSeniority += months + (months > 1 ? ' meses ' : ' mes ');
  }
  if (days > 0) {
    jobSeniority += days + (days > 1 ? ' días' : ' día');
  }

  return jobSeniority;
};

export const parseCURP = (curp) => {
  if (!curp) return null;
  // Validate CURP format with specific Mexican state codes and other components

  if (!curpRegex.test(curp)) return null;
  // Extract birth date
  const year = curp.substring(4, 6);
  const month = curp.substring(6, 8);
  const day = curp.substring(8, 10);

  // Determine century (19 or 20) based on first two digits
  const fullYear = parseInt(year) < 30 ? `20${year}` : `19${year}`;

  // Create date object
  const birthDate = new Date(`${fullYear}-${month}-${day}`);

  // Extract gender (H = Male, M = Female)
  const gender = curp.charAt(10) === 'H' ? 'MALE' : 'FEMALE';

  return {
    birthdate: birthDate,
    gender,
  };
};

/**
 * Formats a date to Spanish locale in UTC format and returns it as a string.
 * Regardless of the user's time zone, the result will always be in UTC
 * (without hours, minutes, or seconds).
 * @param {string | Date} dateParam - The date to format.
 * @returns {string} Formatted date in the format "day month year".
 */
export const formatDateToSpanishLocale = (dateParam) => {
  if (!dateParam) return '';
  const parsedDate = new Date(dateParam);
  const year = parsedDate.getFullYear();
  const monthName = parsedDate.toLocaleString('es-ES', {
    month: 'long',
    timeZone: 'UTC',
  });
  const day = parsedDate.getUTCDate();

  return `${day} ${monthName} ${year}`;
};
