import React from 'react';
import { Stack, TextField } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { useTheme } from '@mui/material/styles';
import { CalendarView } from './SoraCalendar.types';

type SoraCalendarYearNavigationProps = {
  currentYear: number;
  setCurrentYear: (date: number) => void;
  onChangeView: (view: CalendarView) => void;
  onTodayClick: () => void;
  currentView: CalendarView;
};

export const SoraCalendarYearNavigation: React.FC<
  SoraCalendarYearNavigationProps
> = ({
  currentYear,
  setCurrentYear,
  onChangeView,
  onTodayClick,
  currentView,
}) => {
  const theme = useTheme();
  return (
    <>
      <Stack
        p={2}
        mb={2}
        direction={'row'}
        justifyContent={'space-between'}
        sx={{
          borderBottom: `1px solid ${theme.newPalette.grey.grey300}`,
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Button
            variant="outlined"
            color={'default'}
            sx={{
              borderRadius: '1em',
              height: '2em',
              minHeight: '2em',
              p: '.2em 0',
              fontWeight: 'normal',
            }}
            onClick={onTodayClick}
          >
            Hoy
          </Button>
          <Button
            color={'default'}
            onClick={() => setCurrentYear(currentYear - 1)}
          >
            <Icon icon={'arrow_left_s_line'} />
          </Button>
          <Typography>{currentYear}</Typography>
          <Button
            color={'default'}
            onClick={() => setCurrentYear(currentYear + 1)}
          >
            <Icon icon={'arrow_right_s_line'} />
          </Button>
        </Stack>
        <TextField
          select
          variant="outlined"
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{ width: '100px' }}
          value={currentView}
          onChange={(e) => onChangeView(e.target.value as CalendarView)}
        >
          <option value={CalendarView.MONTH}>Mes</option>
          <option value={CalendarView.YEAR}>Año</option>
        </TextField>
      </Stack>
    </>
  );
};
