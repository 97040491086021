import React from 'react';
import { Stack } from '@mui/material';
import { Block } from './Block';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { Typography } from '../../../../../../../newComponents';
import { newPaletteType } from '../../../../../../../theme';

export const HolidaySchemeDaysGrantedHeader: React.FC = () => {
  const theme = useTheme();
  const palette: newPaletteType = theme.newPalette;

  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <Block color={'success'} sx={{ justifyContent: 'center' }}>
          <Typography
            sx={{
              color: `${palette.success.dark} !important`,
              backgroundColor: palette.success.lighter,
            }}
            variant={'body1'}
          >
            <strong>Periodo cumplido</strong>
          </Typography>
        </Block>
        <Stack p={1}>
          <Icon
            fill={theme.newPalette.grey.grey500}
            icon={'arrow_right_long_line'}
            width={'18px'}
            height={'18px'}
          />
        </Stack>
        <Block color={'warning'} sx={{ justifyContent: 'center' }}>
          <Typography
            sx={{
              color: `${palette.warning.dark} !important`,
              backgroundColor: palette.warning.lighter,
            }}
            variant={'body1'}
          >
            <strong>Días otorgados</strong>
          </Typography>
        </Block>
      </Stack>
    </>
  );
};
