import { useReactiveVar } from '@apollo/client';
import { Amplify } from 'aws-amplify';
import { Dialog } from '../../../newComponents/Dialog';
import { livenessDialogVar } from './LivenessDialog.vars';
import awsexports from './aws-exports';
import { LivenessContent } from './LivenessContent';
import { DialogContent } from '@mui/material';

Amplify.configure(awsexports);

export function LivenessDialog() {
  const { open, sessionId, verificationId } = useReactiveVar(livenessDialogVar);

  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      PaperProps={{ sx: { backgroundColor: 'black' } }}
    >
      <DialogContent
        sx={{
          padding: 0,
          position: 'fixed',
          inset: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {sessionId && verificationId && <LivenessContent />}
      </DialogContent>
    </Dialog>
  );
}
