import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { AvatarCheckIcon } from '../IdentityVerificationDialogAssets/AvatarCheckIcon';
import { Alert } from '../../../../newComponents/Alert';
import { AlertTitle } from '../../../../newComponents/AlertTitle';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import { LottieAnimation } from '../../../LottieAnimation';
import { handleStartLivenessSession } from '../../LivenessDialog/LivenessDialog.helpers';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';

export function CaptureLiveness() {
  const [errorMessage, setErrorMessage] = useState('');
  const [showExampleAnimated, setShowExampleAnimated] = useState(false);
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const { selectedIDType, verificationId } = identityVerificationDialog;
  const { isMobile } = useScreenSize();

  const handleBack = () => {
    if (!selectedIDType) return;
    const workflow = identityVerificationDialog[selectedIDType];
    const { currentStep } = workflow;

    setIdentityVerificationDialogVar({
      [selectedIDType]: {
        ...workflow,
        currentStep: currentStep - 1,
      },
    });
  };

  const handleStart = async () => {
    await handleStartLivenessSession({
      verificationId,
      onFail: (errMsg) => {
        setErrorMessage(errMsg);
        setShowExampleAnimated(false);
      },
      onSuccess: (imageUrl) => {
        const workflow = identityVerificationDialog[selectedIDType];
        setIdentityVerificationDialogVar({
          showSummary: true,
          [selectedIDType]: {
            ...workflow,
            selfie: { imageData: { dataUrl: imageUrl, width: 0, height: 0 } },
          },
        });
      },
    });
  };

  const handleContinue = () => {
    setShowExampleAnimated(true);
  };

  if (showExampleAnimated) {
    return (
      <>
        <DialogContent dividers sx={{ borderBottom: 'none' }}>
          <Stack
            maxWidth="800px"
            minHeight={isMobile ? '100%' : '65vh'}
            alignItems="center"
            justifyContent="center"
            margin="0 auto"
            gap={3}
          >
            <LottieAnimation animation="liveness" height={309} width={220} />
            <Stack textAlign="center" alignItems="center">
              <Typography variant="body1" color="grey">
                ¿Estás listo?
              </Typography>
              <Typography variant="body1" color="grey" maxWidth="300px">
                Centra tu rostro y espera a que la pantalla cambie de colores.
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Stack
            gap={2}
            width="100%"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'center' : 'right'}
          >
            <Button
              variant="outlined"
              color="default"
              size={isMobile ? 'medium' : 'large'}
              onClick={() => setShowExampleAnimated(false)}
              fullWidth={isMobile}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              color="primary"
              size={isMobile ? 'medium' : 'large'}
              onClick={handleStart}
              fullWidth={isMobile}
            >
              Comenzar reconocimiento facial
            </Button>
          </Stack>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          maxWidth="800px"
          minHeight={isMobile ? '100%' : '65vh'}
          alignItems="center"
          justifyContent="center"
          margin="0 auto"
          gap={3}
        >
          <AvatarCheckIcon height={120} />
          <Typography variant="h3">Reconocimiento facial</Typography>
          <Typography variant="body1" textAlign="center" maxWidth="300px">
            Con esta herramienta te identificaremos fácil y rapido.
          </Typography>
          {errorMessage === '' && (
            <Stack width="100%" maxWidth="400px" gap={2}>
              <Alert severity="info">
                <AlertTitle>Algunos tips</AlertTitle>
                1. Busca un lugar bien iluminado
                <br />
                2. Retira los accesorios: Como lentes oscuros y gorras
                <br />
                3. Relaja tu rostro
              </Alert>
            </Stack>
          )}
          {errorMessage !== '' && (
            <Stack width="100%" maxWidth="400px">
              <Alert icon={<Icon icon="information_line" />} severity="error">
                <AlertTitle>Vuelve a intentarlo</AlertTitle>
                {errorMessage}
              </Alert>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Stack
          gap={2}
          width="100%"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={isMobile ? 'center' : 'right'}
        >
          <Button
            variant="outlined"
            color="default"
            size={isMobile ? 'medium' : 'large'}
            onClick={handleBack}
            fullWidth={isMobile}
          >
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? 'medium' : 'large'}
            onClick={handleContinue}
            fullWidth={isMobile}
          >
            Continuar
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}
