import { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { CircularProgress, IconButton, Typography } from '../../newComponents';
import { VideoPlayerProps } from './VideoPlayer.types';
import { Icon } from '../../components/Icons/Icons';
import {
  Video,
  VideoWrapper,
  VideoPlayerContainer,
  VideoActionWrapper,
  OptionButton,
} from './VideoPlayer.styles';

export const VideoPlayer = ({
  videoUrl,
  autoPlay = true,
  playsInline = true,
  onClose,
  onLoaded,
}: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoSupported, setVideoSupported] = useState(true);

  const handleLoadedData = () => {
    if (!videoRef.current) return;
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    const isPortrait = videoHeight > videoWidth;
    if (isPortrait) videoRef.current.style.maxHeight = '600px';
  };

  const handlePlay = () => setIsPlaying(true);
  const handleEnded = () => setIsPlaying(false);
  const handlePause = () => setIsPlaying(false);
  const handleCanPlay = () => {
    if (onLoaded) onLoaded();
    setLoading(false);
  };

  const handleError = () => {
    const videoElement = videoRef.current;
    if (videoUrl && videoElement?.error?.code === 4) {
      setVideoSupported(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const element = videoRef?.current;
    if (element && videoUrl) {
      element.load(); // this is needed to trigger events with ios devices
      element.addEventListener('loadeddata', handleLoadedData, false);
      element.addEventListener('play', handlePlay, false);
      element.addEventListener('ended', handleEnded, false);
      element.addEventListener('pause', handlePause, false);
      return () => {
        element.removeEventListener('loadeddata', handleLoadedData, false);
        element.removeEventListener('play', handlePlay, false);
        element.removeEventListener('ended', handleEnded, false);
        element.removeEventListener('pause', handlePause, false);
      };
    }
  }, [videoUrl]);

  return (
    <VideoPlayerContainer>
      {loading && (
        <Stack
          justifyContent="center"
          alignItems="center"
          minHeight="600px"
          spacing={2}
        >
          <CircularProgress size={100} />
          <Typography variant="h6" customColor color="white">
            Cargando video...
          </Typography>
        </Stack>
      )}
      <VideoWrapper style={{ display: loading ? 'none' : 'flex' }}>
        {!videoSupported && (
          <Stack justifyContent="center" alignItems="center" gap={2}>
            <Icon icon="alert_line" height="50px" color="error" />
            <Typography variant="h6" customColor color="white">
              Formato de video no soportado
            </Typography>
          </Stack>
        )}
        <Video
          ref={videoRef}
          src={videoUrl}
          playsInline={playsInline}
          autoPlay={autoPlay}
          onCanPlay={handleCanPlay}
          onError={handleError}
          style={{ display: videoSupported ? 'block' : 'none' }}
        />
        {videoSupported && isPlaying && (
          <IconButton
            className="play-button"
            icon="pause_circle_line"
            onClick={() => videoRef.current.pause()}
            size="large"
            color="white"
            sx={{ position: 'absolute', opacity: 0.5 }}
          />
        )}
        {videoSupported && !isPlaying && (
          <IconButton
            className="play-button"
            icon="play_circle_line"
            onClick={() => videoRef.current.play()}
            size="large"
            color="white"
            sx={{ position: 'absolute', opacity: 0.5 }}
          />
        )}
      </VideoWrapper>
      {onClose && (
        <VideoActionWrapper>
          <OptionButton
            color="white"
            icon="close_line"
            onClick={onClose}
            size="medium"
          />
        </VideoActionWrapper>
      )}
    </VideoPlayerContainer>
  );
};
