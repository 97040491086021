import { DocumentActionOption } from './ControlledDocumentActionInput.types';

export const OPTIONS_SEND_TO_EMPLOYEES: DocumentActionOption[] = [
  {
    value: 'sendToEmployee',
    label: 'Envio a empleado',
    disabled: true,
  },
  {
    value: 'canBeSigned',
    label: 'Solicitar firma',
    icon: 'quill_pen_line',
  },
  {
    value: 'canBeSignedAndVideoAgreement',
    label: 'Firmar y grabar video acuerdo',
    icon: 'vidicon_line',
  },
  {
    value: 'markAsSeen',
    label: 'Confirmar de enterado',
    icon: 'eye_line',
  },
];

export const OPTIONS_NOT_SEND_TO_EMPLOYEES: DocumentActionOption[] = [
  {
    value: 'notSendToEmployee',
    label: 'No se envía al empleado',
    disabled: true,
  },
  {
    value: 'onlySave',
    label: 'Solo guardar',
    icon: 'folder_user_line',
  },
];
