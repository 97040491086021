import React from 'react';
import { Stack, Link } from '@mui/material';
import { Illustrations } from '../../components/Illustrations/Illustrations';
import { Typography } from '../../newComponents';
import { supportDialogVar } from '../../businessComponents/SupportDialog/SupportDialog.vars';

interface ErrorDialogContentProps {
  hideSupportMessage?: boolean;
}

export const ErrorDialogContent = (props: ErrorDialogContentProps) => {
  const { hideSupportMessage = false } = props;
  return (
    <Stack alignItems="center" gap={1}>
      <img src={Illustrations.somethingWentWrongSvg} height={100} alt="Icono" />
      <Typography variant="h4">¡Oops! algo no salió bien.</Typography>
      <Typography variant="body1" color="grey" align="center">
        No hay de que preocuparse. Intenta cerrando el modal e intentando de
        nuevo.
      </Typography>
      {!hideSupportMessage && (
        <Typography variant="caption" color="grey">
          Si el error persiste{' '}
          <Link
            onClick={() => supportDialogVar({ open: true })}
            sx={{
              cursor: 'pointer',
            }}
          >
            intenta contactar a soporte.
          </Link>
        </Typography>
      )}
    </Stack>
  );
};
