import { GENDER_MAP } from '../../../../businessComponents/ControlledInputs/ControlledGenderInput/ControlledGenderInput';
import { MARITAL_STATUS_MAP } from '../../../../businessComponents/ControlledInputs/ControlledMaritalStatusInput/ControlledMaritalStatusInput';
import {
  COMPANY_SIGNER_ROW,
  EMPLOYEE_ROW,
  GROUP_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
} from '../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import type { AutocompleteOption } from '../../../../businessComponents/EmployeesFinder/EmployeesFinder.types';
import { FAILED_EMPLOYEE_FIELDS } from '../../../../businessComponents/InsertVariablesButton/InsertVariablesButton.constants';
import {
  calculateAge,
  calculateJobSeniority,
  formatDateToSpanishLocale,
} from '../../../../utils/utils';
import {
  belongsToSelectedGroups,
  belongsToSelectedWorkCenters,
  belongsToSelectedWorkTitle,
} from '../PdfDocuments/PdfDocuments.utils';
import type {
  GetCollectiveUsers,
  GetTotalsParmas,
  StructureToReplaceVariableProps,
} from './CreateDocumentDialog.types';
import { EmployeeRecordTagCategory } from '../../../Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.types';

export const getTotal = (data: GetTotalsParmas) => {
  const { users = [], includeCompanySigners = true } = data;
  return users.reduce((total, item) => {
    if (item.type === EMPLOYEE_ROW) {
      // return total + 1;
      const alreadyIncluded =
        belongsToSelectedWorkCenters(users, item) ||
        belongsToSelectedGroups(users, item) ||
        belongsToSelectedWorkTitle(users, item);
      if (!alreadyIncluded) {
        return total + 1;
      }
    }

    if (item?.type === COMPANY_SIGNER_ROW && includeCompanySigners) {
      return total + 1;
    }

    if (item.type === WORK_CENTER_ROW) {
      const employeeCount = item.employeeCount ?? 0;
      return total + employeeCount;
    }

    if (item.type === GROUP_ROW || item.type === WORK_TITLE_ROW) {
      const activeEmployees = item?.totalActiveEmployees ?? 0;
      const inactiveEmployees = item?.totalInactiveEmployees ?? 0;
      return total + inactiveEmployees + activeEmployees;
    }

    return total;
  }, 0);
};

export const updateMissingDataToVerifyEmployee = ({
  missingData = [],
  modifiedFields,
}) => {
  if (!missingData?.length) return [];
  // Creamos una copia del array de datos faltantes para no mutar el original
  const updatedMissingData = [...missingData];

  // Iteramos sobre cada campo en FAILED_EMPLOYEE_FIELDS
  Object.entries(FAILED_EMPLOYEE_FIELDS).forEach(([key, value]) => {
    // Verificamos si el campo está en la lista de datos faltantes
    const index = updatedMissingData.indexOf(key);
    if (index !== -1) {
      // Si el campo está en la lista de datos faltantes, verificamos si se ha modificado
      const dbField = value.db;
      if (modifiedFields[dbField]) {
        // Si se ha modificado, lo removemos de la lista de datos faltantes
        updatedMissingData.splice(index, 1);
      }
    }
  });

  return updatedMissingData;
};

export const getCollectiveUsers = (data: GetCollectiveUsers) => {
  const employeeIds = [];
  const workCenterIds = [];
  const groups = [];
  const workTitles = [];
  const { signers } = data;

  if (!signers.length) {
    return {
      employeeIds,
      workCenterIds,
      workTitles,
      groups,
    };
  }

  signers.forEach((signer) => {
    if (signer.type === EMPLOYEE_ROW) {
      employeeIds.push(signer._id);
    }

    if (signer.type === WORK_CENTER_ROW) {
      workCenterIds.push(signer._id);
    }

    if (signer.type === WORK_TITLE_ROW) {
      workTitles.push(signer.name);
    }

    if (signer.type === GROUP_ROW) {
      groups.push(signer.name);
    }
  });

  return {
    employeeIds,
    workCenterIds,
    workTitles,
    groups,
  };
};

export const buildStructureToReplaceVariables = (
  data: StructureToReplaceVariableProps,
) => {
  const { company, employee, countryCodes } = data;
  if (!employee) return null;
  const country = employee?.address?.country;
  const countryFound = countryCodes?.find((item) => item?.code === country);
  const structure = {
    company: {
      name: company?.name || company?.clientName || '',
    },
    user: {
      name: employee?.fullName || '',
      rfc: employee?.rfc || '',
      phone: employee?.phone || '',
      curp: employee?.curp || '',
      birthdate: employee?.birthdate
        ? formatDateToSpanishLocale(employee.birthdate)
        : '',
      age: employee?.birthdate
        ? calculateAge(new Date(employee.birthdate))
        : '',
      gender: GENDER_MAP[employee?.gender] || '',
      maritalStatus: MARITAL_STATUS_MAP[employee?.maritalStatus] || '',
      socialSecurityNumber: employee?.socialSecurityNumber || '',
      address: {
        country: countryFound?.name || '',
        zipCode: employee?.address?.zipCode || '',
        state: employee?.address?.state || '',
        city: employee?.address?.city || '',
        colony: employee?.address?.colony || '',
        street: employee?.address?.street || '',
        externalNumber: employee?.address?.externalNumber || '',
        internalNumber: employee?.address?.internalNumber || '',
      },
    },
    workSpace: {
      salary: employee?.salary || '',
      contractType: employee?.contractType || '',
      workDayType: employee?.workDayType || '',
      employmentStartDate: employee?.employmentStartDate
        ? formatDateToSpanishLocale(employee.employmentStartDate)
        : '',
      jobSeniority: employee?.employmentStartDate
        ? calculateJobSeniority(new Date(employee.employmentStartDate))
        : '',
      payrollId: employee?.payrollId || '',
      workTitle: employee?.workTitle || '',
    },
    workCenter: {
      name: employee?.workCenterName || '',
    },
    todayDate: formatDateToSpanishLocale(new Date()),
  };

  return structure;
};

export const getUserIdByType = (user: AutocompleteOption) => {
  if (
    user.type === EMPLOYEE_ROW ||
    user.type === WORK_CENTER_ROW ||
    user.type === COMPANY_SIGNER_ROW
  ) {
    return user._id;
  }

  return user.name;
};
export const verifyIfSelectedTemplateHasChanged = ({
  currTemplate,
  selectedTemplate,
}) => {
  if (!currTemplate) return true;
  const titleHasChanged = currTemplate?.title !== selectedTemplate?.title;
  if (titleHasChanged) return false;

  const contentHasChanged =
    currTemplate?.myEditor !== selectedTemplate?.htmlContent;
  if (contentHasChanged) return false;

  const currCategory = currTemplate?.category || '';
  const selectedCategory = selectedTemplate?.categories?.category || '';

  const categoriesHasChanged =
    currCategory?.toLowerCase() !== selectedCategory?.toLowerCase();
  if (categoriesHasChanged) return false;

  const currSubcategory = currTemplate?.subcategory || '';
  const selectedSubcategory = selectedTemplate?.categories?.subcategory || '';

  const subcategoriesHasChanged =
    currSubcategory?.toLowerCase() !== selectedSubcategory?.toLowerCase();
  if (subcategoriesHasChanged) return false;

  return true;
};

export type SanitizeCategoryProps = {
  category: { category: string; subcategory: string };
  categories: EmployeeRecordTagCategory[];
};

export const getSanitizeCategory = (data: SanitizeCategoryProps) => {
  const { category, categories } = data;
  if (!categories?.length || !category) return null;
  const currCategory = categories?.find((tag) => {
    if (tag.category) {
      return tag.category?.toLowerCase() === category?.category?.toLowerCase();
    }

    return null;
  });

  if (!currCategory) return null;

  const categoryId = currCategory?._id;
  const subCategories = currCategory?.subcategories ?? [];
  const subcategoryFound = subCategories?.find((sub) => {
    if (sub?.subcategory) {
      return (
        sub?.subcategory?.toLowerCase() === category?.subcategory?.toLowerCase()
      );
    }
    return null;
  });

  if (!subcategoryFound) return null;

  const subcategoryId = subcategoryFound?._id;
  const combinedId = `${categoryId}-${subcategoryId}`;

  return {
    category: currCategory?.category,
    categoryId,
    subcategory: subcategoryFound?.subcategory,
    subcategoryId,
    color: currCategory?.color,
    combinedId,
  };
};
