import { useController } from 'react-hook-form';
import { MenuItem, Stack } from '@mui/material';
import { IconButton, TextInput, Typography } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { setVideoAgreementPromptDialog } from '../../../containers/Settings/Preferences/VideoAgreementTemplates/VideoAgreementPromptDialog/VideoAgreementPromptDialog.vars';
import { ControlledDocumentActionInputProps } from './ControlledDocumentActionInput.types';
import {
  OPTIONS_NOT_SEND_TO_EMPLOYEES,
  OPTIONS_SEND_TO_EMPLOYEES,
} from './ControlledDocumentActionInput.constants';

export const ControlledDocumentActionInput = (
  props: ControlledDocumentActionInputProps,
) => {
  const {
    control,
    videoAgreementText,
    hideDontSendToEmployeeOptions = false,
    onVideoAgreementTextChange = () => {},
  } = props;
  const name = 'documentAction';
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control });

  const ACTIONS = [...OPTIONS_SEND_TO_EMPLOYEES];
  if (!hideDontSendToEmployeeOptions) {
    ACTIONS.push(...OPTIONS_NOT_SEND_TO_EMPLOYEES);
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1} width="100%">
      <TextInput
        name={name}
        label="Elige una acción"
        value={value || ''}
        onChange={(e) => {
          const inputValue = e.target.value;
          onChange(inputValue);
          if (inputValue === 'canBeSignedAndVideoAgreement') {
            setVideoAgreementPromptDialog({
              open: true,
              onContinue: onVideoAgreementTextChange,
            });
          } else {
            onVideoAgreementTextChange('');
          }
        }}
        onBlur={onBlur}
        select
        fullWidth
      >
        {ACTIONS.map((action) => (
          <MenuItem
            key={action.value}
            value={action.value}
            disabled={action.disabled}
          >
            <Stack direction="row" alignItems="center" gap="8px">
              {action.icon && <Icon icon={action.icon} width="24px" />}
              <Typography variant="body2">{action.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </TextInput>
      {value === 'canBeSignedAndVideoAgreement' && (
        <IconButton
          icon="settings_2_line"
          color="warning"
          onClick={() => {
            setVideoAgreementPromptDialog({
              open: true,
              videoAgreementText,
              onContinue: onVideoAgreementTextChange,
            });
          }}
        />
      )}
    </Stack>
  );
};
