import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { Block } from './Block';
import { EditDaysPerYearByMonth } from './EditDaysPerYearByMonth';
import { EditableBlock } from './EditableBlock';
import {
  DaysEarnedByMonth,
  DaysEarnedScheme,
  Month,
} from '../../holidaySchemes.types';
import { IconButton, TextInput } from '../../../../../../../newComponents';
import { Icon } from '../../../../../../../components/Icons/Icons';

function daysGranterPerYearTotal(days: DaysEarnedScheme['days']): number {
  if (typeof days === 'number') {
    return days;
  }

  return days.reduce((acc, month) => {
    if (typeof month === 'number') {
      return acc + month;
    }
    return acc + month.days;
  }, 0);
}

function defaultMonthsAssigned(lastMonthDays: number): DaysEarnedByMonth[] {
  return Array.from({ length: 12 }, (_, i) => ({
    month: i as Month,
    days: i === 11 ? lastMonthDays : 0,
  }));
}

export const YearEarnedRow: React.FC<{
  year: number;
  days: DaysEarnedScheme['days'];
  edit?: boolean;
  onUpdateDays: (days: DaysEarnedScheme['days']) => void;
  onRemove: () => void;
}> = ({ year, days, edit = false, onUpdateDays, onRemove }) => {
  const theme = useTheme();
  const daysAreDefinedByMonth = Array.isArray(days);
  const [showMonths, setShowMonths] = useState(daysAreDefinedByMonth);

  const onChangeEditDaysMode = () => {
    if (!daysAreDefinedByMonth) {
      onUpdateDays(defaultMonthsAssigned(days));
    }
    setShowMonths((prev) => !prev);
  };

  const onChangeDaysPerYear = (daysValue: string) => {
    let days: number;
    try {
      days = parseInt(daysValue, 10);
    } catch (e) {
      console.error(e);
      days = 1;
    }
    setShowMonths(false);
    onUpdateDays(days < 1 ? 1 : days);
  };

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{ borderTop: `1px solid ${theme.newPalette.divider}` }}
        className={'Block'}
        justifyContent={'center'}
      >
        <Block sx={!edit && { justifyContent: 'center' }}>
          <Stack direction={'row'} gap={1}>
            {edit && (
              <IconButton
                sx={{ padding: 0 }}
                size={'small'}
                icon={!showMonths ? 'arrow_right_s_line' : 'arrow_down_s_line'}
                onClick={onChangeEditDaysMode}
              />
            )}
            <EditableBlock
              value={`${year} año${year === 1 ? '' : 's'}`}
              onChange={() => {}}
            />
          </Stack>
        </Block>
        <Stack p={1}>
          <Icon
            fill={theme.newPalette.grey.grey500}
            icon={'arrow_right_long_line'}
            width={'18px'}
            height={'18px'}
          />
        </Stack>
        {!!edit ? (
          <Block sx={{ textAlign: 'center' }} onRemove={onRemove}>
            <TextInput
              placeholder={'Días'}
              value={
                daysAreDefinedByMonth ? daysGranterPerYearTotal(days) : days
              }
              onChange={(e) => onChangeDaysPerYear(e.target.value)}
              onBlur={(e) => {
                if (e.target.value === '') {
                  onChangeDaysPerYear('1');
                }
              }}
              sx={{ width: '80px', margin: '0 auto' }}
              inputProps={{
                type: 'number',
                min: 1,
                style: { textAlign: 'center' },
              }}
            />
          </Block>
        ) : (
          <Block
            sx={!edit && { justifyContent: 'center' }}
            title={`${days} días`}
          />
        )}
      </Stack>
      {showMonths && (
        <EditDaysPerYearByMonth
          value={days as DaysEarnedByMonth[]}
          onChange={(value) => {
            onUpdateDays(value);
          }}
        />
      )}
    </>
  );
};
