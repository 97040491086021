import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { YearEarnedRow } from './YearEarnedRow';
import { HolidaySchemeDaysGrantedHeader } from './HolidaySchemeDaysGrantedHeader';
import { useHolidaySchemeGranted } from './useHolidaySchemeGranted';
import {
  Alert,
  AlertTitle,
  Button,
  ConfirmDialog,
  Typography,
} from '../../../../../../../newComponents';
import { useScreenSize } from '../../../../../../../Hooks';
import { DaysEarnedScheme } from '../../holidaySchemes.types';

export const HolidaySchemeDaysGranted: React.FC<{
  edit?: boolean;
  scheme: DaysEarnedScheme[];
  onUpdateScheme: (scheme: DaysEarnedScheme[]) => void;
}> = ({ edit = false, scheme = [], onUpdateScheme }) => {
  const { isMobile } = useScreenSize();
  const theme = useTheme();
  const palette = theme.newPalette;

  const control = useHolidaySchemeGranted({ scheme, edit });
  const {
    states: { editableScheme, editMode },
    actions: {
      addYearDefinitionToScheme,
      updateDaysGrantedPerYear,
      removeLastYearDefinitionFromScheme,
    },
  } = control;

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const onCloseDialog = () => setConfirmDialogOpen(false);
  const onConfirm = () => {
    removeLastYearDefinitionFromScheme();
    onCloseDialog();
  };

  useEffect(() => {
    onUpdateScheme(editableScheme);
  }, [editableScheme, onUpdateScheme]);

  return (
    <>
      <Stack
        sx={{
          minWidth: '400px',
          borderRight: !isMobile ? `1px solid ${palette.divider}` : undefined,
        }}
        py={2}
        gap={1}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={2}
        >
          <Typography variant="body1">Configura los días otorgados</Typography>
          {editMode && (
            <Button
              variant={'outlined'}
              color={'default'}
              onClick={addYearDefinitionToScheme}
            >
              Agregar año
            </Button>
          )}
        </Stack>
        <Stack className="DayGranted_DayList">
          <HolidaySchemeDaysGrantedHeader />
          {editableScheme.map((scheme, i) => (
            <YearEarnedRow
              key={scheme.year}
              year={scheme.year}
              days={scheme.days}
              edit={editMode}
              onUpdateDays={(days) =>
                updateDaysGrantedPerYear(scheme.year, days)
              }
              onRemove={
                i === editableScheme.length - 1
                  ? () => setConfirmDialogOpen(true)
                  : undefined
              }
            />
          ))}
        </Stack>
      </Stack>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={onCloseDialog}
        icon="delete_bin_line"
        confirmLabel={'Eliminar'}
        cancelLabel={'Cancelar'}
        title={'Eliminar año'}
        severity="error"
        onCancel={onCloseDialog}
        onConfirm={onConfirm}
        DialogProps={{
          maxWidth: 'sm',
        }}
      >
        <Typography color={theme.newPalette.text.secondary} variant="body1">
          ¿Quieres eliminar el año?
        </Typography>
        <Alert variant="standard" severity="info">
          <AlertTitle>Info</AlertTitle>
          Este cambio solo se aplicará a los usuarios nuevos que agregues a este
          esquema.
        </Alert>
      </ConfirmDialog>
    </>
  );
};
