import React from 'react';
import { HolidayScheme } from '../../holidaySchemes.types';
import { Assignee } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/CreateHolidayAuthorizationTeamDialog.types';
import { RowBaseItem } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';

export type EditHolidaySchemeDialogFormType = HolidayScheme & {
  title: string;
  description: string;
  color: React.CSSProperties['color'];
  selectedScheme: HolidayScheme;
  assignedTo: Assignee[];
  assignedToErrors: RowBaseItem[];
  preloadedEmployees: RowBaseItem[];
};

export enum EditHolidaySchemeSteps {
  // eslint-disable-next-line no-unused-vars
  Title = 'Title',
  // eslint-disable-next-line no-unused-vars
  DaysGranted = 'DaysGranted',
  // eslint-disable-next-line no-unused-vars
  Employees = 'Employees',
}
