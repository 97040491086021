// @ts-check
import { TemplatePermissionDenied } from './TemplatePermissionDenied';
import { TemplateCreationDialog } from './TemplateCreationDialog';
import { TemplateUpdateDialog } from './TemplateUpdateDialog';
import { TemplateDeleteConfirmationDialog } from './TemplateDeleteConfirmationDialog';
import { usePermissionChecker, useScreenSize } from '../../../../Hooks';
import {
  ModulesAvailable,
  useBlockableActions,
} from '../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import TemplatesTable from '../../../MyCompany/Settings/Preferences/Templates/TemplatesTable/TemplatesTable.js';
import { createTemplateDialogVar } from '../../../MyCompany/Settings/Preferences/Templates/Templates.vars';

export const CreateTemplateContainer = () => {
  const { isMobile } = useScreenSize();
  const userHasPermissionToView = usePermissionChecker({
    permission: 'templates',
    action: 'read',
  });

  const userHasPermissionsToUpdate = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  const {
    onClick: documentsModuleIsBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS as ModulesAvailable);

  const handleCreateAction = () => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }
    createTemplateDialogVar({ open: true });
  };

  if (!userHasPermissionToView) {
    return <TemplatePermissionDenied />;
  }

  return (
    <>
      <Stack spacing={5}>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Stack spacing={1} mb={1}>
            <Typography variant="h5">Plantillas</Typography>
            <Typography variant="body1">
              Las plantillas te ayudan a diseñar documentos con un aspecto y
              formato profesional de acuerdo a las necesidades y lineamientos de
              tu empresa.
            </Typography>
          </Stack>
          <Button
            sx={{ minWidth: '170px' }}
            variant="contained"
            size={isMobile ? 'medium' : 'large'}
            onClick={handleCreateAction}
            disabled={!userHasPermissionsToUpdate}
            tooltipText={
              userHasPermissionsToUpdate
                ? ''
                : 'No tienes permisos para esta acción'
            }
            tooltipArrow
            tooltipPlacement="top"
            fullWidth={isMobile}
          >
            Crear plantilla
          </Button>
        </Stack>
        <TemplatesTable />
      </Stack>

      <TemplateCreationDialog />
      <TemplateUpdateDialog />
      <TemplateDeleteConfirmationDialog />
    </>
  );
};
