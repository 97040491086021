import { useReactiveVar } from '@apollo/client';
import { Stack, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { IDFrontIcon } from '../IdentityVerificationDialogAssets/IDFrontIcon';
import { Alert } from '../../../../newComponents/Alert';
import { AlertTitle } from '../../../../newComponents/AlertTitle';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import { CameraNative } from '../../../NativeCamera';
import { useVerifyFrontId } from '../IdentityVerificationDialogHooks/useVerifyFrontId';
import { ID_TYPES } from '../IdentityVerificationDialog.constants';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';

const TEXT_MAP = {
  [ID_TYPES.INE]: 'INE',
  [ID_TYPES.PASSPORT]: 'Pasaporte',
  [ID_TYPES.OTHER]: 'Identificación',
};

export function CaptureIDFront() {
  const { errorMessage, handleVerifyFrontId } = useVerifyFrontId();
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const { selectedIDType, IDOptions } = identityVerificationDialog;
  const { isMobile } = useScreenSize();
  const facingMode = 'environment';

  const handleBack = () => {
    setIdentityVerificationDialogVar({ selectedIDType: '' });
  };

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          maxWidth="800px"
          minHeight={isMobile ? '100%' : '65vh'}
          alignItems="center"
          justifyContent="center"
          margin="0 auto"
          gap={3}
        >
          <IDFrontIcon height={80} />
          <Stack textAlign="center" alignItems="center" gap={2}>
            <Typography variant="h3">
              Captura el frente de tu
              {` ${TEXT_MAP[selectedIDType]}`}
            </Typography>
            <Typography variant="body1" color="grey" maxWidth="400px">
              Asegúrate de que tus datos sean visibles.
            </Typography>
          </Stack>
          {!isMobile && (
            <Stack direction="row" gap={2}>
              {IDOptions.length > 1 && (
                <Button
                  variant="outlined"
                  color="default"
                  size="large"
                  onClick={handleBack}
                >
                  Atrás
                </Button>
              )}
              <CameraNative
                onCapture={handleVerifyFrontId}
                facingMode={facingMode}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component="span"
                >
                  Tomar foto
                </Button>
              </CameraNative>
            </Stack>
          )}
          {errorMessage !== '' && (
            <Stack width="100%">
              <Alert icon={<Icon icon="information_line" />} severity="error">
                <AlertTitle>Vuelve a intentarlo</AlertTitle>
                {errorMessage}
              </Alert>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        {isMobile ? (
          <Stack gap={2} width="100%">
            {IDOptions.length > 1 && (
              <Button
                variant="outlined"
                color="default"
                onClick={handleBack}
                fullWidth
              >
                Atrás
              </Button>
            )}
            <CameraNative
              onCapture={handleVerifyFrontId}
              facingMode={facingMode}
            >
              <Button
                variant="contained"
                color="primary"
                component="span"
                fullWidth
              >
                Tomar foto
              </Button>
            </CameraNative>
          </Stack>
        ) : (
          <Stack height="40px"></Stack>
        )}
      </DialogActions>
    </>
  );
}
