import { useReactiveVar } from '@apollo/client';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { ThemeProvider, View, Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './LivenessStyles.css';
import { useGetLivenessSession } from './LivenessDialogHooks';
import {
  livenessDialogVar,
  resetLivenessDialogVar,
} from './LivenessDialog.vars';
import {
  ES_DICTIONARY,
  LIVENESS_ERROR_MESSAGES,
} from './LivenessDialog.constants';

export function LivenessContent() {
  const { sessionId, onFail } = useReactiveVar(livenessDialogVar);
  const { handleGetLivenessSession } = useGetLivenessSession();

  return (
    <ThemeProvider>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
        <View
          as="div"
          maxHeight="760px"
          height="760px"
          width="900px"
          maxWidth="900px"
        >
          <FaceLivenessDetector
            disableStartScreen
            sessionId={sessionId}
            region="us-east-1"
            onAnalysisComplete={async () => {
              await handleGetLivenessSession();
            }}
            onError={(error) => {
              resetLivenessDialogVar();
              const errorMessage =
                LIVENESS_ERROR_MESSAGES[error?.error?.name] ||
                'Ocurrió un error al realizar la prueba de vida';
              onFail(errorMessage);
            }}
            displayText={ES_DICTIONARY}
          />
        </View>
      </Flex>
    </ThemeProvider>
  );
}
