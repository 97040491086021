import { Fragment, useEffect, useState } from 'react';
import { InputAdornment, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  Chip,
  Switch,
  FormControlLabel,
  TextInput,
  Typography,
} from '../../../../../newComponents';
import { Divider } from '../../../../Landing/Development/Components/Divider/Divider';
import { useScreenSize } from '../../../../../Hooks';
import { EmployeeRecordTag } from '../../../../../layoutComponents/EmployeeRecordTag/EmployeeRecordTag';
import type { CreateDocumentSettingsFormProps } from '../CreateDocumentDialog.types';
import { currentCompanyVar } from '../../../../../cache.reactiveVars';
import { globalTotalsVar } from '../CreateDocumentDialog.vars';
import { PreviewTemplateButton } from '../../../../../businessComponents/PreviewTemplateButton';
import { getUserIdByType } from '../CreateDocumentDialog.helpers';
import { ValiditySelector } from '../../Common/ValiditySelector';
import { ControlledDocumentActionInput } from '../../../../../businessComponents/ControlledInputs';

export const CreateDocumentSettingsForm = (
  props: CreateDocumentSettingsFormProps,
) => {
  const { isMobile } = useScreenSize();
  const company = useReactiveVar(currentCompanyVar);
  const globalTotal = useReactiveVar(globalTotalsVar);

  const [validity, setValidity] = useState(null);
  const [currentSigners, setCurrentSigners] = useState([]);

  const employeeRecordsTags = company.configs?.employeeRecordsTags ?? [];
  const { form } = props;
  const [
    categories,
    title,
    errors,
    scheduledFor,
    myEditor,
    signers,
    documentAction,
    videoAgreementText,
  ] = form.watch([
    'categories',
    'title',
    'verifyErrors',
    'scheduledFor',
    'myEditor',
    'signers',
    'documentAction',
    'videoAgreementText',
  ]);

  const signable =
    documentAction === 'canBeSigned' ||
    documentAction === 'canBeSignedAndVideoAgreement';

  const categoryFound = employeeRecordsTags?.find(
    (tag) => tag?.category === categories?.category,
  );

  const handleSetValidity = (value: Date | null) => {
    setValidity(value);
    form.setValue('validity', value ? value : null);
  };

  const handleScheduledFor = (value: Date | null) => {
    form.setValue('scheduledFor', value ? value : null);
  };

  useEffect(() => {
    if (!signable) {
      form.setValue('hideable', false);
      form.setValue('highPriority', false);
    }
  }, [signable, form]);

  useEffect(() => {
    let excludedIds = [];
    if (errors?.length) {
      excludedIds = errors.map((item) => item.userVerified?.user?._id);
    }

    if (excludedIds?.length) {
      const signersFiltered = signers?.filter(
        (signer) => !excludedIds.includes(getUserIdByType(signer)),
      );
      setCurrentSigners(signersFiltered);
    } else {
      setCurrentSigners(signers);
    }
  }, [signers, errors]);

  const total = (globalTotal.employees ?? 0) - (errors?.length ?? 0);

  return (
    <Fragment>
      <Stack width="100%" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon icon="ic_document" width="40px" height="60px" />
          <PreviewTemplateButton
            html={myEditor}
            signers={currentSigners}
            shouldSearchSingleUser={total === 1}
          />
        </Stack>
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <TextInput
        label="Tipo de documento"
        variant="outlined"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <EmployeeRecordTag
                employeeRecordTag={{
                  category: categories.category,
                  subcategory: categories.subcategory,
                  color: categoryFound?.color,
                }}
                columnWidth={400}
              />
            </InputAdornment>
          ),
        }}
        disabled
      />
      <Stack width="100%" spacing={2}>
        <Typography variant="subtitle2">Configuraciones</Typography>
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <ValiditySelector
            fullWidth
            value={validity}
            onChange={handleSetValidity}
            label="Vigencia"
            undefinedLabel="Elegir fecha"
            placeholder="Elegir fecha"
            size={isMobile ? 'small' : 'medium'}
            minDate={new Date()}
          />
          {scheduledFor && (
            <ValiditySelector
              fullWidth
              value={scheduledFor}
              onChange={handleScheduledFor}
              label="Envío programado"
              undefinedLabel="Programado para"
              dateFormat="d MMM yyyy HH:mm"
              size={isMobile ? 'small' : 'medium'}
              includeTime={true}
              minDate={new Date()}
            />
          )}
        </Stack>
        <Typography variant="subtitle2">Acciones</Typography>
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <ControlledDocumentActionInput
            control={form.control}
            videoAgreementText={videoAgreementText}
            hideDontSendToEmployeeOptions
            onVideoAgreementTextChange={(newVideoAgreementText) => {
              form.setValue('videoAgreementText', newVideoAgreementText);
            }}
          />
        </Stack>
        <Stack width="100%" spacing={1.5}>
          <Controller
            name="hideable"
            control={form.control}
            render={({ field: { onChange, value } }) => {
              return (
                <Stack>
                  <FormControlLabel
                    value={value}
                    onChange={onChange}
                    control={
                      <Switch
                        color="primary"
                        checked={value}
                        disabled={!signable}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Ocultar tras firma del empleado
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <Typography variant="caption" color="text.secondary">
                    Al activar esta opción este documento se ocultará únicamente
                    al empleado.
                  </Typography>
                </Stack>
              );
            }}
          />
          <Controller
            name="highPriority"
            control={form.control}
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <Stack>
                  <FormControlLabel
                    value={value}
                    onChange={onChange}
                    control={
                      <Switch
                        color="primary"
                        checked={value}
                        disabled={!signable}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Documento de alta prioridad
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <Typography variant="caption" color="text.secondary">
                    Este documento aparecerá tras inicio de sesión del empleado
                    y solo podrá continuar hasta que lo haya firmado.
                  </Typography>
                </Stack>
              );
            }}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1} width="100%">
        <Typography variant="subtitle2">Usuarios asignados</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="caption" color="text.secondary">
            Empleados
          </Typography>
          <Chip
            label={`${total === 1 ? `${total} firmante` : `${total} firmantes`} `}
            color="primary"
            variant="soft"
            size="small"
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="caption" color="text.secondary">
            Firmantes de empresa
          </Typography>
          <Chip
            label={`${globalTotal?.companySigners === 1 ? `${globalTotal?.companySigners} firmante` : `${globalTotal?.companySigners} firmantes`} `}
            color="success"
            variant="soft"
            size="small"
          />
        </Stack>
      </Stack>
    </Fragment>
  );
};
