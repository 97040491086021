import React from 'react';
import { useTheme, SxProps, Theme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { newPaletteType } from '../../../../../../../theme';
import { IconButton, Typography } from '../../../../../../../newComponents';
import './Block.css';

type BlockProps = React.PropsWithChildren<{
  color?: 'success' | 'warning' | 'error' | 'info';
  title?: string;
  // MUI sx prop type
  sx?: SxProps<Theme>;
  onRemove?: () => void;
}>;

export const Block: React.FC<BlockProps> = ({
  color,
  children,
  title,
  sx,
  onRemove,
}) => {
  const theme = useTheme();
  const palette: newPaletteType = theme.newPalette;
  const allBlockSx = {
    success: {
      backgroundColor: palette.success.lighter,
      color: palette.success.dark,
    },
    warning: {
      backgroundColor: palette.warning.lighter,
      color: palette.warning.dark,
    },
  };

  const currentSx = allBlockSx[color] || {};

  return (
    <Stack
      p={2}
      sx={{
        backgroundColor: currentSx.backgroundColor,
        flex: '1',
        pr: onRemove ? undefined : '34px',
        ...sx,
      }}
      direction={'row'}
      alignItems={'center'}
    >
      {children || (
        <Typography
          variant={'body2'}
          color={currentSx.color}
          textAlign={'center'}
        >
          {title}
        </Typography>
      )}
      {onRemove && (
        <IconButton
          size={'small'}
          icon={'delete_bin_line'}
          sx={{ height: '30px', width: '30px' }}
          onClick={onRemove}
          color={'error'}
          className={'Block_RemoveButton'}
        />
      )}
    </Stack>
  );
};
