import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { Block } from './Block';
import { TextInput } from '../../../../../../../newComponents';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { DaysEarnedByMonth } from '../../holidaySchemes.types';

type EditDaysPerYearByMonthProps = {
  value: DaysEarnedByMonth[];
  onChange: (value: DaysEarnedByMonth[]) => void;
};

export const EditDaysPerYearByMonth: React.FC<EditDaysPerYearByMonthProps> = ({
  value: monthsToShow = [],
  onChange,
}) => {
  const theme = useTheme();

  const onChangeMonthDays = (monthIndex: number, days: number) => {
    const daysToSet = days < 1 ? 1 : days;
    const newMonths = monthsToShow.map((month, index) => {
      if (index === monthIndex) {
        return { ...month, days: daysToSet };
      }
      return month;
    });
    onChange(newMonths);
  };

  return (
    <>
      {monthsToShow.map(({ month, days }) => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{ borderTop: `1px solid ${theme.newPalette.divider}`, ml: 4 }}
          key={month}
        >
          <Block title={`Mes ${month + 1}`} />
          <Stack p={1}>
            <Icon
              fill={theme.newPalette.grey.grey500}
              icon={'arrow_right_long_line'}
              width={'18px'}
              height={'18px'}
            />
          </Stack>
          <Block sx={{ textAlign: 'center' }}>
            <TextInput
              placeholder={'Días'}
              value={days}
              sx={{ width: '80px', margin: '0 auto' }}
              inputProps={{
                type: 'number',
                min: 1,
                style: { textAlign: 'center' },
              }}
              onChange={(e) => onChangeMonthDays(month, Number(e.target.value))}
            />
          </Block>
        </Stack>
      ))}
    </>
  );
};
