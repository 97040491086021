import { useReactiveVar } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { LIVENESS_ERROR_MESSAGES } from '../LivenessDialog.constants';
import {
  livenessDialogVar,
  resetLivenessDialogVar,
} from '../LivenessDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useGetLivenessSession() {
  const { verificationId, sessionId, onSuccess, onFail } =
    useReactiveVar(livenessDialogVar);

  const handleGetLivenessSession = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo resultados del reconocimiento facial...',
      });

      const response = await sifeEndpoints.getLivenessSession.get(
        { params: { verificationId } },
        { sessionId },
      );

      globalBackdropVar({ open: false });

      if (response.status === 200) {
        const { isLive, imageUrl } = response.data.data;

        if (isLive && imageUrl) {
          onSuccess(imageUrl);
          return;
        }

        onFail(LIVENESS_ERROR_MESSAGES.NOT_LIVE);
        return;
      }

      onFail(
        'Ocurrió un error al obtener los resultados del reconocimiento facial',
      );
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      resetLivenessDialogVar();
      globalBackdropVar({ open: false });
    }
  };

  return { handleGetLivenessSession };
}
