import { useEffect, useState } from 'react';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../newComponents/Button';
// import { IconButton } from '../../../../../newComponents/IconButton';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { DocumentPreviewDialog } from '../../DocumentCreator/DocumentPreviewDialog';
import { getHmtlFromCreator } from '../../DocumentCreator/helper';
import { TemplateCreationSettingsForm } from './TemplateCreationSettingsForm';
import { TemplateCreationEditorForm } from './TemplateCreationEditorForm';
import { usePermissionChecker /* useScreenSize */ } from '../../../../../Hooks';
import { useTemplateCreation } from '../TemplateHooks';
import { TemplateUnsavedChangesDialog } from '../TemplateUnsavedChangesDialog';
import {
  DEFAULT_AUTHORIZATIONS,
  DEFAULT_TEMPLATE_EDITOR,
  DEFAULT_TEMPLATE_SETTINGS,
} from './TemplateCreationDialog.constants';
import {
  confirmAuthorizersDialogVar,
  createTemplateDialogVar,
  resetConfirmAuthorizersDialogVar,
  resetCreateTemplateDialogVar,
  resetTemplateItemVar,
} from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars';
import { TemplateCreationAuthorizersForm } from '../../../../MyCompany/Settings/Preferences/Templates/TemplateAuthorizers/TemplateCreationAuthorizersForm.js';
import { ConfirmAuthorizersDialog } from '../../../../MyCompany/Settings/Preferences/Templates/TemplateAuthorizers/ConfirmAuthorizersDialog.js';
import { PreviewTemplateButton } from '../../../../../businessComponents/PreviewTemplateButton';
interface Template {
  title: string;
  category: string;
  subcategory: string;
  myEditor: string;
}

export const TemplateCreationDialog = ({
  template = null,
}: {
  template?: Template | null;
}) => {
  const theme = useTheme();
  const { open } = useReactiveVar<{ open: boolean }>(createTemplateDialogVar);
  const [step, setStep] = useState(0);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
  const userHasPermissionsToUpdate = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });
  const { handleCreateTemplate } = useTemplateCreation();

  const templateSettingsForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_TEMPLATE_SETTINGS,
  });

  const templateEditorForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_TEMPLATE_EDITOR,
    resolver: (data) => {
      if (!data.myEditor) {
        const message = 'Campo requerido';
        const errors = { myEditor: { type: 'required', message } };
        return { values: data.myEditor, errors: errors };
      }
      return { values: data.myEditor, errors: {} };
    },
  });

  const authorizationsForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_AUTHORIZATIONS,
  });

  useEffect(() => {
    if (template) {
      templateSettingsForm.reset({
        ...DEFAULT_TEMPLATE_SETTINGS,
        title: template?.title,
        category: template?.category,
        subcategory: template?.subcategory,
      });
      templateEditorForm.reset({
        ...DEFAULT_TEMPLATE_EDITOR,
        myEditor: template?.myEditor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, open]);

  const formWatch = templateEditorForm.watch('myEditor');

  const templateSettingsFormIsValid = templateSettingsForm.formState.isValid;
  const templateEditorFormIsValid = templateEditorForm.formState.isValid;
  const isTemplateSettingsFormDirty = templateSettingsForm.formState.isDirty;
  const isTemplateEditorFormDirty = templateEditorForm.formState.isDirty;

  const handleCloseDialog = (force?: boolean) => {
    if (force || (!isTemplateSettingsFormDirty && !isTemplateEditorFormDirty)) {
      templateSettingsForm.reset(DEFAULT_TEMPLATE_SETTINGS);
      templateEditorForm.reset(DEFAULT_TEMPLATE_EDITOR);
      resetTemplateItemVar();
      setShowConfirmCloseDialog(false);
      setStep(0);
      resetCreateTemplateDialogVar();
      return;
    }

    if (isTemplateSettingsFormDirty || isTemplateEditorFormDirty) {
      setShowConfirmCloseDialog(true);
    }
  };

  const handleTemplateCreation = () => {
    handleCreateTemplate({
      data: {
        ...templateSettingsForm.getValues(),
        ...templateEditorForm.getValues(),
        ...authorizationsForm.getValues(),
      },
      onSuccess: () => {
        handleCloseDialog(true);
        resetConfirmAuthorizersDialogVar();
      },
    });
  };

  const openConfirmDialog = () => {
    confirmAuthorizersDialogVar({ open: true });
  };

  const createHtml = () => {
    const content = templateEditorForm.getValues().myEditor;
    return getHmtlFromCreator({ data: {}, content: content, preview: true });
  };

  const getMaxWidth = () => {
    if (step === 0) return 'xs';
    if (step === 1) return 'xl';
    if (step === 2) return 'sm';
  };

  const authorizersWatch = authorizationsForm.watch('authorizers');

  return (
    <>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth={getMaxWidth()}
        onClose={() => handleCloseDialog()}
        showCloseButton={false}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 3,
          }}
          component="div"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon
              fill={theme.customPalette.status.inactive_gray}
              icon="close_line"
              height="26px"
              onClick={() => handleCloseDialog(Boolean(template))}
              style={{ cursor: 'pointer' }}
            />
            {step === 2 ? 'Asignar autorizadores' : 'Crear plantilla'}
          </Stack>
          {step === 1 && (
            <Stack>
              <PreviewTemplateButton html={formWatch} />
            </Stack>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {step === 0 && (
            <TemplateCreationSettingsForm form={templateSettingsForm} />
          )}
          {step === 1 && (
            <TemplateCreationEditorForm form={templateEditorForm} />
          )}
          {step === 2 && (
            <TemplateCreationAuthorizersForm form={authorizationsForm} />
          )}
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          {step === 0 && (
            <Button
              variant="contained"
              size="large"
              disabled={!templateSettingsFormIsValid}
              onClick={templateSettingsForm.handleSubmit(() => {
                setStep(template ? 2 : 1);
              })}
            >
              Siguiente
            </Button>
          )}
          {step === 1 && (
            <>
              <Button
                variant="outlined"
                size="large"
                color="default"
                onClick={() => setStep(0)}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={!templateEditorFormIsValid}
                onClick={() => setStep(2)}
              >
                Siguiente
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <Button
                variant="outlined"
                size="large"
                color="default"
                onClick={() => setStep(1)}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={
                  !templateEditorFormIsValid || !userHasPermissionsToUpdate
                }
                onClick={
                  authorizersWatch?.length
                    ? templateEditorForm.handleSubmit(handleTemplateCreation)
                    : openConfirmDialog
                }
              >
                {authorizersWatch?.length ? 'Finalizar' : 'Omitir'}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <TemplateUnsavedChangesDialog
        showDialog={showConfirmCloseDialog}
        closeDialog={() => setShowConfirmCloseDialog(false)}
        onConfirm={() => handleCloseDialog(true)}
      />
      <DocumentPreviewDialog
        open={showPreviewDialog}
        setOpen={setShowPreviewDialog}
        createHtml={createHtml}
      />

      <ConfirmAuthorizersDialog
        onConfirm={templateEditorForm.handleSubmit(handleTemplateCreation)}
      />
    </>
  );
};
