// @ts-check
import React, { useMemo } from 'react';
import { Dialog as MuiDialog, Slide } from '@mui/material';
import { styled as MuiStyled } from '@mui/system';
import { useScreenSize } from '../../Hooks';

const TransitionComponent = ({ direction }) => {
  const TransitionElement = React.forwardRef(
    /**
     *
     * @param {import('@mui/material/transitions').TransitionProps & {children: import('react').ReactElement<any,any>}} props
     * @param {import('react').Ref<unknown>} ref
     */
    function Transition(props, ref) {
      return <Slide direction={direction} ref={ref} {...props} />;
    },
  );
  return TransitionElement;
};

/**
 * Not needed right now, this will only take left
 * @returns {{sx: import("@mui/system").SxProps<import("@mui/system").Theme>}}
 */
const paperStyle = () => ({
  sx: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 0,
    minHeight: '100%',
    height: '100%',
  },
});

/**
 * @param {import('./Dialog.types').DialogProps} props
 * @returns {React.JSX.Element}
 */
export const Dialog = (props) => {
  const { slideMode, slideTimeout, slideDirection, PaperProps, ...restProps } =
    props;
  const { isMobile } = useScreenSize();

  const fullScreen = props.fullScreen || isMobile;

  const dialogStyle = {
    '.MuiDialog-paper': {
      borderRadius: fullScreen ? '0 !important' : '2 !important',
      borderTopRightRadius: slideMode ? '0 !important' : '2 !important',
      borderBottomRightRadius: slideMode ? '0 !important' : '2 !important',
    },
  };
  const Transition = useMemo(
    () =>
      TransitionComponent({
        direction: slideDirection || 'left',
      }),
    [slideDirection],
  );
  return (
    // @ts-ignore
    <MuiDialog
      transitionDuration={slideTimeout || 400}
      {...(props.slideMode
        ? {
            TransitionComponent: Transition,
            PaperProps: {
              ...PaperProps,
              sx: {
                ...(PaperProps?.sx || {}),
                ...paperStyle().sx,
              },
            },
          }
        : { PaperProps })}
      disableEscapeKeyDown={props.disableEscapeKeyDown || false}
      fullScreen={fullScreen}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth || 'lg'}
      sx={{ ...dialogStyle, ...props.sx }}
      {...restProps}
    />
  );
};

// @ts-ignore
export const ImageDialog = MuiStyled('img')((props) => {
  return {
    maxWidth: props.width || '480px',
    maxHeight: props.height || '360px',
    objectFit: 'cover',
    ...props,
  };
});
