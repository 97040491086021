import { useEffect, useState } from 'react';
import type { HolidayScheme } from '../../holidaySchemes.types';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  DELETE_EMPLOYEES_FROM_HOLIDAY_SCHEME,
  GET_COMPANY_SCHEMES,
  GET_EMPLOYEES_BY_HOLIDAY_SCHEME,
} from '../../holidaySchemes.gql';
import { RowBaseItem } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { AutocompleteOption } from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import {
  globalBackdropVar,
  globalSnackbarVar,
  globalSnackbarVarGenericError,
} from '../../../../../../../cache.reactiveVars';

export const useEHSEmployeesActions = ({
  preselectedScheme,
  onDeleteRows,
}: {
  preselectedScheme?: HolidayScheme;
  onDeleteRows?: () => void;
}) => {
  const [page, setPage] = useState<number>(1);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [tableTab, setTableTab] = useState<'success' | 'error'>('success');
  const [errorRows, setErrorRows] = useState<RowBaseItem[]>([]);

  // this will hold the rows of the employees
  // this should be the sum of the rows selected here and the rows preloaded on preselectedScheme
  const [rows, setRows] = useState<RowBaseItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<RowBaseItem[]>([]);

  const [preloadedEmployeesRows, setPreloadedEmployeesRows] = useState<
    RowBaseItem[]
  >([]);
  const [preloadedEmployeesTotal, setPreloadedEmployeesTotal] = useState(0);
  // these are the rows that the user added manually
  const [userAddedRows, setUserAddedRows] = useState<RowBaseItem[]>([]);

  const [getEmployeesByHolidayScheme] = useLazyQuery(
    GET_EMPLOYEES_BY_HOLIDAY_SCHEME,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const employees = data?.getEmployeesByHolidayScheme?.employees || [];
        const totalLoaded = data?.getEmployeesByHolidayScheme?.total || 0;
        setPreloadedEmployeesTotal(totalLoaded);

        const employeesAsRows = employees.map((employee) => ({
          ...employee,
          fullName: employee.name,
          type: 'Employee',
          alreadyAssigned: true,
        }));
        setPreloadedEmployeesRows(employeesAsRows);
      },
    },
  );
  const [deleteEmployeesFromHolidayScheme] = useMutation(
    DELETE_EMPLOYEES_FROM_HOLIDAY_SCHEME,
  );
  const client = useApolloClient();

  // ========================= actions functions
  const handleLoadMore = () => {
    const pageSize = 20;
    if (pageSize * page < preloadedEmployeesTotal) {
      setPage((prev) => {
        getEmployeesByHolidayScheme({
          variables: {
            input: { holidaySchemeId: preselectedScheme._id, page: prev + 1 },
          },
        });
        return prev + 1;
      });
    }
  };

  const addRow = (row: AutocompleteOption) => {
    // The ALL_EMPLOYEES button is not in the figma, but I bet it'll be added :)
    const rowsWithoutAllEmployees = userAddedRows.filter(
      (row) => row.type !== 'ALL_EMPLOYEES',
    );
    setUserAddedRows([...rowsWithoutAllEmployees, row]);
  };

  const deleteRows = async (items: RowBaseItem[]) => {
    const itemsIds = items.map((item) => item._id);
    const [itemsToDeleteOnBackEnd, userAddedItemsToDelete] = items.reduce(
      (acc, item) => {
        if (item.alreadyAssigned) {
          acc[0].push(item);
        } else {
          acc[1].push(item);
        }
        return acc;
      },
      [[], []] as [RowBaseItem[], RowBaseItem[]],
    );

    try {
      globalBackdropVar({
        clickable: false,
        open: true,
        text: 'Eliminando empleados...',
      });
      const { data } = await deleteEmployeesFromHolidayScheme({
        variables: {
          input: {
            holidaySchemeId: preselectedScheme._id,
            employeesIds: itemsToDeleteOnBackEnd.map((item) => item._id),
          },
        },
      });
      if (data?.deleteEmployeesFromHolidayScheme?.__typename !== 'Success') {
        globalSnackbarVar({
          show: true,
          message:
            data?.deleteEmployeesFromHolidayScheme?.message ||
            'Hubo un error al eliminar el empleado. Contacte a Sora',
          severity: 'error',
        });
      }
      // reset the pagination
      setPage(1);
      // reset local cache in order to not show deleted employees
      setPreloadedEmployeesRows([]);
      setPreloadedEmployeesTotal(0);

      // set the rows to empty to force the rerender of Table component,
      // this will make that table content will start from the top, otherwise
      // the table will keep the scroll position and the user will not see the first results
      setRows([]);

      // invalidate the cache of the employees
      client.cache.evict({
        fieldName: 'getEmployeesByHolidayScheme',
        broadcast: false,
      });
      await getEmployeesByHolidayScheme({
        variables: { input: { holidaySchemeId: preselectedScheme._id, page } },
      });
      await client.refetchQueries({ include: [GET_COMPANY_SCHEMES] });
      onDeleteRows?.();
    } catch (e) {
      console.error(e);
      globalSnackbarVarGenericError();
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
    const userAddedItemsToDeleteIds = userAddedItemsToDelete.map((r) => r._id);
    // removing from rows
    setUserAddedRows(
      userAddedRows.filter((r) => !userAddedItemsToDeleteIds.includes(r._id)),
    );
    setSelectedRows(selectedRows.filter((r) => !itemsIds.includes(r._id)));
  };

  const handleCheckAllRows = (checked: boolean) => {
    setIsCheckedAll(checked);
    if (checked) {
      setSelectedRows(tableTab === 'success' ? rows : errorRows);
    } else {
      setSelectedRows([]);
    }
  };

  // ========================= useEffects

  // loads the employees of the preselected scheme on mount
  useEffect(() => {
    if (preselectedScheme) {
      getEmployeesByHolidayScheme({
        variables: {
          input: { holidaySchemeId: preselectedScheme._id, page: 1 },
        },
      });
    }
  }, [getEmployeesByHolidayScheme, preselectedScheme]);

  // updates the rows  when the preloadedEmployeesRows or the userAddedRows change
  useEffect(() => {
    const rowsToSet = [...preloadedEmployeesRows, ...userAddedRows];
    setRows(rowsToSet);
  }, [preloadedEmployeesRows, userAddedRows]);

  return {
    states: {
      page,
      setPage,
      isCheckedAll,
      setIsCheckedAll,
      tableTab,
      setTableTab,
      errorRows,
      setErrorRows,
      rows,
      setRows,
      selectedRows,
      setSelectedRows,
      preloadedEmployeesRows,
      setPreloadedEmployeesRows,
      userAddedRows,
      setUserAddedRows,
      preloadedEmployeesTotal,
      setPreloadedEmployeesTotal,
    },
    actions: {
      handleLoadMore,
      addRow,
      deleteRows,
      handleCheckAllRows,
    },
  };
};
