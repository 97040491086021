import { Stack } from '@mui/material';
import React from 'react';

export interface TableHeaderDefaultFiltersWrapperProps {
  children: React.ReactNode;
}
export const TableHeaderDefaultFiltersWrapper = (
  props: TableHeaderDefaultFiltersWrapperProps,
) => {
  const { children } = props;
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      gap={1}
      flexGrow={1}
      width={{
        xs: '100%',
        md: 'auto',
      }}
    >
      {children}
    </Stack>
  );
};
