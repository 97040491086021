import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  CHSFormType,
  CreateHolidaySchemeSteps,
} from './CreateHolidaySchemeDialog.types';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions } from '@mui/material';
import { Button } from '../../../../../../../newComponents';
import {
  globalBackdropVar,
  globalSnackbarVar,
  globalSnackbarVarGenericError,
} from '../../../../../../../cache.reactiveVars';
import { CHSEditScheme } from './CHSEditScheme';
import { CHSEmployees } from './CHSEmployees';
import { EditTitle } from '../HolidaySchemeEditComponents/EditTitle';
import { JSX } from 'react/jsx-runtime';
import {
  CREATE_HOLIDAY_SCHEME,
  GET_COMPANY_SCHEMES,
} from '../../holidaySchemes.gql';
import { useApolloClient, useMutation } from '@apollo/client';
import { HolidaySchemeAdapter } from '../../utils/HolidaySchemeAdapter';

export const createHolidaySchemeFormErrorMessages = {
  name: { required: 'Ingresa un nombre' },
  approveTemplateId: { required: 'Este campo es requerido' },
};

type CHSFormProps = {
  form: UseFormReturn<CHSFormType>;
  handleClose: () => void;
  step: CreateHolidaySchemeSteps;
  setStep: (step: CreateHolidaySchemeSteps) => void;
  handlePrev: () => void;
};
export const CHSForm: React.FC<CHSFormProps> = ({
  form,
  handleClose,
  step,
  setStep,
  handlePrev,
}) => {
  const { trigger } = form;
  const [createHolidaySchemeMutation] = useMutation(CREATE_HOLIDAY_SCHEME);
  const client = useApolloClient();

  const onSubmit = async () => {
    const values = form.getValues();
    const assignedTo = form.getValues('assignedTo');
    let employeeIds = assignedTo
      .filter((a) => a.type === 'Employee')
      .map((a) => a._id);
    let groups = assignedTo
      .filter((a) => a.type === 'Group')
      .map((g) => g.name);
    let workTitles = assignedTo
      .filter((a) => a.type === 'WorkTitle')
      .map((w) => w.name);
    let workCenterIds = assignedTo
      .filter((a) => a.type === 'WorkCenter')
      .map((w) => w._id);

    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Creando esquema de vacaciones...',
    });
    try {
      const { data } = await createHolidaySchemeMutation({
        variables: {
          input: {
            title: values.title,
            description: values.description,
            employeesIds: employeeIds,
            groups: groups,
            workTitles: workTitles,
            workCenterIds: workCenterIds,
            approveTemplateId: form.getValues('approveTemplateId'),
            holidayTakenTemplateId: form.getValues('holidayTakenTemplateId'),
            scheme: HolidaySchemeAdapter.schemeToGraphQlScheme(
              form.getValues('scheme'),
            ),
            color: form.getValues('color'),
          },
        },
      });

      if (data.createHolidayScheme.__typename === 'Success') {
        globalSnackbarVar({
          message: 'Esquema de vacaciones creado con éxito',
          severity: 'success',
          show: true,
        });
      } else {
        console.error(data.createHolidayScheme);
        globalSnackbarVarGenericError();
      }
      handleClose();
      client.refetchQueries({ include: [GET_COMPANY_SCHEMES] });
    } catch (error) {
      console.error(error);
      globalSnackbarVarGenericError();
    } finally {
      globalBackdropVar({ open: false });
    }
  };
  const handleNext = async () => {
    if (step === CreateHolidaySchemeSteps.Title) {
      await trigger('title');
      await trigger('description');
      if (form.formState.errors.title || form.formState.errors.description) {
        return globalSnackbarVar({
          message: 'Completa los campos requeridos',
          show: true,
          severity: 'error',
        });
      } else {
        setStep(CreateHolidaySchemeSteps.DaysGranted);
      }
    } else if (step === CreateHolidaySchemeSteps.DaysGranted) {
      const approveTemplateId = form.getValues('approveTemplateId');
      if (!approveTemplateId) {
        form.setError('approveTemplateId', {
          type: 'required',
          message: 'Este campo es requerido',
        });
        return globalSnackbarVar({
          message: 'Selecciona una plantilla para la solicitud de vacaciones',
          show: true,
          severity: 'error',
        });
      }
      setStep(CreateHolidaySchemeSteps.Employees);
    } else {
      const selectedEmployees = form.getValues('assignedTo');
      if (selectedEmployees.length === 0) {
        return globalSnackbarVar({
          message: 'Selecciona al menos un empleado',
          show: true,
          severity: 'error',
        });
      }
      await onSubmit();
    }
  };

  let StepComponentToRender: JSX.Element | null;
  switch (step) {
    case CreateHolidaySchemeSteps.Title:
      StepComponentToRender = <EditTitle form={form} />;
      break;
    case CreateHolidaySchemeSteps.DaysGranted:
      StepComponentToRender = <CHSEditScheme form={form} />;
      break;
    case CreateHolidaySchemeSteps.Employees:
      StepComponentToRender = <CHSEmployees form={form} />;
      break;
    default:
      StepComponentToRender = null;
  }

  return (
    <>
      <DialogContent
        sx={{
          height: '100%',
          ...(step === CreateHolidaySchemeSteps.DaysGranted && { px: 0 }),
        }}
      >
        {StepComponentToRender}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            step === CreateHolidaySchemeSteps.Title ? handleClose : handlePrev
          }
          color="primary"
        >
          {step === CreateHolidaySchemeSteps.Title ? 'Cancelar' : 'Atrás'}
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleNext}>
          {step === CreateHolidaySchemeSteps.Employees
            ? 'Guardar'
            : 'Siguiente'}
        </Button>
      </DialogActions>
    </>
  );
};
