import { Button, Typography } from '../../../../newComponents';
import { useScreenSize } from '../../../../Hooks';
import { useStartVideoRecorderCountdown } from '../VideoAgreementRecorderDialogHooks/useStartVideoRecorderCountdown';
import { CameraFooterWrapper } from './VideoAgreementRecorderCamera.styles';
import { Icon } from '../../../../components/Icons/Icons';

export type VideoAgreementRecorderButtonProps = {
  isFaceDetected: boolean;
  isVideoRecording: boolean;
  isRecordingRestarted: boolean;
  onStartRecording: () => void;
  onStopRecording: () => void;
};

export function VideoAgreementRecorderButton(
  props: VideoAgreementRecorderButtonProps,
) {
  const {
    isFaceDetected,
    isVideoRecording,
    isRecordingRestarted,
    onStartRecording,
    onStopRecording,
  } = props;
  const { isMobile } = useScreenSize();
  const { countDown, handleStartCountDown } = useStartVideoRecorderCountdown();

  const startButtonDisabled =
    !isFaceDetected || isRecordingRestarted || countDown !== null;

  return (
    <>
      {countDown !== null && (
        <Typography
          variant="h1"
          customColor
          color="white"
          style={{ fontSize: '8rem' }}
          sx={{ position: 'absolute', top: '40%', zIndex: 2 }}
        >
          {countDown}
        </Typography>
      )}
      <CameraFooterWrapper isMobile={isMobile}>
        {isVideoRecording ? (
          <Button
            variant="contained"
            color="error"
            onClick={onStopRecording}
            fullWidth={isMobile}
            endIcon={<Icon icon="stop_mini_fill" color="white" />}
          >
            Finalizar grabación
          </Button>
        ) : (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleStartCountDown({ onCountDownEnd: onStartRecording });
            }}
            fullWidth={isMobile}
            endIcon={
              <Icon
                icon="record_circle_line"
                color={startButtonDisabled ? 'grey' : 'white'}
              />
            }
            disabled={startButtonDisabled}
          >
            Comenzar a grabar
          </Button>
        )}
      </CameraFooterWrapper>
    </>
  );
}
