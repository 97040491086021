import { makeVar, ReactiveVar } from '@apollo/client';
import { CalendarEvent } from '../../../../newComponents/SoraCalendar/SoraCalendar.types';
import { EmployeeHolidaysSummary, HolidayRequest } from './MyCalendar.types';

//please keep in sync with GetEmployeeHolidaysSummary.types.js type definition
export enum HolidaySetupErrorCode {
  // eslint-disable-next-line no-unused-vars
  NO_HOLIDAYS_SCHEME_ASSIGNED = 'NO_HOLIDAYS_SCHEME_ASSIGNED',
  // eslint-disable-next-line no-unused-vars
  NO_HOLIDAYS_AUTHORIZERS_TEAM_ASSIGNED = 'NO_HOLIDAYS_AUTHORIZERS_TEAM_ASSIGNED',
  // eslint-disable-next-line no-unused-vars
  NO_EMPLOYMENT_START_DATE_DEFINED = 'NO_EMPLOYMENT_START_DATE_DEFINED',
  // eslint-disable-next-line no-unused-vars
  USER_HAS_LESS_THAN_ONE_YEAR_WORKING = 'USER_HAS_LESS_THAN_ONE_YEAR_WORKING',
  // eslint-disable-next-line no-unused-vars
  USER_HAS_LESS_THAN_ONE_MONTH_WORKING = 'USER_HAS_LESS_THAN_ONE_MONTH_WORKING',
}

export type MyHolidaysConfigType = {
  employmentStartDate: Date | null;
  events: CalendarEvent[];
  holidaysSummary: EmployeeHolidaysSummary[];
  pendingHolidayRequest: HolidayRequest | null;
  unsignedApprovedHolidayRequest: HolidayRequest | null;
  holidaySetupError?: HolidaySetupErrorCode;
  approvedHolidays?: HolidayRequest;
  rejectedHolidayRequest?: HolidayRequest;
  approvedHolidayRequestToSignAsTaken?: HolidayRequest;
};
const myHolidaysConfig: MyHolidaysConfigType = {
  employmentStartDate: null,
  events: [],
  holidaysSummary: [],
  pendingHolidayRequest: null,
  unsignedApprovedHolidayRequest: null,
  holidaySetupError: undefined,
  approvedHolidays: undefined,
  rejectedHolidayRequest: undefined,
  approvedHolidayRequestToSignAsTaken: undefined,
};
export const MyHolidaysConfigVar: ReactiveVar<MyHolidaysConfigType> =
  makeVar(myHolidaysConfig);

export const CreateHolidayRequestDialogVar: ReactiveVar<{
  open: boolean;
  requestToCancel?: HolidayRequest;
}> = makeVar({
  open: false,
  requestToCancel: undefined,
});
