import { AnimationType } from './LottieAnimation.types';
import soriWinkAnimation from './LottieAnimationAssets/soriWink.animation.json';
import completeEmploymentRecordAnimation from './LottieAnimationAssets/completeEmploymentRecord.animation.json';
import createElectronicSignatureAnimation from './LottieAnimationAssets/createElectronicSignature.animation.json';
import revokeElectronicSignatureAnimation from './LottieAnimationAssets/revokeElectronicSignature.animation.json';
import identityVerificationAnimation from './LottieAnimationAssets/identityVerification.animation.json';
import signOnboardingDocumentsAnimation from './LottieAnimationAssets/signOnboardingDocuments.animation.json';
import soriWelcomeAnimation from './LottieAnimationAssets/soriWelcome.animation.json';
import confettiAnimation from './LottieAnimationAssets/confetti.animation.json';
import livenessAnimation from './LottieAnimationAssets/liveness.animation.json';

export const LottieAnimationMapper: Record<AnimationType, string> = {
  soriWink: soriWinkAnimation,
  soriWelcome: soriWelcomeAnimation,
  completeEmploymentRecord: completeEmploymentRecordAnimation,
  createElectronicSignature: createElectronicSignatureAnimation,
  revokeElectronicSignature: revokeElectronicSignatureAnimation,
  identityVerification: identityVerificationAnimation,
  signOnboardingDocuments: signOnboardingDocumentsAnimation,
  confetti: confettiAnimation,
  liveness: livenessAnimation,
  '': '',
};
